import { normalizePathData } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import { v4 } from "uuid";
const pathTrackId = v4();

const state = () => ({
  items: [],
  path: [],
  truncatedPath: [],
  prependPath: [],
  currentFolder: null,
  isLoading: false,
  isPathLoading: false,
  selectedFilesToMerge: [],
});

const getters = {
  isLoading: (state) => {
    return state.isPathLoading || state.isLoading;
  },
};

const actions = {
  async getFolders({ commit }, { id, rootId }) {
    commit("foldersLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/children`, {
        params: {
          type: "FOLDER",
          rootId,
        },
      })
      .then(({ data }) => {
        const items = data?.storages.map((item) => {
          return {
            ...item,
          };
        });

        commit("setFolders", items);
        commit("foldersLoading", false);
      })
      .catch((error) => {
        commit("foldersLoading", false);
        throw new Error(error);
      });
  },
  async getPath({ commit }, { id, rootId }) {
    commit("pathLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/path`, {
        headers: { trackId: pathTrackId },
        params: { rootId },
      })
      .then(({ data }) => {
        const { path, prependPath, truncatedPath } = normalizePathData(data?.path || []);

        const currentFolder = path[path.length - 1];

        commit("setPrependPath", prependPath);
        commit("setTruncatedStoragePath", truncatedPath);
        commit("setPath", path);
        commit("setCurrentFolder", currentFolder);
        commit("pathLoading", false);
      })
      .catch((error) => {
        commit("pathLoading", false);
        throw new Error(error);
      });
  },
  async mergeFiles({ commit }, body) {
    return axios.post(`${apiUrl}merge-storage/merge`, body);
  },
};

const mutations = {
  setFolders(state, folders) {
    state.items = folders;
  },
  setPath(state, path) {
    state.path = path;
  },
  setSelectedFiles(state, files) {
    state.selectedFilesToMerge = files;
  },
  removeSelectedFile(state, file) {
    const i = state.selectedFilesToMerge.findIndex((f) => f.id === file.id);
    if (i === -1) return;
    state.selectedFilesToMerge.splice(i, 1);
  },
  setTruncatedStoragePath(state, path) {
    state.truncatedPath = path;
  },
  setPrependPath(state, path) {
    state.prependPath = path;
  },
  setCurrentFolder(state, folder) {
    state.currentFolder = folder;
  },
  foldersLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  pathLoading(state, isLoading) {
    state.isPathLoading = isLoading;
  },
};

export const storageMergeToPdf = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
