import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  isLoading: false,
  currency: null,
  plans: [],
});

const getters = {};

const actions = {
  async getPlans({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}billing/plan`)
      .then(({ data }) => {
        commit("setPlans", data.plans);
        commit("setCurrency", data.currency);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setPlans(state, invoices) {
    state.plans = invoices;
  },
  setCurrency(state, currency) {
    state.currency = currency;
  },
};

export const plans = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
