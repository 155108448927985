import axios from "axios";
import { getInstance } from "../auth";
import store from "../store";
import { openOauth2Window } from "@/services/integration/integrationSetup/oAuth2SetupService";
import { BOLIGMAPPA } from "./constants";

export function axiosAuthSetup() {
  axios.interceptors.request.use(
    async (config) => {
      const authService = getInstance();
      let token = authService.isAuthenticated
        ? await authService.getTokenSilently()
        : null;

      const user = store.state.auth.user;
      const currentDomain = user ? user.currentDomain : null;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (currentDomain) {
        config.headers.domain = currentDomain.id;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

export function axiosErrorHandlingSetup() {
  axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (error.config && error.config.skipErrorHandling) return Promise.reject(error);

      if (isBlob(error)) {
        await blobErrorToStandardError(error);
      }

      const response = error.response;

      if (response) {
        if (response.data.statusCode === 401) {
          const authService = getInstance();
          authService.logout();
        } else {
          store.dispatch("snackbar/snackbar", {
            show: true,
            text: response.data
              ? response.data?.message
                ? response.data?.message
                : response.data?.error
              : "Something went wrong...",
            timeout: 5000,
            color: "error",
          });
        }
      } else {
        if (error.message && error.message === "Network Error") {
          //router.push("/error");
          store.dispatch("snackbar/snackbar", {
            show: true,
            text: "Network Error",
            timeout: 10000,
            color: "error",
          });
        }
      }
      return Promise.reject(error);
    },
  );
}

export function axiosBoligmappaResponseSetup() {
  axios.interceptors.response.use(
    async (res) => {
      const { data, config } = res;
      const isBoligmappaRequest = config.url.includes("boligmappa");
      if (isBoligmappaRequest && data.statusCode === 401) {
        if (!config.__isRetryRequest) {
          config.__isRetryRequest = true;
          await new Promise((resolve) =>
            openOauth2Window({ integrationName: BOLIGMAPPA }, resolve),
          );

          return axios(config);
        }
      }

      return res;
    },
    (error) => Promise.reject(error),
  );
}

function isBlob(error) {
  return (
    error &&
    error.request &&
    error.request.responseType === "blob" &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf("json") != -1
  );
}

async function blobErrorToStandardError(error) {
  await new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      error.response.data = JSON.parse(reader.result);
      resolve(Promise.resolve(error));
    };

    reader.onerror = () => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });
}
