<template>
  <v-dialog v-model="localDialog" max-width="700px">
    <v-stepper v-model="step" v-if="isIntegrationEnabled($constant.BOLIGMAPPA)">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="step > 1" step="1">
          {{ $t("integration.boligmappa.plantWizard.selectStreet") }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="step > 2" step="2">
          {{ $t("integration.boligmappa.plantWizard.selectAddress") }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="step > 3" step="3">
          {{ $t("integration.boligmappa.plantWizard.selectProperty") }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step="4">
          {{ $t("integration.boligmappa.plantWizard.summary") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <SelectStreet
            @select="onStreetSelect"
            @cancel="onCancel"
            @search="onStreetSearch"
            :defaultValue="projectAddress"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <SelectAddress
            @select="onAddressSelect"
            @cancel="onCancel"
            @back="step = 1"
            :streetId="selectedStreetId"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <SelectProperty
            @select="onPropertySelect"
            @cancel="onCancel"
            @back="step = 2"
            :addressId="selectedAddressId"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <PlantCreation
            @complete="onComplete"
            @cancel="onCancel"
            @back="step = 3"
            :property="selectedProperty"
            :projectId="project.id"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card v-else>
      <v-card-title>
        <span class="headline"> Boligmappa integration is not enabled </span>
      </v-card-title>

      <v-card-actions>
        <v-btn text @click="onCancel">
          {{ $t("common.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import SelectStreet from "./steps/SelectStreet.vue";
import SelectAddress from "./steps/SelectAddress.vue";
import SelectProperty from "./steps/SelectProperty.vue";
import PlantCreation from "./steps/PlantCreation.vue";
export default {
  props: {
    dialog: Boolean,
    project: Object,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      step: 1,
      selectedStreetId: null,
      selectedAddressId: null,
      selectedProperty: null,
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("boligmappaPlant", {
      plant: (state) => state.plant,
      isCreatingPlant: (state) => state.isCreatingPlant,
      creatingPlantHasError: (state) => state.creatingPlantHasError,
    }),
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    projectAddress() {
      const address = this.project.projectAddress || "";
      return address;
    },
  },
  methods: {
    onStreetSelect({ selectedStreetId }) {
      if (!selectedStreetId) return;
      this.selectedStreetId = selectedStreetId;
      this.step = 2;
    },
    onAddressSelect({ selectedAddressId }) {
      if (!selectedAddressId) return;
      this.selectedAddressId = selectedAddressId;
      this.step = 3;
    },
    onPropertySelect({ property }) {
      if (!property) return;
      this.selectedProperty = property;
      this.step = 4;
    },
    onStreetSearch() {
      this.selectedStreetId = null;
      this.selectedAddressId = null;
      this.selectedProperty = null;
    },
    onComplete() {
      this.$emit("completed");
      this.localDialog = false;
      setTimeout(() => {
        this.step = 1;
        this.selectedStreetId = null;
        this.selectedAddressId = null;
        this.selectedProperty = null;
      }, 100);
    },
    onCancel() {
      this.$emit("cancel");
      this.localDialog = false;
      setTimeout(() => {
        this.step = 1;
        this.selectedStreetId = null;
        this.selectedAddressId = null;
        this.selectedProperty = null;
      }, 100);
    },
  },
  components: { SelectStreet, SelectAddress, SelectProperty, PlantCreation },
};
</script>
