const isDrawerMiniLSKey = "drawer:isMiniVariant";

const state = () => ({
  isDrawerMiniVariant: JSON.parse(localStorage.getItem(isDrawerMiniLSKey)) ?? false,
});

const getters = {
  isDrawerMiniVariant: (state) => state.isDrawerMiniVariant,
};

const actions = {};

const mutations = {
  setIsDrawerMiniVariant(state, isMini) {
    state.isDrawerMiniVariant = isMini;
    localStorage.setItem(isDrawerMiniLSKey, JSON.stringify(isMini));
  },
};

export const appDrawer = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
