<template>
  <v-btn
    :disabled="disabled"
    icon
    small
    @click="summonStonlyGuide(guideId)"
    @click.stop
    @mousedown.stop
  >
    <v-icon>{{ $icon.LIGHT.COMMON.CIRCLE_QUESTION }}</v-icon>
  </v-btn>
</template>

<script>
import { summonStonlyGuide } from "../../../helpers/util/stonlyWidget.js";
export default {
  props: {
    guideId: String,
    disabled: Boolean,
  },
  methods: {
    summonStonlyGuide(guideId) {
      summonStonlyGuide(guideId);
    },
  },
};
</script>
