<template>
  <div
    v-if="!to"
    @click.stop="$emit('click', $event)"
    class="underline-on-hover primary-text--text cursor-pointer"
  >
    <slot></slot>
  </div>
  <router-link
    v-else
    class="underline-on-hover primary-text--text"
    target="_blank"
    :to="to"
  >
    <span :class="`${textColor}`">
      <slot></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    textColor: String,
    to: Object,
  },
};
</script>
