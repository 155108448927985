<template>
  <Config v-on="listeners" v-bind="params" :completeMethod="completeMethod" />
</template>

<script>
import Config from "./Config.vue";
import { setupCustomAuth } from "@/services/integration/integrationSetup/customSetupService";

export default {
  components: { Config },
  methods: {
    completeMethod(code) {
      return setupCustomAuth({ integrationName: this.$attrs.integrationName, code });
    },
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
};
</script>
