<template>
  <div>
    <AppMiniContentSelectionDivider />
    <template v-if="activeDomainId">
      <AppNavigationList v-if="!isHighestPriorityRoleContact">
        <AppNavigationItem
          :to="{ name: $routeNames.DASHBOARD.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.DASHBOARD"
          :title="$t('drawer.route.dashboard')"
          prepend-icon-small
          replace
        />
      </AppNavigationList>
      <AppMiniContentSelectionDivider />
      <AppNavigationList>
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.manage") }}
        </AppContentSectionHeader>
        <AppNavigationItem
          :to="{ name: $routeNames.TASK.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.TASK"
          :title="$t('drawer.route.tasks')"
          prepend-icon-small
          replace
        />
        <AppNavigationItem
          :to="{ name: $routeNames.FORM.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.FORM"
          :title="$t('drawer.route.forms')"
          prepend-icon-small
          replace
        />
        <AppNavigationItem
          v-if="isTimeEntryFeatureEnabled"
          :to="{ name: $routeNames.TIME_REGISTRATION.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.TIME_REGISTRATION"
          :title="$t('drawer.route.timeRegistration')"
          prepend-icon-small
          replace
        />
        <AppNavigationItem
          :to="{ name: $routeNames.PROJECT.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.PROJECT"
          :title="$t('drawer.route.projects')"
          prepend-icon-small
          replace
        />
        <AppNavigationItem
          v-if="!isHighestPriorityRoleContact"
          :to="{ name: $routeNames.STORAGE.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.STORAGE"
          :title="$t('drawer.route.files')"
          prepend-icon-small
          replace
        />
      </AppNavigationList>
      <AppMiniContentSelectionDivider />
      <AppNavigationList v-if="!isHighestPriorityRoleContact">
        <AppContentSectionHeader>
          {{ $t("drawer.routeLabel.company") }}
        </AppContentSectionHeader>

        <AppNavigationItem
          :to="{ name: $routeNames.CONTACT.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.CONTACT"
          :title="$options.filters.capitalize($t('drawer.route.contacts'))"
          prepend-icon-small
          replace
        />
        <AppNavigationItem
          :to="{ name: $routeNames.EMPLOYEE.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.USER"
          :title="$t('drawer.route.employees')"
          prepend-icon-small
          replace
        />

        <AppNavigationItem
          v-if="isOwnerOrAdmin"
          :to="{ name: $routeNames.TEMPLATE.ROOT }"
          :prependIcon="$icon.REGULAR.ROUTE.TEMPLATE"
          :title="$t('drawer.route.templates')"
          :appendIcon="$icon.SOLID.ACTION.GO_NEXT_LEVEL"
          prepend-icon-small
          show-append-icon-only-on-hover
        />
      </AppNavigationList>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    isOwnerOrAdmin() {
      return this.isDomainOwner || this.isDomainAdmin;
    },
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isDomainHrAdmin: "isDomainHrAdmin",
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
      isTimeEntryFeatureEnabled: "isTimeEntryFeatureEnabled",
    }),
  },
};
</script>
