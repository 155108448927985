<template>
  <div class="d-flex align-center">
    <v-icon class="text--disabled">
      {{ $icon.LIGHT.ACTION.CIRCLE_PLUS }}
    </v-icon>
    <span style="padding-left: 6px" class="text--disabled font-italic text-no-wrap">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
