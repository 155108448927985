<template>
  <div
    style="min-height: 40px"
    @click="toggleChecked"
    :class="`
    w-100 
    mb-2
    d-flex 
    align-center 
    rounded 
    flex-wrap 
    cursor-pointer 
    ${backgroundColor} 
    ${border}`"
  >
    <v-row cols="12" class="align-center px-2">
      <v-col cols="1">
        <v-simple-checkbox color="success" :value="isChecked" @click="toggleChecked" />
      </v-col>
      <v-col cols="2">
        <div>{{ product.code }}</div>
      </v-col>
      <v-col>
        <div>{{ product.name }}</div>
      </v-col>
      <v-col class="text-end">
        <div>{{ clientName }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    isChecked: Boolean,
  },
  computed: {
    clientName() {
      return this.product?.client?.name;
    },
    border() {
      return this.isChecked ? "border-a--success--thin" : "border-a";
    },
    backgroundColor() {
      return this.isChecked
        ? "success-super-light--background"
        : "ui-background--background";
    },
  },
  methods: {
    toggleChecked() {
      this.$emit("toggleChecked", { product: this.product });
    },
  },
};
</script>
