<template>
  <AppGroupByBtn :tableModel="tableModel" :menuItems="menuItems" />
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
export default {
  mixins: [groupByMixins],
  props: {
    tableModel: String,
  },
  computed: {
    menuItems() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("common.noGroupText")),
          icon: this.$icon.REGULAR.ACTION.EMPTY,
          isSelected: this.groupByMixins_isGroupSelected(undefined),
          click: () => this.groupByMixins_setGroupBy(undefined),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.status")),
          icon: this.$icon.REGULAR.COMMON.SQUARE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.STATUS,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.STATUS),
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("formManagement.list.responsible"),
          ),
          icon: this.$icon.REGULAR.ROUTE.USER,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.RESPONSIBLE,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.RESPONSIBLE),
        },
        ...(this.filtersMixins_dataFields.PROJECT
          ? [
              {
                text: this.$t("common.project"),
                icon: this.$icon.REGULAR.ROUTE.PROJECT,
                isSelected: this.groupByMixins_isGroupSelected(
                  this.filtersMixins_dataFields.PROJECT,
                ),
                click: () =>
                  this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.PROJECT),
              },
            ]
          : []),
        {
          text: this.$options.filters.capitalize(this.$t("common.task")),
          icon: this.$icon.REGULAR.ROUTE.TASK,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.TASK,
          ),
          click: () => this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.TASK),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.category")),
          icon: this.$icon.REGULAR.ACTION.CATEGORY,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.CATEGORY,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.CATEGORY),
        },
        {
          text: this.$options.filters.capitalize(this.$t("taskManagement.list.dueDate")),
          icon: this.$icon.REGULAR.ACTION.DUE_DATE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.DUE_DATE,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.DUE_DATE),
        },
      ];
    },
  },
};
</script>
