import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import nest from "../../../../helpers/flatListToTree";
import { v4 } from "uuid";

const state = () => ({
  items: [],
  isLoading: false,
  isCreating: false,
});

const getters = {
  storageTreeView: state => {
    const storagItems = state.items.filter(i => i.type === "STORAGE");
    return nest(storagItems);
  },
};

const actions = {
  async getItems({ commit }, { load = true, templateId }) {
    if (load) {
      commit("loading", true);
    }
    return axios
      .get(`${apiUrl}template/project-item`, {
        params: { templateId },
      })
      .then(({ data }) => {
        const items = (data.items || []).map(item => ({
          ...item,
          parentId: item.parentId || null,
          refId: item.id,
        }));
        commit("setItems", items);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async createItem({ commit }, { body, refId }) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}template/project-item`, { ...body })
      .then(({ data }) => {
        const item = data?.item;
        if (item) {
          item.refId = item.id;
          if (refId) {
            commit("updateItem", { refId, item });
          }
        }
        commit("creating", false);
      })
      .catch(error => {
        commit("creating", false);
        throw new Error(error);
      });
  },
  async createItems({ commit }, { body }) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}template/project-item/multiple`, { ...body })
      .then(({ data }) => {
        const items = data?.items;
        for (const item of items) {
          if (item) {
            item.refId = item.id;
            commit("addItem", item);
          }
        }
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {
        commit("creating", false);
      });
  },
  async updateItem({ commit }, { id, body, refId }) {
    commit("updateItem", { refId, item: body });
    return axios
      .put(`${apiUrl}template/project-item/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        const item = data?.item;
        if (item) {
          item.refId = refId;
          commit("updateItem", { refId, item });
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async moveItem({ dispatch, commit }, { id, item, body, templateId }) {
    commit("moveItem", { item, index: body.index });
    return axios
      .put(`${apiUrl}template/project-item/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        dispatch("getItems", {
          templateId,
          load: false,
        });
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async deleteItems({ commit, dispatch }, { ids, templateId }) {
    for (const id of ids) {
      commit("removeItem", id);
    }
    return axios
      .delete(`${apiUrl}template/project-item`, {
        data: { ids },
      })
      .then(({ data }) => {
        dispatch("getItems", { templateId, load: false });
      })
      .catch(error => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  updateItem(state, { refId, item }) {
    const index = state.items.findIndex(x => x.refId === refId);
    if (index !== -1) {
      state.items.splice(index, 1, { ...state.items[index], ...item });
    }
  },
  moveItem(state, { item, index }) {
    const oldIndex = state.items.findIndex(x => x.refId === item.refId);
    if (oldIndex !== -1) {
      state.items.splice(oldIndex, 1);
    }

    state.items.splice(index, 0, item);
    for (const [index, item] of state.items.entries()) {
      item.index = index;
    }
  },
  addItem(state, item) {
    state.items.push(item);
  },
  addUnsavedItem(state, item) {
    state.items.push({
      refId: v4(),
      ...item,
    });
  },
  removeItem(state, itemId) {
    const index = state.items.findIndex(x => x.refId === itemId);
    if (index !== -1) {
      state.items.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const projectTemplateItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
