import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, MENU, MENU_DETAILS, MENU_COMMENTS, MENU_STORAGE, MENU_HISTORY } =
  ROUTE_NAMES.FORM;

export const formRoutes = [
  {
    path: "/form",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "form" */ "../../pages/FormManagement/Forms"),
    children: [
      {
        path: "menu/:formId",
        name: MENU,
        component: () =>
          import(
            /* webpackChunkName: "form" */ "../../pages/FormManagement/Forms/MenuPage"
          ),
        children: [
          {
            path: "details",
            name: MENU_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/DetailsPage"
              ),
          },
          {
            path: "comments",
            name: MENU_COMMENTS,
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/CommentsPage"
              ),
          },
          {
            path: "storage/:folderId",
            name: MENU_STORAGE,
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/StoragePage"
              ),
          },
          {
            path: "history",
            name: MENU_HISTORY,
            component: () =>
              import(
                /* webpackChunkName: "form" */ "../../pages/FormManagement/MenuPages/HistoryPage"
              ),
          },
        ],
      },
    ],
  },
];
