<script>
import { VContainer, VCard } from "vuetify/lib";
import Title from "./Title.vue";
import Tasks from "./Tasks.vue";
import Storage from "./Storage.vue";
import DocumentTemplates from "./DocumentTemplates.vue";
import Todos from "./Todos.vue";

export default {
  props: {
    template: Object,
    templateItems: Array,
    storageTreeView: Array,
    isLoading: Boolean,
    showHSE: Boolean,
    showQA: Boolean,
  },
  methods: {
    genTodos() {
      const toDoItems = this.templateItems.filter((t) => t.type === "TODO");
      return this.$createElement(Todos, {
        props: { toDoItems },
      });
    },
    genTasks() {
      const taskItems = this.templateItems.filter((t) => t.type === "TASK");
      return this.$createElement(Tasks, {
        props: { taskItems },
      });
    },
    genStorage() {
      const storageItems = this.templateItems.filter((t) => t.type === "STORAGE");

      return this.$createElement(Storage, {
        props: {
          storageItems,
          storageTreeView: this.storageTreeView,
        },
      });
    },
    genDocumentTemplates(type) {
      const documentTemplateItems = this.templateItems.filter((t) => t.type === type);
      return this.$createElement(DocumentTemplates, {
        props: {
          documentTemplateItems,
          type,
        },
      });
    },
    genCard() {
      return this.$createElement(
        VCard,
        {
          class: "border-a",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading,
          },
        },
        [
          this.$createElement("div", { class: "pa-8" }, [
            this.genHeader(),
            this.genTasks(),
            this.genTodos(),
            this.showHSE && this.genDocumentTemplates("HSE"),
            this.showQA && this.genDocumentTemplates("QA"),
            this.genStorage(),
          ]),
        ],
      );
    },
    genHeader() {
      return [
        this.$createElement(Title, {
          props: { title: this.$t("cms.template.form.name") },
        }),
        this.$createElement("AppDefaultTextField", {
          class: "pointer-events-none",
          props: {
            readonly: true,
            label: this.$t("cms.template.form.name"),
            value: this.template?.name,
          },
        }),
        this.$createElement(Title, {
          props: { title: this.$t("cms.template.form.description") },
        }),
        this.$createElement("AppDefaultTextarea", {
          class: "pointer-events-none",
          props: {
            readonly: true,
            id: "descriptionField",
            label: this.$t("cms.template.form.description"),
            value: this.template?.description,
          },
        }),
      ];
    },
  },
  render(h) {
    return h(VContainer, [this.genCard()]);
  },
};
</script>
