<template>
  <div class="to-do-list_item draggable-item" :class="isUnsaved ? 'block-draggable' : ''">
    <div class="to-do-list_item__prepend">
      <v-btn
        class="block-draggable"
        small
        icon
        :color="isDoneColor"
        @click="onIsDoneChange"
        :disabled="isUnsaved"
      >
        <v-icon>
          {{ isDone ? $icon.SOLID.COMMON.CIRCLE_CHECK : $icon.LIGHT.COMMON.CIRCLE_CHECK }}
        </v-icon>
      </v-btn>
    </div>
    <div class="to-do-list_item__content">
      <template v-if="showNameInput">
        <AppDefaultTextarea
          class="block-draggable"
          :id="nameInputId"
          :value="name"
          backgroundColor="transparent"
          rows="1"
          rowHeight="24"
          hide-details
          auto-grow
          @blur="onNameInputBlur"
          @keydown.enter.prevent="onNameInputEnter"
        />
      </template>
      <template v-else>
        <div
          class="pl-3"
          @click.self="onNameClick"
          :class="isDone ? 'text-decoration-line-through text--disabled' : ''"
          v-html="linkTextMixins_formattedLinkText(name)"
        ></div>
      </template>
    </div>
    <div class="to-do-list_item__append">
      <v-btn class="block-draggable" small icon @click="onDelete" :disabled="isUnsaved">
        <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { linkTextMixins } from "@/helpers/mixins";
export default {
  mixins: [linkTextMixins],
  props: {
    toDo: Object,
  },
  data() {
    return {
      isNameInputActive: false,
      isEnterPress: false,
    };
  },
  computed: {
    id() {
      return this.toDo?.id || null;
    },
    index() {
      return this.toDo?.index || 0;
    },
    name() {
      return this.toDo?.name || "";
    },
    isDone() {
      return this.toDo?.isDone || false;
    },
    isDoneColor() {
      return this.isDone ? "success" : "";
    },
    nameInputId() {
      //if the to-do item is unsaved, use index as id else use to-do id
      if (this.isUnsaved) return `to-do-list-item-name-text-field-${this.index}`;
      return `to-do-list-item-name-text-field-${this.id}`;
    },
    isUnsaved() {
      return this.id ? false : true;
    },
    showNameInput() {
      return this.isNameInputActive || this.isUnsaved;
    },
  },
  methods: {
    onUpdate({ body }) {
      this.$emit("toDo:update", { body, toDo: this.toDo });
    },
    saveUnsavedToDo({ body, createNext }) {
      this.$emit("toDo:saveUnsaved", { body, createNext });
    },
    onNameClick() {
      this.isNameInputActive = true;
      setTimeout(() => {
        this.focusNameInput();
      }, 20);
    },
    onNameInputEnter(e) {
      this.isEnterPress = true;
      this.blurNameInput();
    },
    onNameInputBlur(e) {
      this.isNameInputActive = false;
      const name = e.target.value ? e.target.value.trim() : null;

      if (!this.isUnsaved) {
        this.onUpdate({ body: { name } });
      } else {
        const createNext = name && this.isEnterPress;
        this.saveUnsavedToDo({ body: { name }, createNext });
        this.isEnterPress = false;
      }
    },
    onNameInputExit(e) {},
    onIsDoneChange() {
      this.onUpdate({ body: { isDone: !this.isDone } });
    },
    focusNameInput() {
      const input = document.getElementById(this.nameInputId);
      if (input) {
        input.focus();
      }
    },
    blurNameInput() {
      const input = document.getElementById(this.nameInputId);
      if (input) {
        input.blur();
      }
    },
    onDelete() {
      this.$emit("toDo:delete", { toDo: this.toDo });
    },
  },
};
</script>
