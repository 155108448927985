<script>
import { VImg, VSkeletonLoader } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    genSkeletonLoader() {
      const elms = [];
      if (Array.isArray(this.item.images)) {
        for (let img of this.item.images) {
          elms.push(
            this.$createElement(
              "div",
              { class: "form-item__image_skeleton_container__preview" },
              [
                this.$createElement(VSkeletonLoader, {
                  props: {
                    type: "image",
                  },
                }),
              ],
            ),
          );
        }
      }
      return elms;
    },
    genImages() {
      const elms = [];
      if (Array.isArray(this.item.images)) {
        for (let image of this.item.images) {
          elms.push(
            this.$createElement(
              "div",
              {
                class:
                  "form-item__image_container__preview border-a d-flex align-center justify-center cursor-pointer",
                on: {
                  click: (e) =>
                    this.$emit("item:imagePreview", {
                      imageId: image.id,
                      imageIds: this.item.images.map((image) => {
                        return { id: image.id };
                      }),
                    }),
                },
              },
              [
                this.$createElement(VImg, {
                  props: { src: image.url, contain: true },
                  class: "form-item__image",
                }),
              ],
            ),
          );
        }
      }
      return elms;
    },

    genContent() {
      if (this.item.imagesLoading) return this.genSkeletonLoader();
      return this.genImages();
    },
  },

  render(h) {
    return h("div", { class: "py-2" }, [
      h(
        "span",
        { class: "text-body-2 font-weight-bold" },
        this.$t("formManagement.preview.images"),
      ),
      h("div", { class: "form-item__images" }, [this.genContent()]),
    ]);
  },
};
</script>
