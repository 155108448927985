import * as moment from "moment";

export function getPartOfDayKey() {
  let date = moment().format("HHmm");

  let partOfDayKey = "";
  if (date >= "0501" && date <= "1200") {
    partOfDayKey = "morning";
  } else if (date >= "1201" && date <= "1700") {
    partOfDayKey = "afternoon";
  } else if (date >= "1701" && date <= "2300") {
    partOfDayKey = "evening";
  } else {
    partOfDayKey = "night";
  }
  return partOfDayKey;
}
