<template>
  <div class="h-100vh">
    <template v-if="isCommentFeatureEnabled">
      <div
        class="overflow-y-auto"
        id="comments"
        :style="`height: calc(100% - ${commentCointainerHeight}px)`"
        ref="scrollContainer"
      >
        <div class="sentinel" ref="sentinel"></div>
        <div ref="listCointainer">
          <div v-for="date of dates" :key="date.day">
            <div class="d-flex justify-center align-center pa-2">
              <v-divider />
              <h5 class="px-4">{{ date.day }}</h5>
              <v-divider />
            </div>
            <AppCommentItem
              v-for="item in date.comments"
              :key="item.id"
              :item="item"
              :currentUserId="currentUserId"
              @item:delete="onCommentDelete"
              @item:update="onCommentUpdate"
            >
            </AppCommentItem>
          </div>
        </div>
        <template v-if="!isLoading && dates.length === 0">
          <v-row class="fill-height w-100 ma-0" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center pb-0" cols="12">
              <h3>
                {{ $t("comment.noComments") }}
              </h3>
            </v-col>
          </v-row>
        </template>
      </div>
      <div
        class="d-flex flex-column w-100 primary-ui-bg rounded-lg border-a"
        ref="commentContainer"
      >
        <AppQuill
          class="pt-1"
          :placeholder="$t('comment.newComment')"
          :submitLoading="isCreatingComment"
          @submit="onQuillSubmit"
          @update="onQuillUpdate"
          show-submit
        />
      </div>
    </template>
    <template v-else>
      {{ $t("comment.featureIsDisabled") }}
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    type: String,
    parentId: String,
  },
  data() {
    return {
      commentCointainerHeight: 92,
      comment: "",
      initialLoad: true,
      filter: {
        limit: 15,
        offset: 0,
      },
    };
  },
  watch: {
    parentId: {
      handler(parentId) {
        if (parentId) {
          this.getComments(this.filterArgs());
        }
      },
    },
    isLoading: {
      handler(isLoading) {
        this.$emit("list:loading", isLoading);
      },
    },
  },
  computed: {
    ...mapGetters("auth", {
      isCommentFeatureEnabled: "isCommentFeatureEnabled",
      currentUserId: "currentUserId",
    }),
    ...mapState("comments", {
      comments: (state) => state.comments,
      isLoading: (state) => state.isLoading,
      isCreatingComment: (state) => state.isCreatingComment,
      hasMore: (state) => state.hasMore,
    }),
    ...mapGetters("comments", {
      dates: "dates",
    }),
  },
  methods: {
    async getComments(args) {
      await this.$store.dispatch("comments/getComments", { params: { ...args } });
      this.initialLoad = false;
      this.$nextTick().then(() => {
        //scrolldown
        this.scrollToCommentEnd();
        this.$nextTick().then(() => {
          //setup up observer after initial list loaded and scrolled all the way to the bottom
          this.initInterSectionObserver();
        });
      });
    },
    async loadMoreComments(args) {
      this.recordScrollPosition();
      await this.$store.dispatch("comments/getComments", { params: { ...args } });
      this.$nextTick(() => {
        this.restoreScrollPosition();
      });
    },
    async createComment() {
      const body = {
        type: this.type,
        parentId: this.parentId,
        content: this.comment,
      };
      this.comment = "";
      await this.$store.dispatch("comments/createComment", { body });
      this.scrollToCommentEnd();
    },
    onCommentDelete({ item }) {
      if (item.creatorId === this.currentUserId) {
        this.$store.dispatch("comments/deleteComment", item.id);
      }
    },
    onCommentUpdate({ item, content }) {
      if (item.creatorId === this.currentUserId) {
        this.$store.dispatch("comments/updateComment", { id: item.id, content });
      }
    },
    recordScrollPosition() {
      let node = this.$refs.scrollContainer;
      this.previousScrollHeightMinusScrollTop = node.scrollHeight - node.scrollTop;
    },
    restoreScrollPosition() {
      let node = this.$refs.scrollContainer;
      node.scrollTop = node.scrollHeight - this.previousScrollHeightMinusScrollTop;
    },
    initInterSectionObserver() {
      let options = {
        root: this.$refs.scrollContainer,
        margin: "10px",
      };
      this.listEndObserver = new IntersectionObserver(this.handleIntersection, options);

      this.listEndObserver.observe(this.$refs.sentinel);
    },
    handleIntersection([entry]) {
      const offsetId = this.comments[0]?.id;
      if (entry.isIntersecting && this.hasMore && !this.isLoading && offsetId) {
        this.loadMoreComments(this.filterArgs({ offsetId }));
      }
    },
    filterArgs(args) {
      return {
        type: this.type,
        parentId: this.parentId,
        ...this.filter,
        ...args,
      };
    },
    scrollToCommentEnd() {
      let scrollContainer = this.$refs.scrollContainer;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },
    onQuillSubmit({ content }) {
      this.comment = content;
      if (this.comment) {
        this.createComment();
      }
    },
    onQuillUpdate({ content }) {
      this.commentCointainerHeight = this.$refs.commentContainer.clientHeight;
    },
  },
  async mounted() {
    await this.$store.dispatch("comments/resetState");
    this.getComments(this.filterArgs());
    this.commentCointainerHeight = this.$refs.commentContainer.clientHeight;
  },
};
</script>
