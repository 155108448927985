<template>
  <div class="drawer_node border-b">
    <div
      class="drawer_node__root drawer_node_root--selectable cursor-pointer"
      @click="onClick(item)"
    >
      <div class="drawer_node__item">
        <div class="w-100 d-flex align-center pb-1">
          <!-- <AppChangeStatusIconMenu
            :status="item.status"
            :loading="item.isUpdatingStatus"
            @change="updateTask({ status: $event })"
            progress
          /> -->
          <span class="font-weight-medium ml-2">{{ item.name }}</span>
        </div>
        <div class="w-100 d-flex align-center pb-1" v-if="item.description">
          <span>{{ item.description }}</span>
        </div>
        <div class="w-100 d-flex align-center">
          <span v-if="item.dueDate">
            {{ $t("taskManagement.list.dueDate") }}
            {{ new Date(item.dueDate) | dateFormat("DD.MM.YYYY") }}
          </span>
          <v-spacer />
          <span class="text--disabled">{{ item.number }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  methods: {
    onClick(item) {
      this.$emit("item:click", item);
    },
    updateTask(data) {
      this.$emit("item:update", { data, task: this.item });
    },
  },
};
</script>
