<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("formManagement.signatureSession.remindDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="border-a overflow-hidden rounded mb-4"
          fixed-header
          height="200px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left input-background" style="height: 30px">
                  <span class="text--disabled">
                    {{ $t("formManagement.signatureSession.remindDialog.tableTitle") }}
                    ({{ signers.length }})
                  </span>
                </th>
                <th class="text-left input-background" style="height: 30px"></th>
              </tr>
            </thead>
            <tbody class="border-radius-0">
              <tr
                v-for="(signer, i) in signers"
                :key="i"
                class="cursor-pointer"
                @click="selectSigner(signer)"
              >
                <td class="border-radius-0">
                  <v-icon class="pr-2" :color="canBeSelected(signer) ? '' : 'disabled'">
                    {{
                      isSelected(signer)
                        ? $icon.SOLID.COMMON.SQUARE_CHECK
                        : $icon.LIGHT.COMMON.SQUARE
                    }}
                  </v-icon>
                  <span :class="canBeSelected(signer) ? '' : 'text--disabled'">
                    {{ signer.name }} ({{ signer.email }})
                  </span>
                </td>
                <td>
                  <AppColorChip
                    :text="getStatusText(signer)"
                    :color="getStatusColor(signer)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="label--small">
          {{ $t("formManagement.signatureSession.remindDialog.messageLabel") }}
        </div>
        <AppDefaultTextarea ref="messageTextarea" v-model="message" label="" rows="2" />
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="$t('formManagement.signatureSession.remindDialog.confirmButton')"
          :loading="isReminding"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { remindFormSign } from "../../../../services/form/formSignSession";

export default {
  data() {
    return {
      isReminding: false,
      isValid: false,
      selectedEmails: [],
      message: "",
    };
  },
  props: {
    dialog: Boolean,
    formId: String,
    signers: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    isSelected(signer) {
      return this.selectedEmails.includes(signer.email);
    },
    canBeSelected(signer) {
      return signer.status === "IN_PROGRESS";
    },
    selectSigner(signer) {
      if (!this.canBeSelected(signer)) return;
      if (this.isSelected(signer)) {
        this.selectedEmails = this.selectedEmails.filter(
          (email) => email !== signer.email,
        );
      } else {
        this.selectedEmails.push(signer.email);
      }
    },
    getStatusColor(signer) {
      let color = "primary";
      if (signer.status === this.$constant.FORM_SIGN_SESSION_STATUS.IN_PROGRESS) {
        color = "warning";
      } else if (signer.status === this.$constant.FORM_SIGN_SESSION_STATUS.COMPLETED) {
        color = "success";
      } else if (signer.status === this.$constant.FORM_SIGN_SESSION_STATUS.DECLINED) {
        color = "error";
      }
      return `${color}--text`;
    },
    getStatusText(signer) {
      return this.$t(`formManagement.signatureSession.status.${signer.status}`);
    },
    onSubmit() {
      if (this.selectedEmails.length === 0) return;
      const body = {
        formId: this.formId,
        emails: this.selectedEmails,
        comment: this.message,
      };
      this.isReminding = true;
      remindFormSign({ body })
        .then((data) => {
          this.$emit("submit");
          this.close();
        })
        .catch((err) => {
          throw new Error(err);
        })
        .finally(() => {
          this.isReminding = false;
        });
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>
