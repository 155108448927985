const state = () => ({
  snackbar: {
    show: false,
    text: "",
    timeout: 2000,
    color: null,
    vHtml: false,
  },
});

const getters = {};

const actions = {
  snackbar(
    { commit },
    snackbar = { show: false, text: "", timeout: 2000, color: null, vHtml: false },
  ) {
    commit("setSnackbarVHtml", snackbar.vHtml);
    commit("toggleSnackbar", snackbar.show);
    commit("setSnackbarText", snackbar.text);
    commit("setSnackbarTimeout", snackbar.timeout);
    commit("setSnackbarColor", snackbar.color);
  },
};

const mutations = {
  setSnackbar(state, value) {
    state.snackbar = value;
  },
  toggleSnackbar(state, value) {
    state.snackbar.show = value;
  },
  setSnackbarText(state, value) {
    state.snackbar.text = value;
  },
  setSnackbarTimeout(state, value) {
    state.snackbar.timeout = value;
  },
  setSnackbarColor(state, value) {
    state.snackbar.color = value;
  },
  setSnackbarVHtml(state, value) {
    state.snackbar.vHtml = value;
  },
};

export const snackbar = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
