<script>
import { VCombobox } from "vuetify/lib";
export default {
  extends: VCombobox,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "input-background",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
