import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  templates: [],
  isLoading: false,
  isCreating: false,
});

const getters = {};

const actions = {
  async getTemplates({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);
    return axios
      .get(`${apiUrl}cms/form`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const templates = data.templates;
        commit("setTemplates", templates);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async createTemplate({ state, commit, dispatch }, body) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}cms/form`, { ...body })
      .then(({ data }) => {
        dispatch("getTemplates", state.filter);
        commit("creating", false);
        return data?.template;
      })
      .catch(error => {
        commit("creating", false);
        throw new Error(error);
      });
  },
  async updateTemplate({ commit }, { id, data }) {
    return axios
      .put(`${apiUrl}cms/form/${id}`, {
        ...data,
      })
      .then(({ data }) => {
        const template = data?.template;
        if (template) {
          commit("updateTemplate", template);
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async deleteTemplates({ commit }, ids) {
    return axios
      .delete(`${apiUrl}cms/form`, {
        data: { ids },
      })
      .then(({ data }) => {
        const templates = data?.templates;
        if (Array.isArray(templates)) {
          for (let template of templates) {
            commit("removeTemplate", template.id);
          }
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  updateTemplate(state, template) {
    const index = state.templates.findIndex(x => x.id === template.id);
    if (index !== -1) {
      state.templates.splice(index, 1, template);
    }
  },
  removeTemplate(state, templateId) {
    const index = state.templates.findIndex(x => x.id === templateId);
    if (index !== -1) {
      state.templates.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const cmsFormTemplates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
