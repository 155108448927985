import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let updateCancel;

const state = () => ({
  statusesByModels: [],
  isLoading: false,
  isCreating: false,
  loadingStatusModels: [],
});

const getters = {
  getStatusesByModel: (state) => (model) => {
    const foundModel = state.statusesByModels.find((item) => item.model === model);
    return foundModel ? { ...foundModel.statuses } : {};
  },
  hasFetchedStatuses: (state) => (model) => {
    return state.statusesByModels.some((item) => item.model === model);
  },
  getStatusByModelAndId:
    (state, getters) =>
    ({ model, id }) => {
      const statuses = getters.getStatusesByModel(model);
      if (!statuses || !id) return null;

      const foundStatus = Object.values(statuses)
        .flatMap((category) => category)
        .find((s) => s.id === id);

      return foundStatus || null;
    },
  isLoadingStatusesForModel: (state, getters) => (model) => {
    return state.loadingStatusModels.includes(model);
  },
  isClosedStatus: (state, getters) => (model, statusId) => {
    const statuses = getters.getStatusesByModel(model);
    if (!statuses) return false;

    const doneStatuses = statuses.CLOSED;

    const foundStatus = Object.values(doneStatuses).find((s) => s.id === statusId);

    return !!foundStatus;
  },
};

async function waitForStatus(model, getters) {
  return new Promise((resolve) => {
    const checkStatus = () => {
      if (!getters.isLoadingStatusesForModel(model)) {
        resolve();
      } else {
        setTimeout(checkStatus, 100);
      }
    };

    if (getters.isLoadingStatusesForModel(model)) {
      checkStatus();
    } else {
      resolve();
    }
  });
}

const actions = {
  async getStatuses({ commit, getters }, { model }) {
    if (getters.isLoadingStatusesForModel(model)) {
      return await waitForStatus(model, getters);
    } else {
      commit("addLoadingStatusModel", model);
      return axios
        .get(`${apiUrl}status`, {
          params: { model },
        })
        .then(({ data }) => {
          commit("setStatuses", data);
        })
        .catch((error) => {
          throw new Error(error);
        })
        .finally(() => {
          commit("removeLoadingStatusModel", model);
        });
    }

    //should not return before getters.isLoadingStatusesForModel(model) is false if it is true on start
  },
  async createStatus({ commit }, { status }) {
    if (updateCancel) updateCancel();
    commit("creating", true);
    commit("addStatus", { status, model: status.model });
    return axios
      .post(
        `${apiUrl}status`,
        { ...status },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setStatuses", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      })
      .finally(() => {
        if (!updateCancel) {
          commit("removeUnsavedStatus", status.model);
          commit("creating", false);
        }
      });
  },
  async updateStatus({ commit, getters }, { category, status, model }) {
    if (updateCancel) updateCancel();
    commit("updateStatus", { model, category, status });
    return axios
      .put(
        `${apiUrl}status`,
        { model, statuses: getters.getStatusesByModel(model) },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setStatuses", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  async updateStatuses({ commit }, { model, statuses }) {
    if (updateCancel) updateCancel();
    commit("setStatuses", { statuses, model });
    return axios
      .put(
        `${apiUrl}status`,
        { model, statuses },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(({ data }) => {
        commit("setStatuses", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  async deleteStatus({ commit, getters }, { status, category, model }) {
    if (updateCancel) updateCancel();

    commit("removeStatus", { model, category, status });
    return axios
      .put(
        `${apiUrl}status`,
        { model, statuses: getters.getStatusesByModel(model) },
        {
          cancelToken: new CancelToken(function executor(c) {
            updateCancel = c;
          }),
        },
      )
      .then(async ({ data }) => {
        commit("setStatuses", data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
};

const mutations = {
  setStatuses(state, { statuses, model }) {
    const indexToUpdate = state.statusesByModels.findIndex(
      (item) => item.model === model,
    );

    if (indexToUpdate !== -1) {
      state.statusesByModels[indexToUpdate].statuses = statuses;
    } else {
      state.statusesByModels.push({ model, statuses });
    }
    return state;
  },
  addStatus(state, { status, model }) {
    const modelIndex = state.statusesByModels.findIndex((item) => item.model === model);

    if (modelIndex !== -1) {
      const category = state.statusesByModels[modelIndex].statuses[status.category];
      if (category) {
        category.push(status);
      } else {
        state.statusesByModels[modelIndex].statuses[status.category] = [status];
      }
    } else {
      const newModel = {
        model,
        statuses: {
          [status.category]: [status],
        },
      };
      state.statuses.push(newModel);
    }
  },

  removeUnsavedStatus(state, model) {
    const modelToUpdate = state.statusesByModels.find((item) => item.model === model);

    if (modelToUpdate) {
      const modelCategories = modelToUpdate.statuses;

      for (const category in modelCategories) {
        modelCategories[category] = modelCategories[category].filter((s) => s.id);
      }
    }
  },
  updateStatus(state, { model, category, status }) {
    if (!status) throw new Error("Status not found");

    const modelToUpdate = state.statusesByModels.find((item) => item.model === model);

    if (modelToUpdate) {
      const categoryToUpdate = modelToUpdate.statuses[category];

      if (categoryToUpdate) {
        const index = categoryToUpdate.findIndex((x) => x.id === status.id);

        if (index !== -1) {
          categoryToUpdate.splice(index, 1, status);
        }
      }
    }
  },
  removeStatus(state, { model, category, status }) {
    if (!status) throw new Error("Status not found");

    const modelToUpdate = state.statusesByModels.find((item) => item.model === model);

    if (modelToUpdate) {
      const categoryToUpdate = modelToUpdate.statuses[category];
      const index = categoryToUpdate.findIndex((x) => x.id === status.id);

      if (index !== -1) {
        categoryToUpdate.splice(index, 1);
      }
    }
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
  addLoadingStatusModel(state, model) {
    state.loadingStatusModels.push(model);
  },
  removeLoadingStatusModel(state, model) {
    let index = state.loadingStatusModels.indexOf(model);
    if (index > -1) {
      state.loadingStatusModels.splice(index, 1);
    }
  },
};

export const statuses = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
