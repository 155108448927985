import _ from "lodash";

export const filtersWatchMixins = {
  //this mixin required showDrawer, filtersMixins_isLoadingFilters, filtersMixins_filters, getData
  //fetches data when filters is opened, or filter down tasks for forms when projectIds change
  data() {
    return {
      //for autocomplete/select components that handle their own fetching
      isReadyToInitialFetch: false,
    };
  },
  computed: {
    routeParamProjectId() {
      return this.$route.params.projectId;
    },
  },
  watch: {
    //logic to fetch task and form when active project changes
    routeParamProjectId: {
      handler(newVal, oldVal) {
        if (this.showDrawer && newVal !== oldVal) {
          this.getForms?.();
          this.getTasks?.();
        }
      },
    },
    //logic to fetch data initially
    showDrawer: {
      handler(val) {
        if (val && !this.filtersMixins_isLoadingFilters) {
          this.getData?.();
          this.isReadyToInitialFetch = true;
        }
      },
      immediate: true,
    },
    filtersMixins_isLoadingFilters: {
      handler(val) {
        if (!val && this.showDrawer) {
          this.getData?.();
          this.isReadyToInitialFetch = true;
        }
      },
      immediate: true,
    },
    //Logic to fetch task and form when project updates, or when task or form updates
    filtersMixins_filters: {
      handler(newVal, oldVal) {
        if (_.isEqual(newVal, oldVal)) return;

        const isProjectIdsChangedAndIsNotInitialFetch =
          oldVal !== null &&
          oldVal !== undefined &&
          newVal?.projectIds !== oldVal?.projectIds;

        const isFormIdsChangedAndIsNotInitial =
          oldVal !== null &&
          oldVal !== undefined &&
          newVal?.belongsToFormIds !== oldVal?.belongsToFormIds;

        if (isProjectIdsChangedAndIsNotInitialFetch) {
          this.getForms?.();
        }

        if (isFormIdsChangedAndIsNotInitial) {
          this.getForms?.();
        }
      },
    },
  },
};
