import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getProductStatistics() {
  return axios
    .get(`${apiUrl}statistics/product`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
