import byteSize from "byte-size";

export function normalizeCmsDocumentTemplates(templates) {
  return templates.map((template) => normalizeCmsDocumentTemplate(template));
}

export function normalizeCmsDocumentTemplate(template) {
  if (template?.document?.size) {
    template.document.byteSize = `${byteSize(template.document.size)}`;
  }
  template.isLoading = false;
  template.isSelected = false;

  return template;
}
