<template>
  <AppGroupByBtn :tableModel="tableModel" :menuItems="menuItems" />
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
export default {
  mixins: [groupByMixins],
  props: {
    tableModel: String,
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    menuItems() {
      const menuItems = [
        {
          text: this.$options.filters.capitalize(this.$t("common.noGroupText")),
          icon: this.$icon.REGULAR.ACTION.EMPTY,
          isSelected: this.groupByMixins_isGroupSelected(undefined),
          click: () => this.groupByMixins_setGroupBy(undefined),
        },
        {
          text: this.$t("common.date"),
          icon: this.$icon.REGULAR.COMMON.CALENDAR,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.DATE,
          ),
          click: () => this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.DATE),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.status")),
          icon: this.$icon.REGULAR.COMMON.SQUARE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.STATUS,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.STATUS),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.user")),
          icon: this.$icon.REGULAR.ROUTE.USER,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.USER,
          ),
          click: () => this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.USER),
        },
        {
          text: this.$t("common.project"),
          icon: this.$icon.REGULAR.ROUTE.PROJECT,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.PROJECT,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.PROJECT),
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.task")),
          icon: this.$icon.REGULAR.ROUTE.TASK,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.TASK,
          ),
          click: () => this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.TASK),
        },
      ];

      if (this.hasTimeEntriesIntegrationEnabled) {
        menuItems.push({
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.accounting"),
          ),
          icon: this.$icon.REGULAR.FILE.FILE_INVOICE,
          isSelected: this.groupByMixins_isGroupSelected(
            this.filtersMixins_dataFields.TRANSFERRED,
          ),
          click: () =>
            this.groupByMixins_setGroupBy(this.filtersMixins_dataFields.TRANSFERRED),
        });
      }

      return menuItems;
    },
  },
};
</script>
