<template>
  <v-menu offset-y :top="top" :transition="transition">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center cursor-pointer" v-bind="attrs" v-on="on">
        <AppUserAvatar large :user="profile" v-if="profile" />
      </div>
    </template>
    <div class="pa-2 ui-background d-flex flex-column" style="min-width: 70px">
      <slot></slot>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    transition: {
      default: "slide-y-transition",
      type: String,
    },
    text: String,
    loading: Boolean,
    top: Boolean,
    emoji: String,
    profile: Object,
  },
};
</script>
