<script>
import { VChip } from "vuetify/lib";

export default {
  props: {
    item: Object,
  },
  methods: {
    getItemAnswerColor(value) {
      let color = "disabled";
      switch (value) {
        case "YES":
          color = "success";
          break;
        case "NO":
          color = "error";
          break;
      }
      return color;
    },
    genAnswerElm() {
      const answer = this.$te(
        `templateManagement.form.builder.item.values.${this.item.value}`,
      )
        ? this.$t(`templateManagement.form.builder.item.values.${this.item.value}`)
        : this.item.value;
      if (!answer)
        return this.$createElement(
          "div",
          { class: "error--text" },
          this.$t("formManagement.preview.noAnswer"),
        );

      const color = this.getItemAnswerColor(this.item.value);
      return this.$createElement(
        VChip,
        {
          props: {
            color,
            label: true,
            small: true,
          },
        },
        [
          this.$createElement(
            "div",
            {
              class: "px-3",
            },
            answer.toUpperCase(),
          ),
        ],
      );
    },
  },
  render(h) {
    return this.genAnswerElm();
  },
};
</script>
