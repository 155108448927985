<template>
  <v-card
    class="border-a overflow-y-auto rounded-t-lg rounded-br-0 rounded-bl-0"
    :disabled="isLoading"
    :loading="isLoading"
    rounded
    outlined
    :height="height ? height : undefined"
    :width="width ? width : undefined"
  >
    <div class="pa-5">
      <v-slide-y-transition>
        <AppTask :task="task" :isUpdating="isUpdating" @task:update="updateTask" />
      </v-slide-y-transition>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    task: Object,
    isLoading: Boolean,
    isUpdating: Boolean,
    height: String || Number,
    width: String || Number,
  },
  data() {
    return {};
  },
  methods: {
    updateTask(data) {
      this.$emit("task:update", data);
    },
  },
};
</script>
