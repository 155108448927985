<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VCard } from "vuetify/lib";

export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  computed: {
    timeLimitValue() {
      return this.item?.values?.timeLimit || null;
    },
  },
  methods: {
    genNameField() {
      const nameDiv = this.$createElement(
        "div",
        {
          class: "d-flex align-center pl-5",
          style: "height: 65px;",
        },
        [this.$createElement("div", this.item?.name)],
      );

      return nameDiv;
    },
    genAddTimeLimitColumn() {
      return this.timeLimitValue
        ? this.$createElement(
            "div",
            { class: "d-flex align-center block-draggable text-border-reset" },
            [
              this.$createElement(
                "span",
                {
                  class: "font-italic text-body-2 pr-1",
                },
                this.timeLimitValue,
              ),
              this.$createElement(
                "span",
                {
                  class: "pl-1 font-italic text-body-2",
                },
                this.$t("templateManagement.project.builder.task.days"),
              ),
            ],
          )
        : null;
    },
    genTaskItemRow() {
      return this.$createElement(
        VCard,
        {
          style: "height: 65px;",
          class: `pa-2 draggable-item d-flex align-center`,
          props: { flat: true, tile: true },
        },
        [
          this.$createElement(
            "div",
            {
              class: "text-border-reset",
              style: `width: ${this.projectTemplateBuilderMixins_taskNameWidth}%;;`,
            },
            [this.genNameField()],
          ),
          this.genAddTimeLimitColumn(),
        ],
      );
    },
  },
  render(h) {
    return this.genTaskItemRow();
  },
};
</script>
