<template>
  <div>
    <AppNavigationList>
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.personal") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.PROFILE }"
        :prependIcon="$icon.REGULAR.ROUTE.PROFILE_SETTINGS"
        :title="$t('drawer.route.profile')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.WORKSPACES }"
        :prependIcon="$icon.REGULAR.ROUTE.WORKSPACE"
        :title="$t('drawer.route.workspaces')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.NOTIFICATIONS }"
        :prependIcon="$icon.REGULAR.ROUTE.NOTIFICATION"
        :title="$t('drawer.route.alerts')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
    <AppMiniContentSelectionDivider v-if="isDomainOwner || isDomainAdmin" />
    <AppNavigationList v-if="isDomainOwner || isDomainAdmin">
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.workspace") }}
      </AppContentSectionHeader>

      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.ACCOUNT }"
        :prependIcon="$icon.REGULAR.ROUTE.ACCOUNT_SETTINGS"
        :title="$t('drawer.route.account')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        v-if="isDomainOwner"
        :to="{ name: $routeNames.SETTINGS.BILLING }"
        :prependIcon="$icon.REGULAR.ROUTE.BILLING"
        :title="$t('drawer.route.billing')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.PRODUCTS }"
        :prependIcon="$icon.REGULAR.ROUTE.PRODUCT"
        :title="$t('drawer.route.products')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.SYSTEM_SETTINGS }"
        :prependIcon="$icon.REGULAR.ROUTE.SYSTEM_SETTINGS"
        :title="$t('drawer.route.systemSettings')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
    <AppMiniContentSelectionDivider v-if="isDomainOwner || isDomainAdmin" />
    <AppNavigationList v-if="isDomainOwner || isDomainAdmin">
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.settings") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.MODULE_CONTROL }"
        :prependIcon="$icon.REGULAR.ROUTE.MODULE_CONTROL"
        :title="$t('drawer.route.moduleControl')"
        prepend-icon-small
        replace
      />
      <AppNavigationItem
        :to="{ name: $routeNames.SETTINGS.INTEGRATIONS }"
        :prependIcon="$icon.REGULAR.ROUTE.INTEGRATION_SETTINGS"
        :title="$t('drawer.route.integrations')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
    <AppMiniContentSelectionDivider v-if="adminAccessByKey('SUPPORT')" />
    <AppNavigationList v-if="adminAccessByKey('SUPPORT')">
      <AppContentSectionHeader>
        {{ $t("drawer.routeLabel.support") }}
      </AppContentSectionHeader>
      <AppNavigationItem
        v-if="adminAccessByKey('SUPPORT')"
        :to="{ name: $routeNames.SETTINGS.SUPPORT_WORKSPACES }"
        :prependIcon="$icon.REGULAR.ROUTE.WORKSPACE"
        :title="$t('drawer.route.supportWorkspaces')"
        prepend-icon-small
        replace
      />
    </AppNavigationList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      activeDomainId: "activeDomainId",
      isCmsAdmin: "isCmsAdmin",
      adminAccessByKey: "adminAccessByKey",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
  },
};
</script>
