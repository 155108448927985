<template>
  <tr class="list_tr" :class="highlightedClass" @contextmenu="onMenuClick">
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.STATUS)">
      <AppCustomStatusIcon
        @click="(e) => $emit('status:click', { ...e, selectedItem: task })"
        model="task"
        :statusId="statusId"
        :loading="task.isUpdatingStatus"
        :disabled="isDisabledStatus"
      />
    </td>
    <td>
      <div
        class="underline-on-hover cursor-pointer table-cell-content__breakable-long-text"
        @click="onGoToTaskClick"
      >
        {{ task.name }}
      </div>
    </td>
    <td>
      <div class="d-flex justify-end">
        <AppCountChip
          v-for="(countData, index) in countItems"
          v-if="countData.count"
          :key="index"
          :count="countData.count > 99 ? '99+' : countData.count"
          :icon="countData.icon"
          :text="countData.text"
          :class="index === 0 && countData.count ? '' : 'ml-2'"
          @click="onCountChipClick(countData.to)"
        />
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.RESPONSIBLE)">
      <template v-if="task.member">
        <AppUserAvatar :user="task.member" show-name :truncate="22" />
      </template>
    </td>
    <td>
      {{
        $tc(
          "taskManagement.list.participantAmount",
          Array.isArray(task.collaborators) ? task.collaborators.length : 0,
        )
      }}
    </td>
    <td
      v-if="
        model !== 'project' && groupByMixins_showColumn(filtersMixins_dataFields.PROJECT)
      "
    >
      <div v-if="task.project" class="table-cell-content__breakable-long-text">
        <AppActionText
          :to="{
            name: $routeNames.PROJECT.VIEW,
            params: { projectId: this.task?.project?.id },
          }"
        >
          {{ task.project.name }}
        </AppActionText>
      </div>
    </td>
    <td>
      <div class="table-cell-content__breakable--margin">
        <AppActionText
          v-if="task.formItem"
          :to="{
            name: $routeNames.FORM_VIEW.ROOT,
            params: { formId: task.formItem.formId },
          }"
        >
          {{ task.formItem.name }}
        </AppActionText>
      </div>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.START_DATE)">
      <template v-if="task.startDate">
        <div class="d-flex align-center">
          <div>{{ formatDate(task.startDate) }}</div>
        </div>
      </template>
    </td>
    <td v-if="groupByMixins_showColumn(filtersMixins_dataFields.DUE_DATE)">
      <template v-if="task.dueDate">
        <div class="d-flex align-center">
          <div>{{ formatDate(task.dueDate) }}</div>
          <div v-if="repeat">
            <AppRepeatIcon small style="padding-bottom: 2px" class="ml-3" />
          </div>
        </div>
      </template>
    </td>
    <td>
      <v-btn icon @click="onMenuClick">
        <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { groupByMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins],
  props: {
    task: Object,
    model: String,
    isHighlighted: Boolean,
    headers: Array,
    tableModel: String,
  },
  computed: {
    isDisabledStatus() {
      if (this.task.isUpdatingStatus) return true;
      if (!this.task.permissions?.canEdit) return true;
    },
    highlightedClass() {
      return this.isHighlighted ? "list_tr--selected" : "";
    },
    isProject() {
      return this.model === "project";
    },
    repeat() {
      return this.task?.repeatOption?.repeat;
    },
    statusId() {
      return this.task?.statusId;
    },
    countItems() {
      const count = this.task.count;
      if (!count) return [];
      return [
        {
          count: count.comments,
          icon: this.$icon.LIGHT.ROUTE.COMMENT,
          text: this.$tc("itemCounts.comments", count.comments),
          to: {
            name: this.isProject
              ? this.$routeNames.PROJECT.TASK_LIST_MENU_COMMENTS
              : this.$routeNames.TASK.LIST_MENU_COMMENTS,
            params: { taskId: this.task.id },
          },
        },
        {
          count: count.attachments,
          icon: this.$icon.LIGHT.ROUTE.ATTACHMENT,
          text: this.$tc("itemCounts.attachments", count.attachments),
          to: {
            name: this.isProject
              ? this.$routeNames.PROJECT.TASK_LIST_MENU_STORAGE
              : this.$routeNames.TASK.LIST_MENU_STORAGE,
            params: { taskId: this.task.id, folderId: this.task.folderId },
          },
        },
        {
          count: count.forms,
          icon: this.$icon.LIGHT.ROUTE.FORM,
          text: this.$tc("itemCounts.forms", count.forms),
          to: {
            name: this.isProject
              ? this.$routeNames.PROJECT.TASK_LIST_MENU_FORMS
              : this.$routeNames.TASK.LIST_MENU_FORMS,
            params: { taskId: this.task.id },
          },
        },
        {
          count: count.productUsages,
          icon: this.$icon.LIGHT.ROUTE.PRODUCT,
          text: this.$tc("itemCounts.products", count.productUsages),
          to: {
            name: this.isProject
              ? this.$routeNames.PROJECT.TASK_LIST_MENU_PRODUCTS_USAGE
              : this.$routeNames.TASK.LIST_MENU_PRODUCTS_USAGE,
            params: { taskId: this.task.id },
          },
        },
      ];
    },
  },
  methods: {
    onCountChipClick(route) {
      const isSameTask = this.$route.params.taskId === route.params.taskId;

      if (this.isUnderRoute(route.name) && isSameTask) {
        this.$router.replace({
          name: this.isProject
            ? this.$routeNames.PROJECT.VIEW_TASK
            : this.$routeNames.TASK.ROOT,
          params: { taskId: this.task.id },
        });
      } else {
        this.$router.replace({
          name: route.name,
          params: route.params,
        });
      }
      this.$emit("task:select");
      this.$emit("filters:close");
    },
    isUnderRoute(routeName) {
      return this.$route.matched.some((m) => m.name === routeName);
    },
    onGoToTaskClick() {
      this.$emit("name:click", { task: this.task });
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    onTaskUpdate(body) {
      this.$emit("task:update", { task: this.task, body });
    },
    onMenuClick(event) {
      this.$emit("menu:click", { task: this.task, event });
    },
  },
};
</script>

<style>
.v-small-dialog__activator__content {
  float: left;
}
</style>
