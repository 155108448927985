import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let updateCancel;

const state = () => ({
  todos: [],
  isLoading: false,
});

const getters = {
  hasUnsavedToDo: (state) => state.todos.some((toDo) => !toDo.id),
  toDoById: (state) => (id) => state.todos.find((x) => x.id === id),
  doneCount: (state) => state.todos.filter((x) => x.isDone).length,
  totalCount: (state) => state.todos.length,
  donePercentage: (state, getters) => {
    if (getters.totalCount === 0) return 0;
    return Math.round((getters.doneCount / getters.totalCount) * 100);
  },
};

const actions = {
  async getTodos({ commit }, { model, modelId }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}to-do`, {
        params: { model, modelId },
      })
      .then(({ data }) => {
        const todos = data?.todos;
        commit("setTodos", todos);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async createToDo({ commit }, { body }) {
    return axios
      .post(`${apiUrl}to-do`, body)
      .then(({ data }) => {
        const toDo = data?.toDo;
        commit("updateToDo", { toDo, isUnsaved: true });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateToDo({ getters, commit }, { id, body }) {
    if (updateCancel) updateCancel();
    const oldToDo = getters.toDoById(id);
    commit("updateToDo", { toDo: { ...oldToDo, ...body } });

    return axios
      .put(`${apiUrl}to-do/${id}`, body, {
        cancelToken: new CancelToken(function executor(c) {
          updateCancel = c;
        }),
      })
      .then(({ data }) => {
        const toDo = data?.toDo;
        commit("updateToDo", { toDo });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("updateToDo", { toDo: oldToDo });
          throw new Error(error);
        }
      });
  },
  async moveToDo({ commit }, { id, toDo, body }) {
    commit("moveToDo", { toDo, index: body.index });
    return axios
      .put(`${apiUrl}to-do/${id}`, body, {
        cancelToken: new CancelToken(function executor(c) {
          updateCancel = c;
        }),
      })
      .then(({ data }) => {
        const toDo = data?.toDo;
        commit("updateToDo", { toDo });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  async deleteToDo({ commit }, { id }) {
    commit("removeToDo", { id });
    return axios
      .delete(`${apiUrl}to-do/${id}`)
      .then(() => {})
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setTodos(state, todos) {
    state.todos = todos;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  addUnsavedToDo(state, { nextIndex }) {
    const newToDo = {
      id: null,
      index: nextIndex,
      name: "",
    };
    state.todos.push(newToDo);
  },
  updateToDo(state, { toDo, isUnsaved }) {
    const index = state.todos.findIndex((x) => (isUnsaved ? !x.id : x.id === toDo.id));

    if (index !== -1) {
      state.todos.splice(index, 1, toDo);
    }
  },
  moveToDo(state, { toDo, index }) {
    const oldIndex = state.todos.findIndex((x) => x.id === toDo.id);
    if (oldIndex !== -1) {
      state.todos.splice(oldIndex, 1);
    }

    state.todos.splice(index, 0, toDo);
    for (const [index, item] of state.todos.entries()) {
      item.index = index;
    }
  },
  removeToDo(state, { id, isUnsaved }) {
    const index = state.todos.findIndex((x) => (isUnsaved ? !x.id : x.id === id));
    if (index !== -1) {
      state.todos.splice(index, 1);
    }
  },
};

export const todos = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
