const state = () => ({
  visitedRoutes: [],
  direction: "",
});

const getters = {};

const actions = {};

const mutations = {
  addVisitedRoute(state, route) {
    state.visitedRoutes.push(route);
  },
  removeLastRouteFromHistory(state) {
    state.visitedRoutes.pop();
  },
};

export const route = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
