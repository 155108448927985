<script>
import { VForm } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
  },
  data() {
    return {
      value: {
        isValid: false,
      },
    };
  },
  computed: {
    rules() {
      let rules = [];
      if (this.item?.isRequired) {
        rules = this.$rules.REQUIRED;
      }
      return rules;
    },
  },
  methods: {
    onChange(e) {
      const newVal = typeof e === "object" && e !== null ? e?.target?.value : e;
      const oldVal = this.item.value;

      const isValidText = this.formTemplateBuilderMixins_validateTextInputValue({
        newVal,
        oldVal,
      });

      if (isValidText && this.value.isValid) {
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { value: newVal },
        });
      }
    },
    genForm() {
      return this.$createElement(
        VForm,
        {
          props: { value: this.value.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.value.isValid = e),
          },
        },
        [
          this.$createElement("AppDefaultTextarea", {
            props: {
              label: this.$t("templateManagement.form.builder.item.type.TEXTAREA"),
              value: this.item?.value,
              rules: this.rules,
              dense: false,
              hideDetails: true,
            },
            on: { blur: (e) => this.onChange(e) },
          }),
        ],
      );
    },
  },
  render(h) {
    return h("div", [this.genForm()]);
  },
};
</script>
