import { clientPermissions } from "../../util";

export function normalizeClients(clients, currentUser) {
  return clients.map((client) => {
    return normalizeClient(client, currentUser);
  });
}

export function normalizeClient(client, currentUser) {
  const permissions = clientPermissions(client, currentUser);
  return { ...client, ...permissions };
}
