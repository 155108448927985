import { fullname } from "@/helpers/util";

export function normalizeWorkspaces(domains) {
  return (domains || []).map((domain) => normalizeWorkspace(domain));
}

export function normalizeWorkspace(domain) {
  const subscription = domain?.subscription;
  const licenses = subscription?.licenses;
  const licensesText = `${licenses?.used || "?"}/${licenses?.total || "?"}`;

  return {
    ...domain,
    domainUserStatus: domain.domainUser?.status,
    owner: {
      ...domain.owner,
      name: fullname(domain.owner),
    },
    subscription: {
      ...subscription,
      licenses: {
        ...licenses,
        text: licensesText,
      },
    },
  };
}
