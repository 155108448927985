<script>
import Title from "./Title.vue";
import DocumentTemplateItem from "./DocumentTemplateItem.vue";
import { VIcon, VBtn } from "vuetify/lib";

export default {
  props: {
    templateId: String,
    documentTemplateItems: Array,
    type: String,
    isAddingDocuments: Boolean,
  },
  data() {
    return {
      importDialog: false,
    };
  },
  methods: {
    removeBorderBotIfNoRows() {
      return this.documentTemplateItems.length === 0 ? "" : "border-b";
    },
    genDocumentTemplateItems() {
      const documentTemplateRows = [];
      for (const item of this.documentTemplateItems) {
        const documentTemplateRow = this.$createElement(DocumentTemplateItem, {
          props: {
            item,
            isFirst: item === this.documentTemplateItems[0],
            isLast:
              item === this.documentTemplateItems[this.documentTemplateItems.length - 1],
          },
          on: {
            "item:update": (e) => this.$emit("item:update", e),
            "item:delete": (e) => this.$emit("item:delete", e),
          },
        });
        documentTemplateRows.push(documentTemplateRow);
      }
      return this.$createElement(
        "div",
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden project-template__item-container`,
          props: {
            list: this.documentTemplateItems,
          },
        },
        documentTemplateRows,
      );
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.documentTemplateItems.length === 0 ? "rounded-b-lg" : "";
    },
    afterDocumentSelected(e) {
      const items = e.map((document) => ({
        name: document.name,
        documentId: document.id,
        index: 0,
      }));
      const body = { type: this.type, items };
      this.$emit("items:create", { body });
    },
  },
  render(h) {
    return h("div", [
      h(Title, {
        props: {
          title: this.$t(`templateManagement.project.builder.${this.type}.title`),
        },
      }),
      h("div", [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [
            h(
              "div",
              this.$t(`templateManagement.project.builder.${this.type}.tableHeader`),
            ),
          ],
        ),
        this.genDocumentTemplateItems(),
      ]),
      h("div", { class: "pt-2 mb-4" }, [
        h(
          VBtn,
          {
            props: { text: true, loading: this.isAddingDocuments },
            on: {
              click: () => (this.importDialog = true),
            },
          },
          [
            h(VIcon, { class: "pr-3" }, this.$icon.LIGHT.ACTION.CIRCLE_PLUS),
            h(
              "div",
              { class: "font-weight-bold", attrs: { id: "create-document-btn" } },
              this.$t(`templateManagement.project.builder.${this.type}.buttonText`),
            ),
          ],
        ),
      ]),
      h("AppImportedDocumentPickerDialog", {
        props: {
          dialog: this.importDialog,
          group: this.type,
          templateId: this.templateId,
        },
        on: {
          "dialog:change": (e) => (this.importDialog = e),
          "documents:selected": (e) => this.afterDocumentSelected(e),
        },
      }),
    ]);
  },
};
</script>
