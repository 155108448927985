<template>
  <div class="p-relative">
    <div class="label--small">
      {{ $t(labelKey) | capitalize }}
    </div>
    <AppClientAutocomplete
      v-model="localValue"
      multiple
      clearable
      v-bind="params"
      v-on="listeners"
    />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    value: Array,
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
