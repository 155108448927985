<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto d-flex justify-center align-center"
  >
    <div class="text-center pa-2">
      <img class="mb-7" :src="getEmptyStateIcon()" />
      <div class="text-h5 font-weight-bold mb-2">
        {{ $t("formManagement.signatureSession.navMenu.NOT_STARTED.title") }}
      </div>
      <div class="text-body-1 mb-7">
        {{ $t("formManagement.signatureSession.navMenu.NOT_STARTED.subTitle") }}
      </div>
      <v-btn depressed color="primary" @click="openDialog">
        {{ $t("formManagement.signatureSession.navMenu.NOT_STARTED.button") }}
        <AppSubscriptionPlanStar
          v-if="!planRestrictionsMixins_canSendFormToSign"
          class="pl-2"
          :planCode="$constant.PLAN_CODE.GRIPR_PRO"
          small
        />
      </v-btn>
    </div>
    <AppFormSignatureAddRecipients
      :formId="formId"
      v-model="dialog.addRecipientsToESign"
      @submit="afterSubmit"
    />
  </div>
</template>

<script>
import emptyStateImage from "../../../../../assets/empty_state_signature.svg";
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins],
  data() {
    return {
      dialog: {
        addRecipientsToESign: false,
      },
    };
  },
  props: {
    formId: String,
    showStar: Boolean,
    planCode: String,
  },
  methods: {
    getEmptyStateIcon() {
      return emptyStateImage;
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    openDialog() {
      if (!this.planRestrictionsMixins_canSendFormToSign) {
        this.openUpgradePlanDialog();
      } else {
        this.dialog.addRecipientsToESign = true;
      }
    },
    afterSubmit(e) {
      this.$emit("afterSubmit", e);
    },
  },
};
</script>
