import i18n from "@/plugins/i18n";
const categoriesTranslation = i18n.t("cms.template.document.category");

export function normalizeDocumentCategories(categories) {
  const normalizedCategories = (categories || []).map((category) => {
    const name = category.name;
    const translatedName = categoriesTranslation[name?.toLowerCase()];
    return {
      ...category,
      value: category.id,
      text: translatedName ?? name,
    };
  });
  return normalizedCategories;
}
