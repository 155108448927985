<script>
import Title from "./Title.vue";
import { VIcon, VBtn, VTreeview } from "vuetify/lib";
import StorageItem from "./StorageItem.vue";
export default {
  props: {
    storageItems: Array,
    storageTreeView: Array,
  },
  data() {
    return {
      opened: [],
    };
  },
  methods: {
    genTreeView() {
      if (this.storageTreeView.length === 0) return;
      return this.$createElement(VTreeview, {
        class: "border-a rounded-b-lg",
        props: {
          items: this.storageTreeView,
          openOnClick: true,
          openAll: true,
          transition: true,
          open: this.opened,
        },
        on: {
          "update:open": (e) => {
            this.opened = e;
          },
        },
        scopedSlots: {
          label: ({ item, open }) =>
            this.$createElement(StorageItem, {
              props: { item, open, storageItems: this.storageItems },
            }),
        },
      });
    },
    openFolder(item) {
      this.opened = [...new Set([...this.opened, item.id])];
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.storageItems.length === 0 ? "border-b rounded-b-lg" : "";
    },
  },

  render(h) {
    return h("div", [
      h(Title, {
        props: { title: this.$t("templateManagement.project.builder.storage.title") },
      }),
      h(
        "div",
        {
          class: `d-flex rounded-t-lg pa-2 px-4 ${this.showBorderAndBorderRadiusIfNoItems()}  border-l border-t border-r text-subtitle-2`,
        },
        [h("div", this.$t("templateManagement.project.builder.storage.name"))],
      ),
      this.genTreeView(),
    ]);
  },
};
</script>
