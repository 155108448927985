import { MODULE_FIELD_CONFIG } from "@/helpers/constants";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchContacts({ params } = {}) {
  return axios
    .get(`${apiUrl}search/contact`, {
      params: {
        fields: MODULE_FIELD_CONFIG.USER_AND_CONTACT.CONTACT_FIELDS_QUERY_PARAM,
        ...params,
      },
    })
    .then(({ data }) => {
      const contacts = formatItemWithSearchStr(data?.contacts, "USER_AND_CONTACT");
      return contacts;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
