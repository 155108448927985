<script>
export default {
  props: {
    note: String,
    item: Object,
  },
  data() {
    return {
      value: {
        isValid: false,
      },
    };
  },
  methods: {},
  render(h) {
    return h("div", { class: "py-2" }, [
      this.$createElement(
        "span",
        { class: "text-body-2 font-weight-bold" },
        this.$t("formManagement.preview.note"),
      ),
      this.$createElement("div", this.note),
    ]);
  },
};
</script>
