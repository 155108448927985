<template>
  <AppWidgetTemplate
    @delete:click="onDelete"
    :title="$t('widgetLibrary.urlEmbed.title')"
    v-bind="templateProps"
  >
    <template v-slot:append-header>
      <AppToggleMenuBtn>
        <AppMenuItem
          text="Delete url"
          :icon="$icon.REGULAR.ACTION.LOG_OUT"
          color="error"
          @click="onDeleteUrl"
        />
      </AppToggleMenuBtn>
    </template>
    <template v-if="url">
      <iframe
        v-if="!isIframeUnderSameLocation"
        class="w-100"
        style="height: calc(100% - 44px)"
        :src="url"
        frameborder="0"
        allowfullscreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </template>
    <template v-else>
      <v-form
        class="d-flex align-center justify-center"
        style="height: calc(100% - 44px)"
        ref="urlForm"
        v-model="isValid"
        v-on:submit.prevent
      >
        <div class="d-flex w-100 justify-center px-3">
          <AppDefaultTextField
            class="mr-2 mw-650px"
            v-model="urlInput"
            placeholder="https://example.com"
            @keyup.enter="onSaveUrl"
            :rules="$rules.URL_RULES"
          />
          <v-btn depressed color="primary" @click="onSaveUrl">
            {{ $t("widgetLibrary.urlEmbed.saveLink") }}
          </v-btn>
        </div>
      </v-form>
    </template>
  </AppWidgetTemplate>
</template>

<script>
export default {
  props: {
    templateProps: Object,
    url: String,
  },
  data() {
    return {
      urlInput: "",
      isValid: false,
    };
  },
  computed: {
    isIframeUnderSameLocation() {
      return window.location !== window.parent.location;
    },
  },
  methods: {
    onSaveUrl() {
      const isValid = this.$refs?.urlForm?.validate();
      if (!isValid) return;
      this.$emit("item:update", { url: this.urlInput });
    },
    onDeleteUrl() {
      this.$emit("item:update", { url: null });
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
