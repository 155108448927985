<script>
import moment from "moment";
import { VContainer, VCard, VDivider } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import SectionHeader from "./SectionHeader.vue";
import ItemHierarchy from "./ItemHierarchy.vue";
import SessionSignatureSection from "./SessionSignatureSection.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    form: Object,
    formItems: Array,
    company: Object,
    sender: Object,
    signer: Object,
    isLoading: Boolean,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    dueDate() {
      if (!this.form?.dueDate) return "";
      return moment(this.form?.dueDate).format("DD.MM.YYYY");
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    genCompanyHeaderRow() {
      if (!this.company) return null;
      return this.$createElement("AppCompanyHeader", {
        props: {
          company: this.company,
          isMobile: this.isMobile,
        },
      });
    },
    genHeader() {
      return this.$createElement("div", [
        this.genCompanyHeaderRow(),
        this.$createElement("div", { class: "text-h4 pb-3" }, this.form?.name),
        this.$createElement("div", { class: "pb-10" }, this.form?.description),
        this.$createElement("AppFormPreviewInfoRows", {
          props: {
            form: this.form,
          },
        }),
      ]);
    },
    genSection(item) {
      return this.$createElement("div", { class: "d-flex flex-column py-3" }, [
        this.$createElement(SectionHeader, { props: { item } }),
        this.$createElement(ItemHierarchy, {
          props: { items: this.formItems, item },
          on: {
            "item:getImages": (e) => this.$emit("item:getImages", e),
            "item:taskClick": (e) => this.onItemTaskClick(e),
            // "item:imagePreview": (e) => this.onPreviewDialogOpen(e),
          },
        }),
      ]);
    },
    genItemBuilder(items) {
      return items.map((item, index) => {
        const isLast = index === items.length - 1;
        return [
          this.$createElement(VDivider, { props: { horizontal: true } }),
          this.genSection(item),
          isLast && this.$createElement(VDivider, { props: { horizontal: true } }),
        ];
      });
    },
    genSignatureSection() {
      return this.$createElement(SessionSignatureSection, {});
    },
    genCard() {
      const rootItems = this.formTemplateBuilderMixins_getRootItems(this.formItems || []);

      return this.$createElement(
        VCard,
        {
          class: "border-a",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading || this.disabled,
          },
        },
        [
          this.$createElement("div", { class: "pa-6" }, [
            ...[
              this.isLoading || !this.form
                ? []
                : [
                    this.genHeader(),
                    this.genItemBuilder(rootItems),
                    this.genSignatureSection(),
                  ],
            ],
          ]),
        ],
      );
    },
  },
  render(h) {
    return h(VContainer, [this.genCard()]);
  },
};
</script>
