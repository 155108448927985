import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import i18n from "../../../plugins/i18n";
import { v4 } from "uuid";
import { normalizePathData } from "@/helpers/normalization";
const pathTrackId = v4();

const state = () => ({
  items: [],
  path: [],
  truncatedPath: [],
  prependPath: [],
  currentFolder: null,
  isLoading: false,
  isMoving: false,
  isPathLoading: false,
});

const getters = {
  isLoading: (state) => {
    return state.isPathLoading || state.isLoading;
  },
};

const actions = {
  async getFolders({ commit }, { id, selected, permissionLevel }) {
    commit("foldersLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/children`, {
        params: {
          type: "FOLDER",
          permissionLevel,
        },
      })
      .then(({ data }) => {
        const items = data?.storages.map((item) => {
          const isSelected = selected.find((x) => x === item.id);
          return {
            ...item,
            isDisabled: isSelected ? true : false,
          };
        });

        commit("setFolders", items);
        commit("foldersLoading", false);
      })
      .catch((error) => {
        commit("foldersLoading", false);
        throw new Error(error);
      });
  },
  async getPath({ commit }, id) {
    commit("pathLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/path`, { headers: { trackId: pathTrackId } })
      .then(({ data }) => {
        const { path, prependPath, truncatedPath } = normalizePathData(data?.path || []);
        const currentFolder = path[path.length - 1];

        commit("setPrependPath", prependPath);
        commit("setTruncatedStoragePath", truncatedPath);
        commit("setPath", path);
        commit("setCurrentFolder", currentFolder);
        commit("pathLoading", false);
      })
      .catch((error) => {
        commit("pathLoading", false);
        throw new Error(error);
      });
  },
  async moveStorages({ commit }, { storages, folder, permissionLevel }) {
    commit("moving", true);
    return axios
      .put(`${apiUrl}storage/move`, {
        ids: storages,
        parentId: folder,
        permissionLevel,
      })
      .then(({ data }) => {
        commit("moving", false);
        return data;
      })
      .catch((error) => {
        commit("moving", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setFolders(state, folders) {
    state.items = folders;
  },
  resetPaths(state) {
    state.path = [];
    state.truncatedPath = [];
    state.prependPath = [];
  },
  setPath(state, path) {
    state.path = path;
  },
  setTruncatedStoragePath(state, path) {
    state.truncatedPath = path;
  },
  setPrependPath(state, path) {
    state.prependPath = path;
  },
  setCurrentFolder(state, folder) {
    state.currentFolder = folder;
  },
  foldersLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  moving(state, isMoving) {
    state.isMoving = isMoving;
  },
  pathLoading(state, isLoading) {
    state.isPathLoading = isLoading;
  },
};

export const storageMove = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
