<template>
  <div class="p-relative d-flex align-center">
    <v-checkbox
      hide-details
      v-model="localValue"
      class="mt-0 pt-0 primary-text--text"
      :label="$t('common.checkToKeepOpen')"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
