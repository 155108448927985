import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  templates: [],
  isLoading: false,
  isCreating: false,
  filter: null,
});

const getters = {};

const actions = {
  async getTemplates({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}template/form`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const templates = data?.templates || [];
        commit("setTemplates", templates);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
  async createTemplate({ state, commit, dispatch }, body) {
    commit("creating", true);
    return axios
      .post(`${apiUrl}template/form`, { ...body })
      .then(({ data }) => {
        dispatch("getTemplates", state.filter);
        return data?.template;
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit("creating", false));
  },
  async updateTemplate({ commit }, { id, body }) {
    return axios
      .put(`${apiUrl}template/form/${id}`, { ...body })
      .then(({ data }) => {
        const template = data?.template;
        if (template) {
          commit("updateTemplate", template);
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  async deleteTemplates({ commit }, { ids }) {
    return axios
      .delete(`${apiUrl}template/form`, { data: { ids } })
      .then(({ data }) => {
        const templates = data?.templates;
        if (Array.isArray(templates)) {
          for (let template of templates) {
            commit("removeTemplate", template);
          }
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  resetState({ commit }) {
    commit("setTemplates", []);
    commit("setFilter", null);
  },
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  updateTemplate(state, template) {
    if (!template) throw new Error("No template");
    const index = state.templates.findIndex(x => x.id === template.id);
    if (index !== -1) {
      if (state.filter?.projectId) {
        if (state.filter?.projectId !== template.projectId) {
          state.templates.splice(index, 1);
          return;
        }
      }
      state.templates.splice(index, 1, template);
    }
  },
  removeTemplate(state, template) {
    if (!template) throw new Error("No template");
    const index = state.templates.findIndex(x => x.id === template.id);
    if (index !== -1) {
      state.templates.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const formTemplates = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
