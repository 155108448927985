<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { filtersItemsMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
export default {
  mixins: [filtersItemsMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  methods: {
    closeFilters() {
      this.localShowDrawer = false;
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasUserImportIntegrationEnabled: "hasUserImportIntegrationEnabled",
    }),
    children() {
      const children = [
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.userFilters.status",
          filterValueKey: "status",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_userStatusItems,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.userFilters.role",
          filterValueKey: "roles",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_userRolesItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.userFilters.invitedDate",
          filterValueKey: "invitedDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.userFilters.statusChangedDate",
          filterValueKey: "changedDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItems,
        },
        ...(this.hasUserImportIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.userFilters.isSynchronized",
                filterValueKey: "isSynchronized",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
      ];
      return children;
    },
  },
};
</script>
