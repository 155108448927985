<template>
  <AppDefaultAutocomplete
    ref="autocomplete"
    v-model="localValue"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    item-value="orgnr"
    item-text="name"
    return-object
    :dense="dense"
    :autofocus="autofocus"
    :disabled="disabled"
    :backgroundColor="backgroundColor"
    :no-data-text="$t('integration.brreg.lookup.noData')"
    :placeholder="getPlaceholder"
    :rules="rules"
    :clearable="clearable"
    :hideDetails="hideDetails"
    :menuProps="{ maxHeight: 350 }"
  >
    <template v-slot:prepend-item>
      <v-list-item>
        <div class="pb-2 primary--text">
          {{ $t("integration.brreg.lookup.resultsFromBrreg") }}
        </div>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:item="{ item: organization, on, attrs }">
      <AppOrganizationListItem
        :organization="organization"
        :isSelected="isSelected(organization)"
        v-bind="attrs"
        v-on="on"
      />
    </template>
  </AppDefaultAutocomplete>
</template>

<script>
import { getOrganizations } from "../../../services/integration/brreg/BronnoysundRegisterService";

export default {
  props: {
    value: [Object],
    rules: Array,
    autofocus: Boolean,
    dense: Boolean,
    disabled: Boolean,
    placeholder: String,
    hideLabel: Boolean,
    hideDetails: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    backgroundColor: { default: "input-background", type: String },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      search: null,
      items: [],
      isLoading: false,
      searchDebounce: null,
      hasLoadedOrganizationsInitially: false,
    };
  },
  computed: {
    getPlaceholder() {
      if (this.hideLabel) return "";
      return !!this.placeholder
        ? this.placeholder
        : this.$t("integration.brreg.lookup.placeholder");
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  watch: {
    search: {
      handler(search) {
        if (!search) {
          clearTimeout(this.searchDebounce);
          this.items = [];
          return;
        }
        this.debounceSearch(search);
      },
    },
    value: {
      handler(value) {
        if (value) {
          if (!this.hasLoadedOrganizationsInitially) {
            this.getOrganizations(value?.name);
            this.hasLoadedOrganizationsInitially = true;
          }
        }
      },
    },
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    isSelected(item) {
      return item?.orgnr === this.value?.orgnr;
    },
    getOrganizations(search) {
      this.isLoading = true;
      getOrganizations(search)
        .then((organizations) => {
          this.items = organizations;
        })
        .finally(() => (this.isLoading = false));
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.getOrganizations(event);
      }, 600);
    },
  },
};
</script>
