import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getStreets(q) {
  return axios
    .get(`${apiUrl}boligmappa/street`, { params: { q } })
    .then(async ({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getAddresses(streetId) {
  return axios
    .get(`${apiUrl}boligmappa/street/${streetId}/address`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getProperties(addressId, cursor) {
  return axios
    .get(`${apiUrl}boligmappa/address/${addressId}/property`, {
      params: {
        ...(cursor && { cursor }),
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function createPlant(boligmappaNumber, projectId) {
  return axios
    .post(`${apiUrl}boligmappa/plant`, { boligmappaNumber, projectId })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
