<template>
  <div :class="`d-flex align-center`" style="min-height: 36px">
    <div :class="`font-weight-bold text-body-2`" :data-id="item.id" ref="questionName">
      <div class="d-flex flex-column">
        <div style="font-size: 0.875rem">
          {{ item.number + " " }}
        </div>
        <div
          :class="`${item?.isRequired ? 'required-asterisk' : ''} ${
            item.isNotRelevant ? 'text-decoration-line-through subtitle--text' : ''
          }`"
          style="font-size: 1rem"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <v-spacer />
    <div v-if="item.isNotRelevant">
      <AppRectangleBadge
        :background-color="$vuetify.theme.themes.light['input-background']"
        :text="$t('formManagement.completion.notRelevant')"
      />
    </div>
    <div v-else-if="showBadge">
      <AppRectangleBadge
        :background-color="item?.defaultValueTemplate?.labelColor"
        :text="item?.defaultValueTemplate?.labelName"
      />
    </div>
    <slot name="append-inner"></slot>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    hasExplanation: Boolean,
  },
  computed: {
    showBadge() {
      const hideBadge = this.item.hideDefaultValueBadge;
      const hasDefaultValueId = !!this.item.defaultValueTemplateId;

      return !hideBadge && hasDefaultValueId;
    },
  },
  mounted() {
    this.$emit("questionName:mounted", this.$refs.questionName);
  },
};
</script>
