<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {};
  },
  computed: {
    values() {
      return this.item?.values || [];
    },
  },
  methods: {
    genChoice({ value, index }) {
      return this.$createElement("div", { class: "d-flex align-center pt-2 pb-3" }, [
        this.$createElement(
          "div",
          { class: "font-weight-bold d-flex justify-center mr-2", style: "width: 32px;" },
          [this.$createElement("span", index + 1)],
        ),
        this.genChoiceInput({ value, index }),
      ]);
    },
    genChoiceInput({ value, index }) {
      return this.$createElement("div", value);
    },
    onkeydownPress(e) {
      if (e.key === "Enter") {
        const value = e.target.value;
        if (!value || value?.trim() === "") return;
        this.addChoice();
      }
    },
    genChoices() {
      return this.values.map((value, index) => {
        return this.genChoice({ value, index });
      });
    },
  },
  render(h) {
    return h("div", { class: "w-100 pt-2 pb-1 border-b" }, [this.genChoices()]);
  },
};
</script>
