<template>
  <div
    class="color-chip"
    :class="[color, clickable ? 'cursor-pointer' : '', classes]"
    @click="(e) => $emit('click', e)"
  >
    <v-icon v-if="isIcon" :color="iconColor" small>
      {{ text }}
    </v-icon>
    <span v-else :class="slotClass ?? 'color-chip__content'">
      {{ text }}
      <slot></slot>
    </span>
    <slot name="append-item"></slot>
  </div>
</template>

<script>
export default {
  props: {
    isIcon: Boolean,
    iconColor: String,
    text: String,
    color: String,
    clickable: Boolean,
    large: Boolean,
    slotClass: String,
  },
  computed: {
    classes() {
      return {
        "color-chip--large": this.large,
      };
    },
  },
};
</script>
