import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let previewCancel;
let storageCancel;

const state = () => ({
  preview: null,
  storage: null,
  filesInFolder: null,
  isPreviewLoading: false,
  isPreviewRefreshing: false,
  isStorageLoading: false,
  isFilesLoading: false,
  isValid: false,
});

const getters = {
  isLoading: (state) => {
    return state.isPreviewLoading || state.isStorageLoading;
  },
};

const actions = {
  async getPreview({ commit }, { id, load = false, refresh = false, version = null }) {
    if (load) {
      commit("loadingPreview", true);
    }
    if (refresh) {
      commit("refreshingPreview", true);
    }
    commit("valid", false);
    if (previewCancel) previewCancel();
    return axios
      .get(`${apiUrl}storage/file/${id}/content`, {
        params: {
          version,
        },
        responseType: "blob",
        cancelToken: new CancelToken(function executor(c) {
          previewCancel = c;
        }),
      })
      .then((res) => {
        if (
          res?.data?.type.match("application.pdf") ||
          res?.data?.type.match("image/*")
        ) {
          const blobUrl = URL.createObjectURL(res.data);
          commit("setPreview", {
            id,
            content: blobUrl,
            contentType: res.data.type,
          });
          commit("loadingPreview", false);
          commit("valid", true);
        } else {
          commit("valid", false);
          commit("loadingPreview", false);
          commit("setPreview", { id });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loadingPreview", false);
          commit("valid", false);
          commit("setPreview", { id });
          throw new Error(error);
        }
      })
      .finally(() => {
        commit("refreshingPreview", false);
      });
  },
  async getStorage({ commit }, { id, load = true }) {
    if (load) {
      commit("storageLoading", true);
    }

    if (storageCancel) storageCancel();
    return axios
      .get(`${apiUrl}storage/file/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          storageCancel = c;
        }),
      })
      .then(async ({ data }) => {
        const storage = data.storage;
        commit("setStorage", storage);
        commit("storageLoading", false);
        return storage;
      })
      .catch((error) => {
        commit("storageLoading", false);
        if (!axios.isCancel(error)) {
          throw new Error(error);
        }
      });
  },
  async restoreFileVersion({ commit }, { id, versionId }) {
    return axios
      .put(`${apiUrl}storage/file/${id}/restore`, { versionId })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async checkAndUpdatePreview({ commit, dispatch, state }, { id }) {
    try {
      if (storageCancel) storageCancel();

      const { data } = await axios.get(`${apiUrl}storage/file/${id}`, {
        cancelToken: new CancelToken(function executor(c) {
          storageCancel = c;
        }),
      });
      const storage = data?.storage;
      const updatedAt = storage?.updatedAt;
      commit("setStorage", storage);
      if (state.storage && state.storage.updatedAt !== updatedAt) {
        dispatch("getPreview", { id });
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
    }
  },
  async getFilesInFolder({ commit }, folderId) {
    commit("loadingFiles", true);
    return axios
      .get(`${apiUrl}storage/folder/${folderId}/children`, {
        params: {
          type: "FILE",
        },
      })
      .then(async ({ data }) => {
        const { storages } = data;
        commit("setFiles", storages);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loadingFiles", false);
      });
  },
};

const mutations = {
  setPreview(state, preview) {
    state.preview = preview;
  },
  setStorage(state, storage) {
    state.storage = storage;
  },
  setFiles(state, filesInFolder) {
    state.filesInFolder = filesInFolder;
  },
  loadingFiles(state, isLoading) {
    state.isFilesLoading = isLoading;
  },
  loadingPreview(state, isLoading) {
    state.isPreviewLoading = isLoading;
  },
  refreshingPreview(state, isRefreshing) {
    state.isPreviewRefreshing = isRefreshing;
  },
  storageLoading(state, isLoading) {
    state.isStorageLoading = isLoading;
  },
  valid(state, isValid) {
    state.isValid = isValid;
  },
};

export const storagePreview = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
