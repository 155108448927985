<template>
  <v-dialog v-model="localDialog" maxWidth="520" scrollable>
    <v-card class="d-flex flex-column align-center pa-10">
      <div class="dialog__close-btn">
        <v-btn icon dark @click="close">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <div class="text-h5 font-weight-bold text-center mb-4">
        {{ $t("common.downloadApp") }}
      </div>
      <v-btn color="primary" depressed @click="goToAppStore">
        {{ $t("common.getTheApp") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import { getMobileOperatingSystem } from "@/helpers/util";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    os() {
      return getMobileOperatingSystem();
    },
  },
  methods: {
    goToAppStore() {
      if (this.os === "IOS") {
        window.location.href = "https://apps.apple.com/no/app/gripr/id1632240792";
      } else if (this.os === "ANDROID") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.gripr.app";
      }
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>
