<template>
  <AppMenuItem
    :text="$t('settings.workspaces.acceptInvitation')"
    :icon="$icon.REGULAR.ACTION.ACCEPT"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
