<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    getAnswerElm() {
      const answer = this.item.value;
      if (!answer)
        return this.$createElement(
          "div",
          { class: "error--text" },
          this.$t("formManagement.preview.noAnswer"),
        );
      return this.$createElement(
        "div",
        { style: { whiteSpace: "pre-wrap" } }, // Add this line
        answer,
      );
    },
  },
  render(h) {
    return this.getAnswerElm();
  },
};
</script>
