export function normalizeTimeEntry(timeEntry) {
  if (!timeEntry) return;
  return {
    ...timeEntry,
    isUpdatingStatus: false,
  };
}

export function normalizeTimeEntries(timeEntries) {
  return (timeEntries || []).map((te) => normalizeTimeEntry(te));
}
