<template>
  <div>
    <v-form
      ref="radioForm"
      :value="value.isValid"
      @submit="(e) => e.preventDefault()"
      @input="(e) => (value.isValid = e)"
    >
      <v-btn-toggle
        @change="onChange"
        class="d-flex flex-row"
        :value="item.value"
        :rules="rules"
        style="background-color: transparent"
      >
        <v-row>
          <v-col cols="4" v-for="(value, index) in item.values" :key="index">
            <v-btn
              class="border-a flex-grow-1 w-100"
              :active-class="getColor(value)"
              depressed
              :value="value"
              >{{ getLabel(value) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-btn-toggle>
    </v-form>
  </div>
</template>

<script>
import { formTemplateBuilderMixins } from "@/helpers/mixins";
export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
  },
  data() {
    return {
      value: {
        isValid: false,
      },
    };
  },
  computed: {
    rules() {
      if (!this.item?.isRequired) return [];
      return this.$rules.REQUIRED;
    },
  },
  methods: {
    getColor(value) {
      if (value === "OK" || value === "YES") {
        return "success";
      } else if (value === "NO" || value === "DEVIATION") {
        return "error";
      }
    },
    onChange(e) {
      const newVal = typeof e === "object" && e !== null ? e?.target?.value : e;
      const oldVal = this.item.value;

      const isNewValue = newVal !== oldVal;

      if (!isNewValue) return;
      this.formTemplateBuilderMixins_updateItem({
        item: this.item,
        body: { value: newVal ?? null },
      });
    },
    getLabel(value) {
      const label = this.$te(`templateManagement.form.builder.item.values.${value}`)
        ? this.$t(`templateManagement.form.builder.item.values.${value}`)
        : value;
      return label;
    },
    validateForm() {
      this.$refs.radioForm.validate();
    },
  },
};
</script>
