import { MODULE_FIELD_CONFIG } from "@/helpers/constants";
import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getSearchTasks({ params } = {}) {
  return axios
    .get(`${apiUrl}search/task`, {
      params: {
        fields: MODULE_FIELD_CONFIG.TASK.FIELDS_QUERY_PARAM,
        ...params,
      },
    })
    .then(({ data }) => {
      const normalizedTask = data?.tasks.map((task) => {
        const member = task?.members[0];
        return { ...task, member };
      });
      const tasks = formatItemWithSearchStr(normalizedTask, "TASK");
      return tasks;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
