<template>
  <v-btn
    depressed
    :class="`text-none text-subtitle-2 justify-start ${disabledClass} ${backgroundColorIfSelected} w-100`"
    :color="buttonColor"
    v-on="$listeners"
  >
    <div class="d-flex align-center w-100">
      <i v-if="emoji" :class="['mr-2', 'em', emoji]"></i>
      <template v-if="icon">
        <v-icon
          style="width: 20px !important; margin-right: 17.7777777778px"
          class="mr-4 w-100"
          small
          :color="iconColorComputed"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span :class="`${color}--text${disabledText}`">{{ text }}</span>
      <v-spacer />
      <v-icon class="ml-2" small :color="iconColorComputed">
        {{ angleIcon }}
      </v-icon>
    </div>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
    buttonColor: String,
    icon: String,
    color: String,
    iconColor: String,
    disabled: Boolean,
    isSelected: Boolean,
    showStar: Boolean,
    planCode: String,
    emoji: String,
    angleDown: Boolean,
  },
  computed: {
    iconColorComputed() {
      return this.disabled ? "" : this.iconColor ?? this.color;
    },
    disabledText() {
      return this.disabled ? "--disabled" : "";
    },
    backgroundColorIfSelected() {
      return this.isSelected ? "action-button" : "";
    },
    disabledClass() {
      return this.disabled ? "menu-item-disabled" : "";
    },
    angleIcon() {
      return this.angleDown
        ? this.$icon.REGULAR.COMMON.ANGLE_DOWN
        : this.$icon.REGULAR.COMMON.ANGLE_RIGHT;
    },
  },
};
</script>
