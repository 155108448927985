<template>
  <AppWidgetLibraryItemTemplate
    :title="$t('widgetLibrary.autogearVehicle.title')"
    :subTitle="$t('widgetLibrary.autogearVehicle.subTitle')"
    :planCode="planCode"
    :showStar="showStar"
    icon="$autogear"
    is-logo
    @add="onAdd"
  />
</template>

<script>
export default {
  props: {
    planCode: String,
    showStar: Boolean,
  },
  methods: {
    onAdd() {
      this.$emit("add");
    },
  },
};
</script>
