import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import FileSaver from "file-saver";

export async function downloadProductErrorReport({ reportUrl }) {
  return axios
    .get(`${apiUrl}product-import/report`, {
      params: { path: reportUrl },
      responseType: "blob",
    })
    .then((res) => {
      let fileName = "";
      const contentDisposition = res.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      FileSaver.saveAs(res.data, fileName);
    })
    .catch((error) => {
      throw new Error(error);
    });
}
