import { normalizeStorageSearch } from "@/helpers/normalization";
import axios from "axios";
import FileSaver from "file-saver";
import _ from "lodash";

const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let searchCancel;

const state = () => ({
  items: [],
  isLoading: false,
  isDownloading: false,
});

const getters = {
  selectedItems: (state) => {
    return state.items.filter((i) => i.isSelected === true);
  },
  isAllSelected: (state, getters) => {
    return state.items.length > 0
      ? getters.selectedItems.length === state.items.length
      : false;
  },
  isIndeterminate: (state, getters) => {
    return getters.selectedItems.length > 0 &&
      getters.selectedItems.length !== state.items.length
      ? true
      : false;
  },
  folderSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.type === "FOLDER") ? true : false;
  },
  relationFileSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.type === "FILE_RELATION") ? true : false;
  },
  lockedStorageSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.isLocked) ? true : false;
  },
  selectedPermissionLevels: (state, getters) => {
    const permissionLevelCount = _.countBy(
      getters.selectedItems.map((x) => x.permissionLevel),
    );
    let count = 0;
    for (const [key, value] of Object.entries(permissionLevelCount)) {
      if (value > 0) {
        count++;
      }
    }

    const mostSelected = Object.keys(permissionLevelCount).reduce((a, b) =>
      permissionLevelCount[a] > permissionLevelCount[b] ? a : b,
    );

    return {
      hasSelectedMultiple: count > 1 ? true : false,
      mostSelected,
    };
  },
};

const actions = {
  async search({ commit, getters }, { search, keepSelected = false, rootId }) {
    const itemsToKeep = [...getters.selectedItems];
    if (!search) search = "";
    commit("searchLoading", true);
    if (searchCancel) searchCancel();
    return axios
      .get(`${apiUrl}storage`, {
        params: {
          search,
          rootId,
        },
        cancelToken: new CancelToken(function executor(c) {
          searchCancel = c;
        }),
      })
      .then(({ data }) => {
        const storages = data?.storages;
        const normalizedStoragesSearch = normalizeStorageSearch(
          storages,
          itemsToKeep,
          keepSelected,
        );
        commit("setSearch", normalizedStoragesSearch);
        commit("searchLoading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("searchLoading", false);
          throw new Error(error);
        }
      });
  },
  async deleteStorageItems({ commit, dispatch }, { items, search }) {
    for (let item of items) {
      commit("toggleStorageLoading", { storage: item, isLoading: true });
    }
    const ids = items.map((i) => i.id);
    return axios
      .delete(`${apiUrl}storage`, { data: { ids } })
      .then(({ data }) => {
        dispatch("search", { search, keepSelected: false });
      })
      .catch((error) => {
        for (let item of items) {
          commit("toggleStorageLoading", { storage: item, isLoading: false });
        }
        throw new Error(error);
      });
  },
  async download({ commit }, ids) {
    commit("downloading", true);
    return axios
      .get(`${apiUrl}storage/download`, {
        params: { ids },
        responseType: "blob",
      })
      .then((res) => {
        commit("downloading", false);
        let fileName = "";
        const contentDisposition = res.headers["content-disposition"];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("downloading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setSearch(state, items) {
    state.items = items;
  },
  searchLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  downloading(state, isDownloading) {
    state.isDownloading = isDownloading;
  },
  toggleStorageLoading(state, payload = { storage: null, isLoading: false }) {
    if (!payload.storage) throw new Error("No folder");
    const index = state.items.findIndex((x) => x.id === payload.storage.id);
    if (index !== -1) {
      state.items[index].isLoading = payload.isLoading;
    }
  },
  toggleSelectedStorage(state, { items, isSelected }) {
    for (const item of items) {
      const index = state.items.findIndex((x) => x.id == item.id);

      if (index !== -1) {
        state.items[index].isSelected = isSelected;
      }
    }
  },
};

export const storageSearch = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
