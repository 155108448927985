import { base64ToBlobObjectUrl } from "@/helpers/util";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  storage: null,
  isLoading: false,
  isValid: false,
});

const getters = {};

const actions = {
  async getData({ commit }, { hash }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}action-request-recipient/in-progress`, { params: { hash } })
      .then(async ({ data }) => {
        const storage = data;
        if (
          storage.contentType.match("application.pdf") ||
          storage.contentType.match("image/*")
        ) {
          storage.content = await base64ToBlobObjectUrl(
            storage.content,
            storage.contentType,
          );
          commit("setIsValid", true);
        } else {
          commit("setIsValid", false);
        }

        commit("setStorage", storage);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setStorage(state, storage) {
    state.storage = storage;
  },
  setIsValid(state, isValid) {
    state.isValid = isValid;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const storageReadConfirmationInProgress = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
