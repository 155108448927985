<template>
  <div class="d-flex flex-column mw-650px align-center">
    <v-img
      contain
      max-height="200"
      src="../../../../assets/importing.png"
      alt="Importing"
      class="pb-10"
    />
    <AppCircularLoader class="h-100 w-100 mt-10" page />
  </div>
</template>

<script>
export default {
  props: {
    state: String,
    importType: String,
  },
};
</script>
