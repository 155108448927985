export const PROJECT_PRODUCTS = "PROJECT_PRODUCTS";
export const PROJECT_EBYGGESOK = "PROJECT_EBYGGESOK";
export const SETTINGS_PRODUCTS = "SETTINGS_PRODUCTS";
export const PROJECT_FIND_PRODUCTS = "PROJECT_FIND_PRODUCTS";
export const CLIENTS = "CLIENTS";
export const CONTACTS = "CONTACTS";
export const FORMS = "FORMS";
export const PROJECT_FORMS = "PROJECT_FORMS";
export const TASKS = "TASKS";
export const PROJECT_TASKS = "PROJECT_TASKS";
export const PROJECTS = "PROJECTS";
export const PROJECT_SUB_PROJECTS = "PROJECT_SUB_PROJECTS";
export const PROJECT_CONTACTS = "PROJECT_CONTACTS";
export const PROJECT_CLIENTS = "PROJECT_CLIENTS";
export const USERS = "USERS";
export const TIME_ENTRIES = "TIME_ENTRIES";
