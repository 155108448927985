import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
const CancelToken = axios.CancelToken;
let getGpsAndTagsCancel;

export async function getAutogearGpsAndTags() {
  if (getGpsAndTagsCancel) getGpsAndTagsCancel();
  return axios
    .get(`${apiUrl}autogear/gps-and-tags`, {
      cancelToken: new CancelToken(function executor(c) {
        getGpsAndTagsCancel = c;
      }),
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        throw new Error(error);
      }
      return { isCanceled: true };
    });
}
