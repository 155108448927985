import { normalizeBrregOrganisations } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getOrganizations(search) {
  return axios
    .get(`${apiUrl}bronnoysund/organization`, { params: { search } })
    .then(({ data }) => {
      const units = data?.units;
      return normalizeBrregOrganisations(units);
    })
    .catch(error => {
      throw new Error(error);
    });
}
