<template>
  <v-app>
    <AppDrawer />
    <v-main>
      <router-view />
    </v-main>

    <v-overlay :value="isLoadingPage" :opacity="0.8" color="background">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </v-overlay>

    <!-- should make these render conditionally since they are triggered so rarely and dont have to be in the background at all times -->
    <AppSnackbar />
    <AppUpgradePlanDialog />
    <AppDownloadApp v-model="dialog.downloadApp.isActive" />
    <AppTrialExpiredDialog
      v-if="isDomainOwner && triggerTrialExpiredDialog && !isDomainSupportUser"
      :dialog="triggerTrialExpiredDialog"
    />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { setSleekUser } from "./helpers/util/sleekplan";
import moment from "moment";
import { isMobile } from "./helpers/util";

export default {
  data() {
    return {
      userUpdateInterval: null,
      hasTrackedStonely: false,
      hovering: false,
      dialog: {
        downloadApp: {
          hasShown: false,
          isActive: false,
        },
      },
      isMobile: isMobile(),
    };
  },
  name: "App",
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
      isLoadingUserInitially: (state) => state.isLoadingUserInitially,
    }),
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainSupportUser: "isDomainSupportUser",
    }),
    isOnRoot() {
      return this.$route.fullPath === process.env.BASE_URL;
    },
    isLoadingPage() {
      const isLoading = this.$auth.loading || this.isLoadingUserInitially;
      return isLoading || this.isOnRoot;
    },
    triggerTrialExpiredDialog() {
      if (!this.user) return false;
      const subscriptionPlan = this.user?.currentDomain?.subscriptionPlan;
      return (
        subscriptionPlan.remainingSubscriptionDays < 0 &&
        subscriptionPlan.planCode === this.$constant.PLAN_CODE.GRIPR_TRIAL
      );
    },
    shouldShowDownloadAppDialog() {
      const isOnRoute = (route) => {
        return this.$route.name === route;
      };
      const isOnGetStartedFlowOrRoot =
        isOnRoute(this.$routeNames.GET_STARTED.NEW_WORKSPACE) ||
        isOnRoute(this.$routeNames.GET_STARTED.LANDING) ||
        this.isOnRoot;

      const hasShown = this.dialog.downloadApp.hasShown;
      const shouldShow =
        this.isMobile && !!this.user?.id && !hasShown && !isOnGetStartedFlowOrRoot;

      return shouldShow;
    },
  },
  watch: {
    user: {
      handler(user) {
        this.onUserChange(user);
      },
      immediate: true,
      deep: true,
    },
    shouldShowDownloadAppDialog: {
      handler(shouldShow) {
        if (shouldShow) {
          this.showDownloadAppDialog();
        }
      },
      immediate: true,
    },
  },
  methods: {
    afterAuth() {
      const fn = async () => {
        if (this.$auth.isAuthenticated) {
          //after
        }
      };

      if (!this.$auth.loading) {
        return fn();
      }

      this.$auth.$watch("loading", (loading) => {
        if (loading === false) {
          return fn();
        }
      });
    },
    onUserChange(user) {
      clearInterval(this.userUpdateInterval);
      if (user) {
        this.getActiveIntegrations(user);
        this.setLanguage(user);
        this.setUpPeriodicUserFetching();
        this.configureRollbar(user);
        setSleekUser(user);
        this.configureStonelyTracking(user);
      }
    },
    getActiveIntegrations(user) {
      if (user.activeDomain && user.domains.length > 0) {
        this.$store.dispatch("activeIntegrations/getActiveIntegrations");
      }
    },
    showDownloadAppDialog() {
      window.location.href = "gripr://";
      this.dialog.downloadApp.isActive = true;
      this.dialog.downloadApp.hasShown = true;
    },
    setUpPeriodicUserFetching() {
      this.userUpdateInterval = setInterval(() => {
        this.$store.dispatch("auth/getUser");
      }, 60000);
    },
    setLanguage(user) {
      this.$i18n.locale = user.language || "nb";
      moment.locale(user.language || "nb"); // Set moment locale based on user language
      this.$vuetify.lang.current = user.language || "nb";
    },
    configureStonelyTracking(user) {
      if (window.stonlyTrack && !this.hasTrackedStonely) {
        window.stonlyTrack("identify", user.authId, {
          language: user.language || "nb",
          firstname: user.firstName || "",
          lastname: user.lastName || "",
          email: user.email,
        });
        this.hasTrackedStonely = true;
      }
    },
    configureRollbar(user) {
      if (this.$rollbar) {
        this.$rollbar.configure({
          payload: {
            person: {
              id: user.id,
              email: user.email,
              name: user.name,
              activeDomain: user.activeDomain,
            },
          },
        });
      }
    },
  },
  mounted() {
    this.afterAuth();
  },
};
</script>
