import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function sendFormToSign({ body }) {
  return axios
    .post(`${apiUrl}form-signature-session`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function remindFormSign({ body }) {
  return axios
    .post(`${apiUrl}form-signature-session/remind`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function resetFormSignSession({ body }) {
  return axios
    .post(`${apiUrl}form-signature-session/reset`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function revokeFormSignSession({ body }) {
  return axios
    .post(`${apiUrl}form-signature-session/revoke`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function sendCompletionEmail({ body }) {
  return axios
    .post(`${apiUrl}form-signature-session/completion-email`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getSignLink({ params }) {
  return axios
    .get(`${apiUrl}form-signature-session/sign-link`, { params })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
