<template>
  <v-dialog v-model="localDialog" scrollable max-width="800px" min-height="90%">
    <v-card height="90vh">
      <v-card-title>
        <div class="pb-6 d-flex align-center w-100">
          <div class="text-h6 font-weight-bold">
            {{ $t("projectView.products.addProductsDialog.findAndAddDialog.title") }}
          </div>
          <v-spacer />
          <div v-if="!isLoadingStats" class="text-subtitle-2">
            {{
              $tc(
                "projectView.products.addProductsDialog.findAndAddDialog.availableProducts",
                countAll,
              )
            }}
          </div>
        </div>
        <AppDefaultTextField
          :loading="isLoadingProducts || filtersMixins_isLoadingFilters"
          class="mt-1 mr-2"
          :placeholder="
            $t(
              'projectView.products.addProductsDialog.findAndAddDialog.searchPlaceholder',
            )
          "
          :append-icon="$icon.LIGHT.ACTION.SEARCH"
          hide-details
          clearable
          :value="filtersMixins_filterByKey('search')"
          @input="filtersMixins_debounceSearch"
        />
      </v-card-title>
      <v-card-text class="primary-text--text" style="scrollbar-gutter: stable">
        <AppFindProductsItem
          v-for="(product, index) of products"
          :key="index"
          :product="product"
          :isChecked="selectedProducts.some((p) => p?.id === product.id)"
          @toggleChecked="toggleChecked"
        />
        <div class="w-100 d-flex align-center justify-center pt-2" v-if="isMoreItems">
          <AppCircularLoader v-intersect="onIntersect" />
        </div>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="$t('projectView.products.actionBtnText')"
        />
        <v-spacer />
        <div class="font-weight-bold">
          {{
            $tc(
              "projectView.products.addProductsDialog.findAndAddDialog.productsSelected",
              selectedProducts.length,
            )
          }}
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getProductStatistics } from "@/services/product/productStatistics";
import * as moment from "moment";
import { filtersMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersMixins],
  props: {
    dialog: Boolean,
    projectId: String,
  },
  data() {
    return {
      selectedProducts: [],
      isLoadingStats: false,
      countAll: null,
      tableModel: this.$constant.PROJECT_FIND_PRODUCTS,
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    filtersMixins_filters: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetState();
          this.getProducts();
        }
      },
      deep: true,
    },
    dialog: {
      handler(val) {
        if (val) {
          this.filtersMixins_getFilters();
        }
      },
    },
  },
  computed: {
    isMoreItems() {
      return this.count > this.products.length;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("productsInfinityList", {
      products: (state) => state.products,
      isLoadingProducts: (state) => state.isLoading,
      count: (state) => state.count,
    }),
  },
  methods: {
    onIntersect(e) {
      const isIntersecting = e[0].isIntersecting;
      if (isIntersecting || !this.isMoreItems) {
        this.getProducts(true);
      }
    },
    resetSelectedProducts() {
      this.selectedProducts = [];
    },
    toggleChecked({ product }) {
      const isSelected = this.selectedProducts.some((p) => p?.id === product.id);
      if (isSelected) {
        this.selectedProducts = this.selectedProducts.filter((p) => p?.id !== product.id);
      } else {
        this.selectedProducts = [...this.selectedProducts, product];
      }
    },
    addProducts() {
      const newProducts = this.selectedProducts.map((product) => ({
        addedAt: moment(new Date()).format("YYYY-MM-DD"),
        name: null,
        amount: null,
        unitPrice: null,
        comment: null,
        ...product,
      }));
      this.$emit("submit:click", newProducts);
      this.resetSelectedProducts();
    },
    onSubmit() {
      this.close();
      this.addProducts();
    },
    close() {
      this.localDialog = false;
    },
    resetState() {
      this.$store.commit("productsInfinityList/resetState");
    },
    async getProducts(shouldPushItems) {
      await this.$store.dispatch("productsInfinityList/getProducts", {
        filter: this.filtersMixins_filters,
        projectId: this.projectId,
        shouldPushItems,
      });
    },
  },
  mounted() {
    this.isLoadingStats = true;
    getProductStatistics()
      .then((res) => {
        const { products } = res;
        const { active } = products || {};
        this.countAll = active || 0;
      })
      .finally(() => {
        this.isLoadingStats = false;
      });
  },
};
</script>
