<template>
  <AppWidgetShortcutButton
    @click="navigateProject"
    @delete:click="onDelete"
    :templateProps="templateProps"
    :icon="$icon.LIGHT.ROUTE.PROJECT"
    :title="$t('dashboard.shortcutButton.createProjectTitle')"
    :description="$t('dashboard.shortcutButton.createProjectDescription')"
  />
</template>

<script>
export default {
  props: {
    templateProps: Object,
  },
  methods: {
    navigateProject() {
      this.$router.replace({
        name: this.$routeNames.PROJECT.ROOT,
        query: { openCreateProject: true },
      });
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
