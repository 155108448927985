<template>
  <v-data-table
    ref="dataTable"
    class="elevation-0 border-a overflow-hidden"
    fixed-header
    v-bind="$attrs"
    v-on="$listeners"
    :loadingText="$t('common.loading')"
    hide-default-footer
    :itemsPerPage="options.itemsPerPage"
    :page.sync="options.page"
    @page-count="options.pageCount = $event"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="item">
      <slot :name="slot" v-bind="item"></slot>
    </template>
    <template v-slot:footer v-if="!disablePagination">
      <div
        ref="dataTableFooter"
        class="py-1 border-t p-relative d-flex align-center justify-center"
      >
        <div v-if="isStatsLoading || statsText" class="ml-4 p-absolute" style="left: 0">
          <v-skeleton-loader
            class="skeleton-loader-text-custom"
            v-if="isStatsLoading"
            type="text"
            width="150"
            height="16"
          />
          <div v-else v-html="statsText"></div>
        </div>

        <v-pagination
          class="pagination-items-remove-box-shadow justify-center"
          v-model="options.page"
          :length="options.pageCount"
          total-visible="7"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    maxHeight: String,
    disablePagination: Boolean,
    statsText: String,
    isStatsLoading: Boolean,
  },
  data() {
    return {
      options: {
        itemsPerPage: this.disablePagination ? -1 : 20,
        pageCount: 0,
        page: 1,
      },
    };
  },
  methods: {
    calcDataTableMaxHeight() {
      if (this.height) return;
      const pixelsFromTopOfPageToTable =
        this.$refs.dataTable.$el.getBoundingClientRect().top;
      const tableFooterHeight = this.$refs.dataTableFooter
        ? this.$refs.dataTableFooter.offsetHeight
        : 0;

      const extraPixelsToAccountFor = 30;
      const heightToRemove =
        pixelsFromTopOfPageToTable + tableFooterHeight + extraPixelsToAccountFor;

      const maxHeightStyle = `calc(100vh - ${heightToRemove}px)`;
      return maxHeightStyle;
    },
    updateDataTableHeight() {
      //This is the wrapper we have to set the max-height on
      const dataTableWrapper = this.$refs.dataTable.$el.querySelector(
        ".v-data-table__wrapper",
      );

      const dataTableMaxHeight = this.maxHeight
        ? `${this.maxHeight}px`
        : this.calcDataTableMaxHeight();

      dataTableWrapper.style.maxHeight = dataTableMaxHeight;
    },
  },
  mounted() {
    this.updateDataTableHeight();
  },
  updated() {
    this.updateDataTableHeight();
  },
};
</script>

<style>
.pagination-items-remove-box-shadow > .v-pagination > li > .v-pagination__item,
.v-pagination__navigation {
  box-shadow: none;
}
</style>
