import { LABEL_TYPE } from "@/helpers/constants";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  labels: {
    [LABEL_TYPE.JOB_TITLE]: [],
    [LABEL_TYPE.RESPONSIBILITY]: [],
  },
  isLoading: {
    [LABEL_TYPE.JOB_TITLE]: false,
    [LABEL_TYPE.RESPONSIBILITY]: false,
  },
});

const getters = {
  labelsByType: (state) => (type) => state.labels[type] || [],
  isLoadingByType: (state) => (type) => !!state.isLoading[type],
};

const actions = {
  async getLabels({ commit }, { type }) {
    commit("loading", { type, isLoading: true });
    return axios
      .get(`${apiUrl}label`, { params: { type } })
      .then(({ data }) => {
        const { labels } = data;
        commit("setLabels", { type, labels });
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", { type, isLoading: false });
      });
  },
  async createLabel({ commit }, { body }) {
    return axios
      .post(`${apiUrl}label`, body, { params: { type: body.type } })
      .then(({ data }) => {
        const { label } = data;
        commit("createLabel", { label });
        return label;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateLabel({ commit }, { id, body }) {
    return axios
      .put(`${apiUrl}label/${id}`, body)
      .then(({ data }) => {
        const { label } = data;
        commit("updateLabel", { label });
        return label;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteLabels({ commit }, { ids }) {
    return axios
      .delete(`${apiUrl}label`, { data: { ids } })
      .then(({ data }) => {
        const { labels } = data;
        for (const label of labels) {
          const { type, id } = label;
          commit("deleteLabel", { type, id });
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setLabels(state, { type, labels }) {
    state.labels = { ...state.labels, [type]: labels };
  },
  createLabel(state, { label }) {
    const { type } = label;
    const existingLabels = state.labels[type] || [];
    state.labels = { ...state.labels, [type]: [...existingLabels, label] };
  },
  updateLabel(state, { label }) {
    const { type } = label;
    if (state.labels[type]) {
      const updatedLabels = state.labels[type].map((l) =>
        l.id === label.id ? label : l,
      );
      state.labels = { ...state.labels, [type]: updatedLabels };
    }
  },
  deleteLabel(state, { type, id }) {
    if (state.labels[type]) {
      const updatedLabels = state.labels[type].filter((l) => l.id !== id);
      state.labels = { ...state.labels, [type]: updatedLabels };
    }
  },
  loading(state, { type, isLoading }) {
    state.isLoading = { ...state.isLoading, [type]: isLoading };
  },
};

export const label = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

//unfortunatly Vue 2 doesnt have support for data types like Map,
//so we have to use object instead. When we have implemented Vue 3 we can test out this code, dont remove it.
//https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types

// import axios from "axios";
// const apiUrl = process.env.VUE_APP_API_URL;

// const state = () => ({
//   labels: new Map([
//     ["JOB_TITLE", []],
//     ["RESPONSIBILITY", []],
//   ]),
//   isLoading: new Map([
//     ["JOB_TITLE", false],
//     ["RESPONSIBILITY", false],
//   ]),
// });

// const getters = {
//   labelsByType: (state) => (type) => state.labels.get(type),
//   isLoadingByType: (state) => (type) => state.isLoading.get(type),
// };

// const actions = {
//   async getLabels({ commit }, { type }) {
//     commit("loading", { type, isLoading: true });
//     return axios
//       .get(`${apiUrl}label`, { params: { type } })
//       .then(({ data }) => {
//         const { labels } = data;
//         commit("setLabels", { type, labels });
//       })
//       .catch((error) => {
//         throw new Error(error);
//       })
//       .finally(() => {
//         commit("loading", { type, isLoading: false });
//       });
//   },
//   async createLabel({ commit }, { type, body }) {
//     return axios
//       .post(`${apiUrl}label`, body, { params: { type: body.type } })
//       .then(({ data }) => {
//         const { label } = data;
//         commit("createLabel", { type, label });
//       })
//       .catch((error) => {
//         throw new Error(error);
//       })
//       .finally(() => {});
//   },
//   async updateLabel({ state, commit }, { type, id, body }) {
//     const oldLabel = state.labels.get(type).find((l) => l.id === id);
//     commit("updateLabel", { type, label: { ...oldLabel, ...body } });

//     return axios
//       .put(`${apiUrl}label/${id}`, body)
//       .then(({ data }) => {
//         const { label } = data;
//         commit("updateLabel", { type, label });
//       })
//       .catch((error) => {
//         commit("updateLabel", { type, label: oldLabel });
//         throw new Error(error);
//       })
//       .finally(() => {});
//   },
//   async deleteLabels({ commit }, { ids }) {
//     return axios
//       .delete(`${apiUrl}label`, { data: { ids } })
//       .then(({ data }) => {
//         const { labels } = data;
//         for (const label of labels) {
//           const { type, id } = label;
//           commit("deleteLabel", { type, id });
//         }
//       })
//       .catch((error) => {
//         throw new Error(error);
//       })
//       .finally(() => {
//         commit("deleting", false);
//       });
//   },
// };

// const mutations = {
//   setLabels(state, { type, labels }) {
//     state.labels.set(type, labels);
//   },
//   createLabel(state, { type, label }) {
//     if (state.labels.has(type)) {
//       // Clone, push, and set to maintain reactivity and avoid direct mutation
//       const labels = [...state.labels.get(type), label];
//       state.labels.set(type, labels);
//     } else {
//       // Type does not exist, so create it with the new label
//       state.labels.set(type, [label]);
//     }
//   },
//   updateLabel(state, { type, label }) {
//     if (state.labels.has(type)) {
//       const labels = state.labels.get(type);
//       const index = labels.findIndex((l) => l.id === label.id);
//       if (index !== -1) {
//         // Clone array and update the label to maintain reactivity
//         const updatedLabels = [...labels];
//         updatedLabels[index] = label;
//         state.labels.set(type, updatedLabels);
//       }
//     }
//   },
//   deleteLabel(state, { type, labelId }) {
//     if (state.labels.has(type)) {
//       const labels = state.labels.get(type);
//       const updatedLabels = labels.filter((l) => l.id !== labelId);
//       state.labels.set(type, updatedLabels);
//     }
//   },
//   loading(state, { type, isLoading }) {
//     state.isLoading.set(type, isLoading);
//   },
// };

// export const label = {
//   namespaced: true,
//   state,
//   getters,
//   actions,
//   mutations,
// };
