<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-btn icon v-if="canClose" @click="close">
        <v-icon>{{ $icon.LIGHT.COMMON.ARROW_LEFT }}</v-icon>
      </v-btn>
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.toDoList")
      }}</v-toolbar-title>
      <v-spacer />
      <AppToDoProgressIndicator />
    </v-toolbar>
    <div class="pb-0 secondary-height overflow-y-auto">
      <AppToDoList model="task" :modelId="taskId" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskId: String,
    canClose: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
