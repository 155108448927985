<template>
  <div v-if="isProductRequired">
    <div class="label--small required-asterisk">
      {{ $t("settings.integrations.DUETT.timeArticle") }}
    </div>
    <AppDefaultAutocomplete
      v-model="localValue"
      :loading="isLoadingProducts"
      :items="products"
      :rules="rules"
      item-text="name"
      item-value="id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAccountingTimeEntryProducts } from "@/services/integration/accounting/accountingTimeEntryProduct.service";

export default {
  props: {
    value: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      products: [],
      rules: this.$rules.REQUIRED,
      isLoadingProducts: false,
    };
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      isTimeEntryProductRequiredIntegrationEnabled:
        "isTimeEntryProductRequiredIntegrationEnabled",
    }),
    isProductRequired() {
      return this.isTimeEntryProductRequiredIntegrationEnabled;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(id) {
        this.$emit("change", id);
      },
    },
  },
  methods: {
    getProducts() {
      this.isLoadingProducts = true;
      getAccountingTimeEntryProducts()
        .then((products) => {
          this.products = products;
        })
        .finally(() => {
          this.isLoadingProducts = false;
        });
    },
  },
  mounted() {
    if (this.isProductRequired) {
      this.getProducts();
    }
  },
};
</script>
