import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  isLoadingFeeSubscription: {
    isActive: false,
  },
});

const getters = {};

const actions = {
  async updateMainSubscription({ commit }, { body }) {
    return axios
      .put(`${apiUrl}billing-admin/main-subscription`, body)
      .then(({ data }) => {
        commit("billingDetails/setBillingDetails", data.billingDetails, { root: true });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updatePlatformFeeSubscription({ commit }, { body }) {
    commit("isLoadingFeeSubscriptionIsActive", true);
    return axios
      .put(`${apiUrl}billing-admin/platform-fee-subscription`, body)
      .then(({ data }) => {
        commit("billingDetails/setBillingDetails", data.billingDetails, { root: true });
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("isLoadingFeeSubscriptionIsActive", false);
      });
  },
  async updateBillingAccount({ commit }, { body }) {
    return axios
      .put(`${apiUrl}billing-admin/account`, body)
      .then(({ data }) => {
        commit("billingDetails/setBillingDetails", data.billingDetails, { root: true });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  isLoadingFeeSubscriptionIsActive(state, value) {
    state.isLoadingFeeSubscription.isActive = value;
  },
};

export const billingAdmin = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
