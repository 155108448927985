import "@fortawesome/fontawesome-pro/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { no, en } from "vuetify/es5/locale";
import icons from "./icons";
import themes from "./vuetifyThemes";
import { LIGHT, REGULAR, SOLID } from "@/helpers/icons";
const nb = no;

Vue.use(Vuetify);

const VUETIFY_ICONS = {
  complete: LIGHT.ACTION.ACCEPT,
  cancel: LIGHT.ACTION.CLOSE,
  close: LIGHT.ACTION.CLOSE,
  delete: LIGHT.ACTION.DELETE,
  clear: LIGHT.ACTION.CLEAR,
  success: SOLID.COMMON.CIRCLE_CHECK,
  info: SOLID.ACTION.INFO,
  warning: SOLID.ACTION.WARNING,
  error: SOLID.ACTION.ERROR,
  prev: LIGHT.COMMON.CHEVRON_LEFT,
  next: LIGHT.COMMON.CHEVRON_RIGHT,
  checkboxOn: SOLID.COMMON.SQUARE_CHECK,
  checkboxOff: LIGHT.COMMON.SQUARE,
  checkboxIndeterminate: SOLID.COMMON.SQUARE_MINUS,
  delimiter: LIGHT.COMMON.CIRCLE,
  sort: REGULAR.COMMON.ARROW_UP,
  expand: LIGHT.COMMON.ANGLE_DOWN,
  menu: LIGHT.COMMON.BARS,
  subgroup: SOLID.ACTION.DROPDOWN,
  dropdown: SOLID.ACTION.DROPDOWN,
  radioOn: SOLID.COMMON.CIRCLE_DOT,
  radioOff: LIGHT.COMMON.CIRCLE,
  edit: LIGHT.ACTION.EDIT,
  ratingEmpty: LIGHT.COMMON.STAR,
  ratingFull: LIGHT.COMMON.STAR,
  ratingHalf: LIGHT.COMMON.STAR_HALF,
  loading: SOLID.ACTION.LOADING,
  first: LIGHT.ACTION.FIRST,
  last: LIGHT.ACTION.FIRST,
  unfold: LIGHT.COMMON.UP_DOWN,
  file: LIGHT.ROUTE.ATTACHMENT,
  plus: LIGHT.ACTION.PLUS,
  minus: LIGHT.ACTION.MINUS,
};

const vuetify = new Vuetify({
  lang: {
    current: "nb",
    locales: { nb, en },
  },
  icons: {
    iconfont: "fa",
    values: { ...icons, ...VUETIFY_ICONS },
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes,
  },
});

export default vuetify;
