<template>
  <AppDefaultTooltip v-if="tooltipText" right>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        :style="rectangleStyle"
        :class="classes"
        @click="(e) => $emit('click', e)"
      >
        <v-icon :color="color" :size="iconSize">
          {{ icon }}
        </v-icon>
      </div>
    </template>
    <span v-html="tooltipText"></span>
  </AppDefaultTooltip>
  <div v-else :style="rectangleStyle" :class="classes" @click="(e) => $emit('click', e)">
    <v-icon :color="color" :size="iconSize">
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: "",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textColor() {
      return this.color + "--text";
    },
    classes() {
      return ["color-chip", this.textColor, this.clickable ? "cursor-pointer" : ""];
    },
    rectangleStyle() {
      return {
        width: this.width + "px",
        height: this.height + "px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
        "background-image":
          "linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95))",
      };
    },
    iconSize() {
      return this.width * 0.5;
    },
  },
};
</script>
