<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("fileManagement.preview.navMenu.e-signature.remindDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <template v-if="!isSignersLoading">
          <template v-if="status === $constant.E_SIGN_STATUS.IN_PROGRESS">
            <v-simple-table
              class="border-a overflow-hidden rounded mb-4"
              fixed-header
              height="200px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left input-background" style="height: 30px">
                      <span class="text--disabled">
                        {{
                          $t(
                            "fileManagement.preview.navMenu.e-signature.remindDialog.tableTitle",
                          )
                        }}
                        ({{ signers.length }})
                      </span>
                    </th>
                    <th class="text-left input-background" style="height: 30px"></th>
                  </tr>
                </thead>
                <tbody class="border-radius-0">
                  <tr
                    v-for="(signer, i) in signers"
                    :key="i"
                    class="cursor-pointer"
                    @click="selectSigner(signer)"
                  >
                    <td class="border-radius-0">
                      <v-icon
                        class="pr-2"
                        :color="canBeSelected(signer) ? '' : 'disabled'"
                      >
                        {{
                          isSelected(signer)
                            ? $icon.SOLID.COMMON.SQUARE_CHECK
                            : $icon.LIGHT.COMMON.SQUARE
                        }}
                      </v-icon>
                      <span :class="canBeSelected(signer) ? '' : 'text--disabled'">
                        {{ signer.name }} ({{ signer.email }})
                      </span>
                    </td>
                    <td>
                      <AppColorChip
                        :text="getStatusText(signer)"
                        :color="getStatusColor(signer)"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="label--small">
              {{
                $t("fileManagement.preview.navMenu.e-signature.remindDialog.messageLabel")
              }}
            </div>
            <AppDefaultTextarea
              ref="messageTextarea"
              v-model="message"
              label=""
              rows="2"
            />
          </template>
          <template v-else> The eSign is not in progress. </template>
        </template>
        <template v-else>
          <v-progress-linear indeterminate rounded height="6" />
        </template>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          v-if="!isSignersLoading"
          @confirm="onSubmit"
          @cancel="close"
          :confirmText="
            $t('fileManagement.preview.navMenu.e-signature.remindDialog.confirmButton')
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDocumentProperties } from "../../../services/storage/esign/documentProperties";
export default {
  data() {
    return {
      isValid: false,
      signers: [],
      isSignersLoading: false,
      status: null,
      selectedEmails: [],
      message: "",
    };
  },
  props: {
    dialog: Boolean,
    storageId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.getSigners();
      }
    },
  },
  methods: {
    getSigners() {
      this.isSignersLoading = true;
      this.selectedEmails = [];
      this.signers = [];
      getDocumentProperties(this.storageId)
        .then((data) => {
          this.signers = data.signers;
          this.status = data.status;
          this.selectedEmails = this.signers
            .filter((signer) => signer.status === "NOT_COMPLETED")
            .map((signer) => signer.email);
        })
        .finally(() => {
          this.isSignersLoading = false;
        });
    },
    isSelected(signer) {
      return this.selectedEmails.includes(signer.email);
    },
    canBeSelected(signer) {
      return signer.status === "NOT_COMPLETED";
    },
    selectSigner(signer) {
      if (!this.canBeSelected(signer)) return;
      if (this.isSelected(signer)) {
        this.selectedEmails = this.selectedEmails.filter(
          (email) => email !== signer.email,
        );
      } else {
        this.selectedEmails.push(signer.email);
      }
    },
    getStatusColor(signer) {
      let color = "primary";
      if (signer.status === "NOT_COMPLETED") {
        color = "warning";
      } else if (signer.status === "COMPLETED") {
        color = "success";
      }
      return `${color}--text`;
    },
    getStatusText(signer) {
      const status = signer.isViewed ? signer.status : "NOT_VIEWED";
      return this.$t(`fileManagement.preview.navMenu.e-signature.status.${status}`);
    },
    onSubmit() {
      if (this.selectedEmails.length === 0) return;
      this.$emit("submit", {
        emails: this.selectedEmails,
        message: this.message,
      });
      this.close();
    },
    close() {
      this.localDialog = false;
    },
  },
};
</script>
