<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 696 113.77"
  >
    <g>
      <path
        class="cls-1"
        d="M233.07,2.88h-11.37V27.88h-14.54v9.73h14.54v56.33c-.11,7.23,1.26,12.29,4.06,15.03,2.79,2.73,7.71,4.12,14.64,4.12,1.5,0,3.04-.05,4.57-.16,1.47-.11,2.98-.16,4.48-.16h.59v-9.8l-.66,.07c-2.88,.32-5.79,.48-8.62,.48-3.42-.2-5.56-1.15-6.37-2.81-.87-1.78-1.31-4.33-1.31-7.56V37.61h16.97v-9.73h-16.97V2.88Z"
      />
      <g>
        <path
          class="cls-1"
          d="M151.37,79.07c-.74,5.01-2.56,9.05-5.42,12.01-2.84,2.93-6.96,4.41-12.26,4.41-3.43,0-6.36-.78-8.73-2.32-2.39-1.55-4.31-3.57-5.71-6.01-1.42-2.46-2.45-5.25-3.08-8.29-.64-3.06-.96-6.11-.96-9.07s.32-6.2,.96-9.32c.63-3.09,1.72-5.96,3.24-8.53,1.51-2.54,3.49-4.65,5.88-6.26,2.37-1.59,5.35-2.4,8.88-2.4,9.55,0,14.91,4.53,16.39,13.85l.08,.5h23.6l-.04-.63c-.33-5.44-1.66-10.22-3.96-14.21-2.29-3.97-5.31-7.34-8.97-10.02-3.65-2.66-7.85-4.67-12.47-5.98-4.61-1.3-9.48-1.96-14.46-1.96-6.84,0-13.01,1.16-18.34,3.43-5.34,2.28-9.92,5.5-13.62,9.58-3.7,4.07-6.54,8.97-8.45,14.56-1.9,5.57-2.86,11.68-2.86,18.17s1.05,12.14,3.11,17.39c2.06,5.26,4.99,9.86,8.69,13.66,3.7,3.8,8.26,6.81,13.54,8.93,5.27,2.11,11.14,3.19,17.44,3.19,11.19,0,20.5-2.97,27.69-8.84,7.2-5.87,11.62-14.52,13.14-25.7l.09-.67h-23.33l-.07,.51Z"
        />
        <path
          class="cls-1"
          d="M75.34,40.26c-3.43-4.67-7.88-8.45-13.21-11.22-5.34-2.77-11.69-4.18-18.86-4.18-6.41,0-12.34,1.16-17.61,3.43-5.27,2.28-9.88,5.44-13.69,9.41-3.81,3.97-6.79,8.73-8.85,14.15-2.07,5.42-3.11,11.35-3.11,17.63s1.02,12.53,3.03,17.95c2.01,5.42,4.91,10.16,8.61,14.07,3.71,3.92,8.29,6.97,13.63,9.09,5.33,2.11,11.38,3.19,18,3.19,9.55,0,17.82-2.2,24.56-6.54,6.77-4.36,11.85-11.68,15.11-21.76l.25-.78h-21.46l-.13,.43c-.71,2.44-2.7,4.8-5.91,7.02-3.22,2.23-7.13,3.36-11.63,3.36-6.28,0-11.14-1.63-14.46-4.83-3.22-3.12-5.05-8.2-5.45-15.11h60.22l.04-.55c.43-6.49-.12-12.79-1.63-18.73-1.52-5.96-4.03-11.35-7.45-16.01m-41.59,4.83c2.33-1.19,5.32-1.79,8.86-1.79,5.4,0,9.47,1.45,12.09,4.32,2.55,2.79,4.38,6.9,5.46,12.23H24.19c.14-1.41,.47-3,.98-4.73,.56-1.95,1.56-3.84,2.97-5.6,1.39-1.75,3.29-3.24,5.62-4.44"
        />
        <rect class="cls-1" x="185.98" y="27.28" width="11.37" height="84.4" />
        <rect class="cls-1" x="185.99" y="2.19" width="11.37" height="10.63" />
      </g>
    </g>
    <rect class="cls-1" x="302.46" width="7.72" height="113.77" />
    <g>
      <path
        class="cls-1"
        d="M376.85,28.05l-23.67,56.04h6.07l5.29-12.77h27.19l5.29,12.77h6.15l-23.59-56.04h-2.73Zm12.62,37.9h-22.67l11.37-27.54,11.3,27.54Z"
      />
      <path
        class="cls-1"
        d="M436.94,68.6c0,6.44-4.33,10.94-10.53,10.94s-10.62-4.4-10.62-10.94v-25.33h-5.61v25.17c0,9.7,6.67,16.47,16.23,16.47s16.14-6.77,16.14-16.47v-25.17h-5.61v25.33Z"
      />
      <path
        class="cls-1"
        d="M470.57,79.62c-5.37,0-7.77-2.3-7.77-7.44v-23.63h12.69v-5.28h-12.69v-11.14h-5.69v11.14h-6.83v5.28h6.83v23.79c0,8.1,4.55,12.57,12.81,12.57,2.16,0,4.04-.25,6.3-.84l.58-.15v-5.12l-.93,.19c-1.83,.38-3.43,.63-5.29,.63Z"
      />
      <path
        class="cls-1"
        d="M502.38,42.45c-11.84,0-21.11,9.31-21.11,21.19s9.24,21.27,21.03,21.27,21.19-9.34,21.19-21.27-9.27-21.19-21.11-21.19Zm-.08,37.09c-8.7,0-15.25-6.8-15.25-15.82s6.59-15.9,15.33-15.9,15.33,6.84,15.33,15.9-6.77,15.82-15.41,15.82Z"
      />
      <path
        class="cls-1"
        d="M567.08,49.32c-3.41-4.24-9.16-6.87-15.33-6.87-11.33,0-20.21,9.09-20.21,20.7s8.88,20.78,20.21,20.78c6.03,0,11.72-2.57,15.17-6.72v2.78c0,8.89-5.25,14.19-14.03,14.19-5.51,0-10.81-2.26-14.91-6.36l-.57-.57-3.54,3.88,.47,.52c4.47,4.95,11.43,7.9,18.63,7.9,11.88,0,19.56-7.74,19.56-19.72V43.26h-5.45v6.06Zm-14.76,29.24c-8.7,0-15.01-6.45-15.01-15.33s6.31-15.33,15.01-15.33c6.74,0,14.6,4,14.6,15.25,0,10.65-7.33,15.41-14.6,15.41Z"
      />
      <path
        class="cls-1"
        d="M603.72,42.45c-11.56,0-20.62,9.34-20.62,21.27s9.27,21.19,21.11,21.19c6.37,0,12.18-2.7,16.37-7.59l.46-.54-3.68-3.8-.55,.65c-3.29,3.87-7.61,5.91-12.51,5.91-8.34,0-14.55-5.78-15.36-14.15h32.63l.15-.58c.17-.67,.35-2.24,.35-4.09,0-10.58-7.71-18.26-18.34-18.26Zm-14.54,17.73c1.42-7.37,7.22-12.45,14.38-12.45,3.72,0,7.06,1.34,9.41,3.79,2.15,2.24,3.3,5.22,3.33,8.66h-27.12Z"
      />
      <path
        class="cls-1"
        d="M646.59,42.45c-6.4,0-11.82,2.93-15.68,8.46l-.41,.59,4.02,3.22,.48-.62c3.37-4.35,6.87-6.29,11.35-6.29,6.48,0,10.05,3.8,10.05,10.7v3.7h-12.89c-8.77,0-14.44,4.42-14.44,11.26,0,7.89,7.17,11.43,14.27,11.43,5.43,0,10.16-1.97,13.3-5.49v4.67h5.45v-25.42c0-10.01-5.94-16.23-15.49-16.23Zm9.8,24.81v1.42c0,6.23-5.56,11.1-12.65,11.1-4.11,0-8.91-1.72-8.91-6.55s4.93-5.98,9.07-5.98h12.49Z"
      />
      <path
        class="cls-1"
        d="M695.31,43.27c-.2-.02-.39-.05-.59-.08-.54-.08-1.14-.17-2.02-.17-4.96,0-9.31,1.91-12.24,5.32v-5.08h-5.45v40.83h5.61v-23.87c0-7.69,6.47-11.83,12.57-11.83h2.8v-5.04l-.69-.08Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
.cls-1 {
  fill: #231f20;
}
</style>
