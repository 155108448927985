<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.overview")
      }}</v-toolbar-title>
      <v-spacer />
      <AppToggleMenuBtn>
        <AppMenuCopyUrlBtn
          :route="{
            name: $routeNames.FORM_VIEW.ROOT,
            params: { formId: this.form?.id },
          }"
        />
        <AppMenuDeleteBtn :disabled="!canEdit" @click="onDeleteFormClick" />
      </AppToggleMenuBtn>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="pa-5 pb-0 secondary-height overflow-y-auto" v-if="!isLoading && form">
      <v-form v-model="name.isValid" @submit.prevent>
        <div class="label--small required-asterisk">
          {{ $t("formManagement.list.formName") | capitalize }}
        </div>
        <AppDefaultTextarea
          :disabled="!canEdit"
          :value="form.name"
          @blur="updateFormField($event, 'name')"
          :rules="$rules.NAME_RULES"
          :label="$t('formManagement.list.formName')"
          rows="1"
          :counter="$rules.MAX_CHARACTERS"
        />
      </v-form>

      <v-form v-model="description.isValid" @submit.prevent>
        <div class="label--small">
          {{ $t("formManagement.list.formDescription") | capitalize }}
        </div>
        <AppDefaultTextarea
          :disabled="!canEdit"
          :value="form.description"
          @blur="updateFormField($event, 'description')"
          :rules="description.rules"
          :label="$t('formManagement.list.formDescription')"
        />
      </v-form>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("formManagement.list.responsible") | capitalize }}
        </div>
        <AppClientGroupedUserAndContactAutocomplete
          :disabled="!canEdit"
          :label="$t('formManagement.list.responsible')"
          hide-details
          :value="memberId"
          :items="assignableUsers"
          :loading="isAssignableUsersLoading"
          :labelPaths="['workTitleLabel', 'contact.relation.label']"
          @change="onMemberChange"
          :noDataText="$t('common.noUsers')"
          user-avatar
        />
      </div>

      <AppFieldMenuDatePicker
        :disabled="!canEdit"
        :label="$t('taskManagement.list.dueDate')"
        :date="form.dueDate"
        @date:change="onDueDateChange"
      />
      <AppProjectAutocompleteWithLabel
        ref="projectAutocomplete"
        :disabled="!canEdit"
        v-model="localProjectId"
        :isReadyToInitialFetch="!!form"
        :queryParams="{
          alwaysIncludeIds: [form?.projectId],
          userIds: [form?.member?.id],
        }"
        hide-details
        type="project"
        @created="
          ({ project }) => {
            localProjectId = project.id;
          }
        "
      />
      <AppTaskAutocompleteWithLabel
        ref="taskAutocomplete"
        v-model="localTaskId"
        :queryParams="{
          projectId: form?.projectId,
          alwaysIncludeIds: [form?.taskId],
        }"
        :isReadyToInitialFetch="!!form"
        refetchOnQueryParamsChange
        hide-details
        type="task"
        :data="{ projectId: form.projectId }"
        @created="
          ({ searchTask }) => {
            localTaskId = searchTask.id;
          }
        "
      />
      <div class="d-flex align-end">
        <div class="label--small required-asterisk">
          {{ $t("common.status") | capitalize }}
        </div>
        <v-spacer />
        <AppSettingsBtn
          @click.stop="onEditStatusesClick"
          :tooltip="statusMixins_tooltip('form')"
        />
      </div>
      <AppChangeCustomStatusAutocomplete
        :disabled="!canEdit"
        class="pb-5"
        :value="form.statusId"
        model="form"
        hide-details
        @change="onFormStatusChange"
      />
      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.category") | capitalize }}
        </div>
        <AppDefaultAutocomplete
          :disabled="!canEdit"
          :value="selectedCategory"
          :items="categories"
          :placeholder="$t('cms.template.form.nav.details.formCategory')"
          @change="onFormCategoryChange"
          :noDataText="$t('common.noCategories')"
          :loading="form.isCategoriesLoading"
          :clearable="false"
          hide-details
        />
      </div>

      <div class="pb-5" v-if="form.meta">
        <div class="d-flex align-center">
          <span class="label--small pt-1">
            {{ $t("formManagement.signature.enableSignature") }}
          </span>
          <v-spacer />
          <AppStonlyGuideBtn guideId="jO4OaVPSwz" />
        </div>
        <span>
          <AppDefaultSwitch
            class="mt-0"
            :loading="form.isUpdatingIsSignable"
            :disabled="form.isUpdatingIsSignable || !canEdit"
            :input-value="isSignable"
            @change="onIsSignableChange"
          />
        </span>
      </div>
      <div class="pb-5">
        <div class="d-flex align-center">
          <span class="label--small pt-1">
            {{ $t("formManagement.list.setAsPrivate") }}
          </span>
          <v-spacer />
          <AppStonlyGuideBtn guideId="vVPZRVBOsX" />
        </div>
        <div>
          <AppDefaultSwitch
            class="mt-0"
            :loading="form.isUpdatingIsPrivate"
            :disabled="form.isUpdatingIsPrivate || !canEdit"
            :input-value="form.isPrivate"
            @change="onIsPrivateChange"
          />
        </div>
      </div>
      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.creationDate") | capitalize }}
        </div>
        <div>
          {{ new Date(form.createdAt) | dateFormat("DD.MM.YYYY HH:mm") | capitalize }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.lastUpdated") | capitalize }}
        </div>
        <div>
          {{ new Date(form.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") | capitalize }}
        </div>
      </div>

      <div class="pb-5">
        <div class="label--small">
          {{ $t("common.id") | capitalize }}
        </div>
        <div>{{ form.number }}</div>
      </div>
    </div>
    <AppEditCustomStatusDialog v-model="editStatusDialog" model="form" />
    <AppDeleteConfirmationDialog
      v-model="deleteFormDialog"
      @delete="onDeleteForm"
      :title="$t('formManagement.deleteConfirmation.title')"
      :subtitle="$t('formManagement.deleteConfirmation.description')"
      :validatorText="
        $t('formManagement.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      :validator="$t('common.delete').toLowerCase()"
    />
  </div>
</template>

<script>
import * as moment from "moment";
import { getSearchUsers } from "@/services/search/users";
import { statusMixins } from "@/helpers/mixins";
import { mapState } from "vuex";

export default {
  mixins: [statusMixins],
  props: {
    form: Object,
    isLoading: Boolean,
  },
  data() {
    return {
      assignableUsers: [],
      isAssignableUsersLoading: false,
      dueDateMenu: false,
      editStatusDialog: false,
      name: {
        isValid: false,
      },
      description: {
        isValid: false,
        rules: [],
      },
      deleteFormDialog: false,
    };
  },
  watch: {
    "form.projectId": {
      handler(projectId) {
        if (projectId === undefined) return;
        this.getAssignableUsers({ projectId });
      },
      immediate: true,
    },
    "form.member.id": {
      handler(userId) {
        this.$refs.projectAutocomplete?.getProjects();
      },
    },
  },
  computed: {
    localProjectId: {
      get() {
        return this.form?.projectId;
      },
      set(projectId) {
        this.onProjectChange(projectId);
      },
    },
    localTaskId: {
      get() {
        return this.form?.taskId;
      },
      set(taskId) {
        this.onTaskChange(taskId);
      },
    },
    canEdit() {
      return this.form?.permissions?.canEdit;
    },
    isSignable() {
      return this.form?.isSignable === "TRUE";
    },
    ...mapState("formCategories", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    selectedCategory() {
      return this.form?.categoryId || null;
    },
    memberId() {
      return this.form?.member?.id;
    },
  },
  methods: {
    onEditStatusesClick() {
      this.editStatusDialog = true;
    },
    onDeleteFormClick() {
      this.deleteFormDialog = true;
    },
    onDeleteForm() {
      this.$emit("form:delete", { form: this.form });
    },
    getAssignableUsers({ projectId } = {}) {
      this.isAssignableUsersLoading = true;
      const params = {
        projectId,
        onlyWithProjectAccess: !!projectId,
        alwaysIncludeIds: [this.memberId],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableUsers = users;
        })
        .finally(() => (this.isAssignableUsersLoading = false));
    },
    updateFormField(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (value !== this.form[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.updateForm({ [field]: value });
      }
    },
    onMemberChange(memberId) {
      this.updateForm({ memberId });
    },
    onProjectChange(projectId) {
      this.updateForm({ projectId });
    },
    onTaskChange(taskId) {
      this.updateForm({ taskId });
    },
    onIsSignableChange(isSignable) {
      this.updateForm({ isSignable });
    },
    onIsPrivateChange(isPrivate) {
      this.updateForm({ isPrivate });
    },
    onDueDateChange(dueDate) {
      this.updateForm({ dueDate });
    },
    onFormCategoryChange(categoryId) {
      this.updateForm({ categoryId });
    },
    onFormStatusChange(statusId) {
      this.updateForm({ statusId });
    },
    updateForm(body) {
      this.$emit("form:update", { form: this.form, body });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    getCategories() {
      this.$store.dispatch("formCategories/getCategories");
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
