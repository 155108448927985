<template>
  <div>
    <div class="mb-4">
      <AppDefaultTextField
        class="mt-1 mb-4"
        :label="$t('common.search')"
        :append-icon="$icon.LIGHT.ACTION.SEARCH"
        :value="search"
        @input="debounceSearch"
        @click:append="getStreets(search)"
        hide-details
        clearable
        autofocus
      />

      <v-simple-table class="border-a" fixed-header height="400px">
        <template v-slot:default>
          <tbody class="border-radius-0">
            <template v-if="isLoading">
              <v-progress-linear indeterminate rounded height="6" />
            </template>
            <template v-else-if="hasError">
              Something went wrong, please try again
            </template>
            <template v-else>
              <template v-if="hasNoStreets">
                <div class="pa-4 d-flex align-center justify-center">
                  <div v-html="$t('integration.boligmappa.plantWizard.searchHelp')"></div>
                </div>
              </template>
              <template v-else>
                <tr
                  v-for="(street, i) in streets"
                  :key="i"
                  class="cursor-pointer"
                  :class="
                    street.id
                      ? street.id === selectedStreetId
                        ? 'list_tr--selected'
                        : ''
                      : ''
                  "
                  @click="selectStreet(street)"
                >
                  <td class="border-radius-0">
                    {{ streetText(street) }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-btn color="primary" @click="onNextStep" :disabled="!selectedStreetId">
      {{ $t("integration.boligmappa.plantWizard.nextStep") }}
    </v-btn>

    <v-btn text @click="onCancel">
      {{ $t("common.cancel") }}
    </v-btn>
  </div>
</template>

<script>
import { getStreets } from "../../../../../../services/integration/boligmappa/BoligmappaPropertyWizardService";

export default {
  props: {
    defaultValue: String,
  },
  data() {
    return {
      search: "",
      streets: [],
      isLoading: false,
      hasError: false,
      selectedStreetId: null,
      searchDebounce: null,
    };
  },
  watch: {
    search(search) {
      this.getStreets(search);
    },
  },
  computed: {
    hasNoStreets() {
      this.streets?.length === 0;
    },
  },
  methods: {
    getStreets(search) {
      if (!search || !search?.trim()) {
        this.streets = [];
        return;
      }
      this.$emit("search");
      this.selectedStreetId = null;
      this.hasError = false;
      this.isLoading = true;
      getStreets(search)
        .then(({ response }) => {
          this.streets = response;
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    streetText(street) {
      const streetName = street.streetName ? street.streetName : "";
      const postalCode = street.postalCode ? street.postalCode : "";
      const municipalityName = street.municipalityName ? street.municipalityName : "";
      return `${streetName}, ${postalCode} ${municipalityName}`;
    },
    selectStreet(street) {
      this.selectedStreetId = street.id;
    },
    onNextStep() {
      this.$emit("select", { selectedStreetId: this.selectedStreetId });
    },
    onCancel() {
      this.$emit("cancel");
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.search = event;
      }, 400);
    },
  },
  mounted() {
    this.search = this.defaultValue;
  },
};
</script>
