import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getApplicationTypes() {
  return axios
    .get(`${apiUrl}ebyggesok/application-types`)
    .then(({ data }) => {
      const { types } = data;
      return types;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
