import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  discovery: null,
  isLoading: false,
});

const getters = {
  getApplicationActions: (state) => (applicationName, ignoreFilter) => {
    if (!state.discovery) return [];
    const actions = state.discovery[applicationName] || [];

    return ignoreFilter
      ? actions
      : actions.filter((action) => !["editnew"].includes(action.name));
  },
  getApplicationOpenAction: (state, getters) => (applicationName) => {
    if (!state.discovery) return null;
    const actions = getters.getApplicationActions(applicationName);
    let action = actions.find((x) => x.name === "edit");
    if (!action) action = actions.find((x) => x.name === "view");
    return action;
  },
};

const actions = {
  async getDiscovery({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}office/wopi/discovery`)
      .then(({ data }) => {
        commit("setDiscovery", data);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  setDiscovery(state, discovery) {
    state.discovery = discovery;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const wopiDiscovery = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
