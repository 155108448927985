<template>
  <v-dialog v-model="localDialog" max-width="1000px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("actionRequest.addRecipientsDialog.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column overflow-y-auto" style="max-height: 600px">
          <v-form ref="recipientsForm" v-model="isValid" @submit="onSubmit">
            <div
              class="d-flex pt-1"
              v-for="(recipient, index) in recipients"
              :key="index"
            >
              <AppUserAndContactGroupedUserAndContactCombobox
                class="pr-3"
                :loading="isLoading"
                :users="users"
                :contacts="contacts"
                :label="
                  $t(
                    'fileManagement.preview.navMenu.e-signature.prepDocumentDialog.addFromListOrEnterFreeText',
                  )
                "
                :value="recipient"
                @change="changeRecipientNameAndEmail($event, recipient)"
              />
              <AppDefaultTextField
                style="width: 200px"
                :rules="$rules.EMAIL_RULES"
                class="pr-3"
                :label="$t('common.email')"
                v-model="recipient.email"
              />

              <AppDefaultSelect
                style="width: 50px"
                :items="languages"
                v-model="recipient.language"
                :clearable="false"
              >
                <template v-slot:item="{ item }">
                  <div>{{ item.text }} ({{ item.value }})</div>
                </template>
              </AppDefaultSelect>

              <v-btn icon class="mx-3" @click="onRecipientDelete(recipient)">
                <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
              </v-btn>
            </div>
          </v-form>
        </div>
        <v-btn outlined color="primary" @click="addRecipient" class="mt-2">
          <v-icon class="pr-3">{{ $icon.REGULAR.ACTION.ADD }}</v-icon>
          {{ $t("actionRequest.addRecipientsDialog.addRecipientButton") }}
        </v-btn>
        <AppErrorAlert class="mt-2" v-if="showNoDuplicateEmailsError">
          {{ $t("actionRequest.addRecipientsDialog.errorMessages.noDuplicateEmails") }}
        </AppErrorAlert>
        <AppErrorAlert class="mt-2" v-if="showApiError">
          {{ $t("actionRequest.addRecipientsDialog.errorMessages.apiError") }}
        </AppErrorAlert>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="closeDialog"
          :loading="isSending"
          :confirmText="$t('actionRequest.addRecipientsDialog.confirmButton')"
        />
      </v-card-actions>
    </v-card>
    <AppDeleteConfirmationDialog
      v-model="dialogs.deleteRecipient.active"
      :title="$t('actionRequest.addRecipientsDialog.confirmDeleteDialog.title')"
      :subtitle="$t('actionRequest.addRecipientsDialog.confirmDeleteDialog.subTitle')"
      skip-validation
      :item="dialogs.deleteRecipient.item"
      @delete="deleteRecipient"
    />
  </v-dialog>
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import {
  createActionRequest,
  updateActionRequest,
} from "@/services/actionRequest/actionRequest";
import { getSearchContacts } from "@/services/search/contact";

const genEmptyRecipient = () => ({ name: "", email: "", language: "nb", userId: null });

export default {
  data() {
    return {
      isValid: false,
      dialogs: {
        deleteRecipient: {
          item: null,
          active: false,
        },
      },
      recipients: [genEmptyRecipient()],
      languages: [
        {
          value: "nb",
          text: this.$t("actionRequest.addRecipientsDialog.language.no"),
        },
        {
          value: "en",
          text: this.$t("actionRequest.addRecipientsDialog.language.en"),
        },
      ],
      users: [],
      isLoadingUsers: false,
      contacts: [],
      isLoadingContacts: false,
      showNoDuplicateEmailsError: false,
      showApiError: false,
      isSending: false,
    };
  },
  props: {
    dialog: Boolean,
    modelId: String,
    model: String,
    type: String,
    actionRequestId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.getUsers();
          this.getContacts();
        }
      },
      immediate: true,
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isLoading() {
      return this.isLoadingContacts || this.isLoadingUsers;
    },
    isEditMode() {
      return !!this.actionRequestId;
    },
  },
  methods: {
    changeRecipientNameAndEmail(event, recipient) {
      if (!event) {
        recipient.userId = null;
        return;
      }
      if (typeof event === "string") {
        recipient.name = event;
      } else {
        recipient.name = event.name;
        recipient.userId = event.id;
        recipient.email = event.email;
      }
    },
    isRecipientEmailOrNameEmpty(recipient) {
      const isUndefined = !recipient.name && !recipient.email;
      const isEmpty = !recipient?.name?.trim() && !recipient?.email?.trim();
      return isUndefined || isEmpty;
    },
    onRecipientDelete(recipient) {
      let isEmpty = this.isRecipientEmailOrNameEmpty(recipient);

      if (isEmpty) {
        this.deleteRecipient({ item: recipient });
        return;
      }

      this.dialogs.deleteRecipient.item = recipient;
      this.dialogs.deleteRecipient.active = true;
    },
    deleteRecipient({ item }) {
      const index = this.recipients.indexOf(item);
      if (index === -1) return;
      this.recipients.splice(index, 1);
    },
    addRecipient() {
      this.recipients.push(genEmptyRecipient());
    },
    hasNoDuplicateEmails() {
      const emails = this.recipients.map((recipient) => recipient.email);
      return new Set(emails).size === emails.length;
    },
    validate() {
      this.showApiError = false;
      this.isValid = this.$refs.recipientsForm.validate();
      const isValidNoDuplicateEmails = this.hasNoDuplicateEmails();
      this.showNoDuplicateEmailsError = !isValidNoDuplicateEmails;
      return this.isValid && isValidNoDuplicateEmails;
    },
    async getUsers() {
      this.isLoadingUsers = true;
      const params = {
        alwaysIncludeIds: this.recipients?.map((recipient) => recipient?.userId) ?? [],
      };
      this.users = await getSearchUsers({ params });
      this.isLoadingUsers = false;
    },
    async getContacts() {
      this.isLoadingContacts = true;
      getSearchContacts()
        .then((contacts) => {
          this.contacts = contacts;
        })
        .finally(() => (this.isLoadingContacts = false));
    },
    getRequestArgs() {
      const recipients = this.recipients.map(({ name, email, language }) => ({
        name,
        email,
        language,
      }));

      const baseBody = { recipients };

      if (this.isEditMode) {
        return {
          id: this.actionRequestId,
          body: {
            ...baseBody,
          },
        };
      } else {
        return {
          body: {
            ...baseBody,
            modelId: this.modelId,
            model: this.model,
            type: this.type,
          },
        };
      }
    },
    onSubmit() {
      const isValid = this.validate();
      if (!isValid) return;
      const args = this.getRequestArgs();
      this.isSending = true;
      const action = this.isEditMode ? updateActionRequest : createActionRequest;
      action(args)
        .then((data) => {
          this.$emit("submit");
          this.resetAndCloseDialog();
        })
        .catch((err) => {
          this.showApiError = true;
          throw new Error(err);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    resetAndCloseDialog() {
      this.recipients = [genEmptyRecipient()];
      this.closeDialog();
    },
    closeDialog() {
      this.localDialog = false;
      this.showNoDuplicateEmailsError = false;
      this.showApiError = false;
      this.$refs.recipientsForm.resetValidation();
    },
  },
};
</script>
