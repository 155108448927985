export function canEditTeam(team, user) {
  if (!team || !user) return false;
  if (
    user.currentDomain.isOwner ||
    user.currentDomain.isAdmin ||
    user.currentDomain.isHrAdmin
  )
    return true;
  if (team.creatorId === user.id) {
    return true;
  }
  if (team.ownerId === user.id) {
    return true;
  }
  return false;
}

export function canDeleteTeam(team, user) {
  if (!team || !user) return false;
  if (
    user.currentDomain.isOwner ||
    user.currentDomain.isAdmin ||
    user.currentDomain.isHrAdmin
  )
    return true;
  if (team.creatorId === user.id) {
    return true;
  }
  return false;
}

export function teamPermissions(team, user) {
  return {
    canDelete: canDeleteTeam(team, user),
    canEdit: canEditTeam(team, user),
  };
}
