export const timeMixins = {
  methods: {
    timeMixins_formatHoursShort(duration) {
      if (!duration) return "-";
      const { hours, minutes } = this.timeMixins_durationToHMS(duration);
      return `${hours}${this.$t("timeRegistration.common.h")} ${minutes}${this.$t(
        "timeRegistration.common.m",
      )}`;
    },
    timeMixins_durationToHMS(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return {
        hours,
        minutes,
        seconds,
      };
    },
    timeMixins_aggregateDuration(inputList, durationKey) {
      const totalSeconds = inputList.reduce((result, item) => {
        result += item[durationKey];
        return result;
      }, 0);

      return totalSeconds;
    },
    timeMixins_hoursToSeconds(hours) {
      const numericHours = parseFloat(hours);
      if (isNaN(numericHours)) {
        return null;
      }
      const flooredHours = this.timeMixins_floorHours(numericHours);
      const seconds = flooredHours * 3600;
      const flooredSeconds = this.timeMixins_floorSeconds(seconds);
      return flooredSeconds;
    },
    timeMixins_secondsToHours(seconds) {
      let numericSeconds = parseFloat(seconds);
      const flooredSeconds = this.timeMixins_floorSeconds(numericSeconds);
      if (isNaN(flooredSeconds)) {
        return null;
      }
      const hours = flooredSeconds / 3600;
      const flooredHours = this.timeMixins_floorHours(hours);
      return flooredHours;
    },
    timeMixins_floorSeconds(seconds) {
      return Math.floor(seconds);
    },
    timeMixins_floorHours(hours) {
      return Math.floor(hours * 100) / 100;
    },
  },
};
