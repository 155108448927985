<template>
  <AppWidgetShortcutButton
    @click="navigateFillOutForms"
    @delete:click="onDelete"
    :templateProps="templateProps"
    :icon="$icon.LIGHT.ROUTE.FORM"
    :title="$t('dashboard.shortcutButton.fillOutFormTitle')"
    :description="$t('dashboard.shortcutButton.fillOutFormDescription')"
  />
</template>

<script>
export default {
  props: {
    templateProps: Object,
  },
  methods: {
    navigateFillOutForms() {
      this.$router.replace({
        name: this.$routeNames.FORM.ROOT,
        query: { openTemplatePicker: true },
      });
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
