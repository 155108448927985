import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import { normalizeCmsFormTemplateCategories } from "@/helpers/normalization";

const state = () => ({
  isLoading: false,
  categories: [],
});

const getters = {};

const actions = {
  async getCategories({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}cms/category`, { params: { model: "form" } })
      .then(({ data }) => {
        const categories = data?.categories;
        const normalizedCategories = normalizeCmsFormTemplateCategories(categories);
        commit("setCategories", normalizedCategories);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const cmsFormTemplateCategories = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
