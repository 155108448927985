export function normalizeStorageSearch(storages, itemsToKeep, keepSelected) {
  const normalizedStorages = storages.map((item) => {
    let isSelected = false;
    if (keepSelected) {
      const keptIndex = itemsToKeep.findIndex((i) => i.id === item.id);
      if (keptIndex !== -1) {
        isSelected = true;
        itemsToKeep.splice(keptIndex, 1);
      }
    }
    return {
      ...item,
      isLoading: false,
      isSelected,
    };
  });

  if (keepSelected) {
    normalizedStorages.unshift(...itemsToKeep);
  }

  return normalizedStorages;
}
