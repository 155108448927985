import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function importProducts(data) {
  return axios
    .post(`${apiUrl}product-import`, data)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
