<template>
  <AppWidgetTemplate
    v-if="isLoggingOut"
    @delete:click="$emit('delete:click')"
    :title="$t('widgetLibrary.autogearVehicle.title')"
    v-bind="templateProps"
  >
    <div class="w-100 h-100 d-flex align-center justify-center">
      <AppCircularLoader :style="`margin-bottom: ${headerCompensation}`" />
    </div>
  </AppWidgetTemplate>
  <AppWidgetTemplate
    v-else-if="isAutogearIntegrationEnabled && !isUnauthorized"
    @delete:click="$emit('delete:click')"
    :title="$t('widgetLibrary.autogearVehicle.title')"
    :isLoading="isLoading"
    v-bind="templateProps"
  >
    <template v-slot:append-header>
      <AppToggleMenuBtn>
        <AppMenuItem
          :text="$t('widgetLibrary.autogearVehicle.ellipsisMenu.openAutogear')"
          :icon="$icon.REGULAR.ACTION.POP_OUT"
          @click="openAutogear"
        />
        <AppMenuItem
          :text="$t('widgetLibrary.autogearVehicle.ellipsisMenu.autogearLogOut')"
          :icon="$icon.REGULAR.ACTION.LOG_OUT"
          color="error"
          @click="onLogOut"
        />
      </AppToggleMenuBtn>
    </template>
    <AppDefaultTabs
      dense
      :value="selectedTabName"
      :tabs="tabs"
      @click="onTabClick"
      @change="onTabChange"
      tabId="widget-autogear-vehicle"
      backgroundColor="transparent"
      hide-padding-bottom
      style="cursor: unset !important"
    />
    <div class="overflow-y-auto" :style="`height: calc(100% - ${headerCompensation})`">
      <template v-if="activeTabIsVehicle || activeTabIsEquipment">
        <div class="pl-5 pb-3 pr-5" v-for="(item, index) in data" :key="item.id">
          <div
            :class="`w-100 d-flex align-center rounded widget_list_item py-2 px-2 ${
              index === 0 ? 'mt-2' : ''
            }`"
          >
            <div>
              <div class="d-flex align-center">
                <div class="pr-2">{{ item.name }}</div>
                <div v-if="item.lastKnownTime" class="text-caption">
                  ({{ formatLastKnownTime(item.lastKnownTime) }})
                </div>
              </div>
              <div class="subtitle--text text-caption">{{ item.lastKnownAddress }}</div>
            </div>
            <v-spacer />
            <div>{{ item.driverName }}</div>
          </div>
        </div>
      </template>
      <template v-if="activeTabIsMap">
        <AppGoogleMap :markers="markers" />
      </template>
    </div>
  </AppWidgetTemplate>
  <AppWidgetTemplate
    v-else
    @delete:click="$emit('delete:click')"
    :title="$t('widgetLibrary.autogearVehicle.title')"
    v-bind="templateProps"
    :isLoading="isLoading"
  >
    <div class="w-100 h-100 d-flex align-center justify-center">
      <v-btn
        :style="`margin-bottom:  ${headerCompensation}`"
        depressed
        class="primary"
        @click="dialog.autogearLogin.active = true"
      >
        {{ $t("common.login") }}
      </v-btn>
    </div>
    <AppAutogearLoginDialog v-model="dialog.autogearLogin.active" />
  </AppWidgetTemplate>
</template>

<script>
import moment from "moment";
import { getAutogearGpsAndTags } from "@/services/integration/autogear/autogearGpsAndTags";
import { mapGetters, mapState } from "vuex";
import {
  getIntegrations,
  updateIntegration,
} from "@/services/integration/integration/integration";

export default {
  props: {
    templateProps: Object,
    selectedTabName: String,
  },
  data() {
    return {
      autogearData: [],
      isLoadingAutogear: false,
      localSelectedTabName: "VEHICLE",
      dialog: {
        autogearLogin: {
          active: false,
        },
      },
      statusCode: null,
      isLoggingOut: false,
    };
  },
  watch: {
    isAutogearIntegrationEnabled: {
      handler(isEnabled) {
        if (isEnabled) {
          this.getAutogearGpsAndTags();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      isAutogearIntegrationEnabled: "isAutogearIntegrationEnabled",
    }),
    ...mapState("auth", {
      isLoadingActiveIntegration: (state) => state.isLoading,
    }),
    headerCompensation() {
      return "92px";
    },
    tabs() {
      return [
        {
          title: this.$t("widgetLibrary.autogearVehicle.tabs.vehicle"),
          name: "VEHICLE",
        },
        {
          title: this.$t("widgetLibrary.autogearVehicle.tabs.equipment"),
          name: "EQUIPMENT",
        },
        {
          title: this.$t("widgetLibrary.autogearVehicle.tabs.viewInMap"),
          name: "MAP",
        },
      ];
    },
    isUnauthorized() {
      return this.statusCode === 401;
    },
    activeTabIsVehicle() {
      return this.localSelectedTabName === "VEHICLE";
    },
    activeTabIsEquipment() {
      return this.localSelectedTabName === "EQUIPMENT";
    },
    activeTabIsMap() {
      return this.localSelectedTabName === "MAP";
    },
    isLoading() {
      return this.isLoadingAutogear;
    },
    data() {
      if (this.localSelectedTabName === "VEHICLE") return this.vehicles;
      if (this.localSelectedTabName === "EQUIPMENT") return this.equipment;
    },
    vehicles() {
      return this.autogearData?.filter((gps) => {
        return gps.hardwareType === "VEHICLE";
      });
    },
    equipment() {
      return this.autogearData?.filter((gps) => {
        return gps.hardwareType === "TAG";
      });
    },
    markers() {
      return this.autogearData
        ?.filter((gps) => gps.latitude && gps.longitude)
        .map((gps) => {
          return {
            position: {
              lat: parseFloat(gps.latitude),
              lng: parseFloat(gps.longitude),
            },
            title: gps.name,
            infoWindowContent: this.getInfoWindowContent(gps),
            icon: this.markerIcon(gps),
          };
        });
    },
  },
  methods: {
    onTabClick() {
      this.getAutogearGpsAndTags();
    },
    openAutogear() {
      window.open("https://app.autogear.com", "_blank");
    },
    markerIcon(gps) {
      const parser = new DOMParser();
      if (gps.hardwareType === "VEHICLE") {
        const pinSvgString = `<svg xmlns="http://www.w3.org/2000/svg" height="32" width="28" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d="M35.8 431.9L190.9 54.2C196.4 40.8 209.5 32 224 32s27.6 8.8 33.1 22.2L412.2 431.9c9.4 23-7.4 48.1-32.3 48.1c-7.8 0-15.3-2.6-21.4-7.3L224 368 89.4 472.7C83.3 477.4 75.8 480 68 480c-24.8 0-41.7-25.2-32.3-48.1z"/></svg>`;
        const pinSvg = parser.parseFromString(
          pinSvgString,
          "image/svg+xml",
        ).documentElement;
        return pinSvg;
      } else if (gps.hardwareType === "TAG") {
        return undefined;
      }
    },
    getInfoWindowContent(gps) {
      const hasDriverName = !!gps.driverName?.trim();
      const hasLastKnownAddress = !!gps.lastKnownAddress?.trim();

      return `
        <h3 style="padding-bottom: 8px;">${gps.name}</h3>
        ${
          hasLastKnownAddress
            ? `<div style="padding-bottom: 8px;">
              ${this.$t("widgetLibrary.autogearVehicle.lastKnownAddress")}: ${
                gps.lastKnownAddress
              }
            </div>`
            : ""
        }
        ${
          hasDriverName
            ? `<div style="padding-bottom: 8px;"> ${this.$t(
                "widgetLibrary.autogearVehicle.defaultDriver",
              )}: ${gps.driverName}
              </div>`
            : ""
        }
      `;
    },
    getAutogearGpsAndTags() {
      this.isLoadingAutogear = true;
      getAutogearGpsAndTags()
        .then((data) => {
          if (data.isCanceled) return;
          this.statusCode = data.statusCode;
          this.autogearData = data.data || [];

          this.isLoadingAutogear = false;
        })
        .catch((err) => {
          this.isLoadingAutogear = false;
          throw new Error(err);
        });
    },
    formatLastKnownTime(date) {
      return moment(date).fromNow();
    },
    onTabChange({ name }) {
      this.localSelectedTabName = name;
      const body = { selectedTabName: name };
      this.$emit("item:update", body);
    },
    async onLogOut() {
      this.isLoggingOut = true;
      await this.deleteIntegrationInstance();
      this.isLoggingOut = false;
    },
    async initializeComponent() {
      if (!!this.selectedTabName) {
        this.localSelectedTabName = this.selectedTabName;
      }
    },
    async deleteIntegrationInstance() {
      const integrations = await getIntegrations({ type: "DOMAIN_USER" });
      const autogearIntegration = integrations.find((integration) => {
        return integration.name === this.$constant.AUTOGEAR;
      });

      const instanceId = autogearIntegration.integrationInstance?.id;
      await updateIntegration({ id: instanceId, body: { active: false } });
      await this.$store.dispatch("activeIntegrations/getActiveIntegrations");
    },
  },
  mounted() {
    this.initializeComponent();
  },
};
</script>
