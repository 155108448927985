import { normalizeCmsDocumentTemplateHistory } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  items: [],
  isLoading: false,
  isLoadingMore: false,
  canLoadMore: false,
  offset: 0,
});

const getters = {};

const actions = {
  async getItems({ commit, dispatch, state }, { id, offset, limit = 5 }) {
    commit("setOffset", limit + offset);
    if (offset > 0) {
      commit("loadingMore", true);
    } else {
      commit("loading", true);
    }
    axios
      .get(`${apiUrl}audit/${id}/admin`, {
        params: { model: "", limit, offset },
      })
      .then((res) => {
        const data = res.data;
        const normalizedItems = normalizeCmsDocumentTemplateHistory(data);

        if (offset > 0) {
          let joinedItems = [...state.items, ...normalizedItems];
          joinedItems = [...new Set(joinedItems.map((i) => i.id))].map((id) =>
            joinedItems.find((i) => i.id === id),
          );

          commit("setItems", joinedItems);
        } else {
          commit("setItems", normalizedItems);
        }

        commit("loadMore", data.hasMore);
        commit("loading", false);
        commit("loadingMore", false);
      })
      .catch((error) => {
        commit("loading", false);
        commit("loadingMore", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setOffset(state, offset) {
    state.offset = offset;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  loadingMore(state, isLoadingMore) {
    state.isLoadingMore = isLoadingMore;
  },
  loadMore(state, canLoadMore) {
    state.canLoadMore = canLoadMore;
  },
};

export const cmsTemplateHistory = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
