<template>
  <div>
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteUser"
      :item="selectedItem"
      :title="$t('users.deleteConfirmation.title')"
      :subtitle="$t('users.deleteConfirmation.description')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="deleteUser"
    />
    <AppEditUserDialog
      v-model="dialog.editUser"
      :item="selectedItem"
      @afterEdit:completed="afterEdit"
    />
    <AppDataTable
      :headers="_headers"
      :items="users"
      :loading="isLoading"
      :noDataText="$t('common.noUsers')"
      :isStatsLoading="isUserStatsLoading"
      :statsText="statsText"
    >
      <template v-slot:item="{ item }">
        <AppUserTableItem
          :user="item"
          :canEdit="canEdit"
          :isHighlighted="isHighlighted(item)"
          :canDoActions="canDoActions"
          :canEditLabels="canEditLabels(item)"
          :jobTitleLabel="jobTitleLabel(item)"
          @user:onRoleChange="onRoleChange"
          @user:onDomainUserUpdate="onDomainUserUpdate"
          @menu:click="onMenuClick"
          @workTitleLabel:click="openJobTitleMenu"
        />
      </template>
    </AppDataTable>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppActivityIndicator
      :show="isUpdatingStatus"
      :text="$t('users.userInvite.isUpdatingStatus')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onLabelChange"
      :labelType="labelType"
      selectedItemLabelPath="workTitleLabel"
    />
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuInviteUserBtn
        @click="onUserReInvite"
        :user="selectedItem"
        :disabled="isInviteDisabled"
      />
      <AppMenuSetInactiveBtn @click="setInactiveUser" :disabled="isSetInactiveDisabled" />
      <AppMenuEditBtn @click="onEditClick" :disabled="!canEditUser" />
      <AppMenuDeleteBtn @click="onUserDelete" :disabled="isSetDeletedDisabled" />
    </AppContextMenu>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    users: Array,
    isLoading: Boolean,
    currentUserId: String,
    canDoActions: Boolean,
    canEdit: Boolean,
  },
  data() {
    return {
      dialog: {
        deleteUser: false,
        editUser: false,
      },
      selectedItem: null,
      addLabelDialog: {
        active: false,
      },
      updateLabelDialog: {
        active: false,
        data: null,
      },
      labelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      isContextMenuOpen: false,
    };
  },
  computed: {
    canEditUser() {
      return this.canEdit && !this.selectedItem?.isContact;
    },
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    ...mapState("settingsUsers", {
      isInviting: (state) => state.isInviting,
      isUpdatingStatus: (state) => state.isUpdatingStatus,
    }),
    ...mapState("userStats", {
      isUserStatsLoading: (state) => state.isLoading,
    }),
    ...mapGetters("userStats", {
      statsCountNotDeletedOrInactive: "statsCountNotDeletedOrInactive",
    }),
    isInviteDisabled() {
      if (!this.canEdit) return true;
      if (this.hasStatus(this.$constant.ACTIVE)) return true;
      return false;
    },
    isSetInactiveDisabled() {
      if (!this.canEdit) return true;
      if (this.isCurrentUser) return true;
      if (this.hasStatus(this.$constant.INACTIVE)) return true;
      return false;
    },
    isSetDeletedDisabled() {
      if (!this.canEdit) return true;
      if (this.isCurrentUser) return true;
      if (this.hasStatus(this.$constant.DELETED)) return true;
      return false;
    },
    isCurrentUser() {
      return this.selectedItem?.id === this.currentUserId;
    },
    statsText() {
      return this.$tc(
        "common.informationHeader.users",
        this.statsCountNotDeletedOrInactive,
      );
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("users.name"),
          value: "fullName",
          show: true,
        },
        {
          text: this.$t("common.email"),
          value: "email",
          show: true,
        },
        {
          text: this.$t("users.phone"),
          sortable: false,
          show: true,
        },
        {
          text: this.$t("users.label.jobTitle.title"),
          value: "workTitleLabel.name",
          show: true,
        },
        {
          text: this.$t("users.adminPrivileges"),
          value: "isAdmin",
          align: "center",
          show: true,
        },
        {
          text: this.$t("users.hrAdminPrivileges"),
          value: "isHrAdmin",
          align: "center",
          show: true,
        },

        {
          text: this.$t("users.status"),
          value: "status",
          show: true,
        },
        {
          text: this.$t("common.id"),
          value: "number",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    getLabels() {
      this.$store.dispatch("label/getLabels", { type: this.labelType });
    },
    jobTitleLabel(item) {
      return this.labelsByType(this.labelType).find(
        (label) => label?.id === item?.workTitleLabel?.id,
      );
    },
    canEditLabels(item) {
      return (this.isDomainOwner || this.isDomainAdmin) && !item.isContact;
    },
    openJobTitleMenu({ pointerEvent, item: selectedItem }) {
      this.$refs.labelMenuJobTitle.open({
        pointerEvent,
        data: { selectedItem },
      });
    },
    onLabelChange({ selectedItem, label }) {
      this.onDomainUserUpdate({
        id: selectedItem?.id,
        labelId: label.id,
      });
    },
    getUserStats() {
      this.$store.dispatch("userStats/getStats");
    },
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);

      return this.isContextMenuOpen || isAnyDialogsOpen;
    },
    hasStatus(status) {
      if (!this.selectedItem) return false;
      return this.selectedItem.status === status;
    },
    onMenuClick({ event, user }) {
      this.selectedItem = user;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    deleteUser({ item }) {
      this.onDomainUserUpdate({ id: item.id, status: this.$constant.DELETED });
    },
    setInactiveUser() {
      this.onDomainUserUpdate({
        id: this.selectedItem.id,
        status: this.$constant.INACTIVE,
      });
    },
    afterEdit() {
      this.$emit("afterEdit:completed");
    },
    onRoleChange(e) {
      this.$emit("user:onRoleChange", e);
    },
    onDomainUserUpdate(e) {
      this.$emit("user:onDomainUserUpdate", e);
    },
    onUserReInvite() {
      this.$emit("user:reInvite", this.selectedItem);
    },
    onEditClick() {
      this.dialog.editUser = true;
    },
    onUserDelete() {
      this.dialog.deleteUser = true;
    },
  },
  mounted() {
    this.getUserStats();
    this.getLabels();
  },
};
</script>
