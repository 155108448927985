import { ROUTE_NAMES } from "@/helpers/routeNames";

const {
  SHARED_STORAGE_VIEW,
  SHARED_DOCUMENT_READ_CONFIRMATION_VIEW,
  SHARED_FORM_SIGN_VIEW,
} = ROUTE_NAMES.EXTERNAL;

export const externalRoutes = [
  {
    path: "/view/storage/:hash",
    name: SHARED_STORAGE_VIEW,
    component: () =>
      import(
        /* webpackChunkName: "sharedView" */ "../../pages/SharedView/StorageSharing"
      ),
  },

  // Form sharing
  {
    path: "/document-read-confirmation",
    name: SHARED_DOCUMENT_READ_CONFIRMATION_VIEW,
    component: () =>
      import(
        /* webpackChunkName: "sharedView" */ "../../pages/SharedView/StorageSharing/StorageReadConfirmation"
      ),
  },

  // Form sharing
  {
    path: "/form-sign",
    name: SHARED_FORM_SIGN_VIEW,
    component: () =>
      import(
        /* webpackChunkName: "sharedView" */ "../../pages/SharedView/FormSharing/FormSignPage"
      ),
  },
];
