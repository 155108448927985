<template>
  <div>
    <AppModuleAutocomplete
      ref="autocomplete"
      v-bind="params"
      v-on="listeners"
      :items="filteredTasks"
      :loading="loading"
      item-text="name"
      item-value="id"
      :noDataText="$t('common.noTasks')"
      :titleField="$constant.MODULE_FIELD_CONFIG.TASK.TITLE_FIELD"
      :subTitleFields="$constant.MODULE_FIELD_CONFIG.TASK.SUB_TITLE_FIELDS"
    >
      <template #append-item>
        <AppSelectAppendCreateBtn @click="onCreateTaskClick">
          {{ $t("common.createNew") }}
        </AppSelectAppendCreateBtn>
      </template>
    </AppModuleAutocomplete>
    <AppTaskDialog
      v-model="newTaskDialog.active"
      :title="$t('task.newTask.title')"
      :confirmText="$t('common.create')"
      :data="data"
      @created="onTaskCreated"
    />
  </div>
</template>

<script>
import { getSearchTasks } from "@/services/search/task";
import _ from "lodash";

export default {
  props: {
    value: [String, Array, Object],
    queryParams: Object,
    refetchOnQueryParamsChange: Boolean,
    isReadyToInitialFetch: Boolean,
    idsToExclude: Array,
    data: Object,
  },
  data() {
    return {
      newTaskDialog: {
        active: false,
      },
      searchTasks: [],
      loading: false,
      hasInitialFetched: false,
    };
  },
  watch: {
    //initial data fetch
    isReadyToInitialFetch: {
      handler(newVal) {
        if (newVal && !this.hasInitialFetched) {
          this.getTasks().then(() => {
            this.hasInitialFetched = true;
          });
        }
      },
      immediate: true,
    },
    //refetch on query params change,
    //this is particularly useful in filters
    queryParams: {
      handler(newVal, oldVal) {
        if (newVal && this.refetchOnQueryParamsChange && !_.isEqual(newVal, oldVal)) {
          this.getTasks();
        }
      },
      deep: true,
    },
  },
  computed: {
    filteredTasks() {
      return this.searchTasks.filter((task) => !this.idsToExclude?.includes(task.id));
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    onCreateTaskClick() {
      this.newTaskDialog.active = true;
    },
    onTaskCreated({ task }) {
      this.getTasks().then(() => {
        const searchTask = this.searchTasks.find((t) => t.id === task.id);
        if (!searchTask) return;
        this.$emit("created", { task, searchTask });
      });
    },
    async getTasks() {
      this.loading = true;
      await getSearchTasks({ params: { ...this.queryParams } })
        .then((tasks) => {
          this.searchTasks = tasks;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>
