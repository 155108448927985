import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  documentTypes: [],
  chapterTags: [],
  professionTypes: [],
  rooms: [],
  isLoading: false,
  hasError: false,
  isUploading: false,
});

const getters = {};

const actions = {
  async getTypesAndTags({ commit }, boligmappaNumber) {
    commit("error", false);
    commit("loading", true);
    return axios
      .get(`${apiUrl}boligmappa/type-and-room`, { params: { boligmappaNumber } })
      .then(({ data }) => {
        commit("setTypesAndTags", data);
      })
      .catch(error => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async uploadFile({ commit }, { boligmappaNumber, body }) {
    commit("error", false);
    commit("uploading", true);
    return axios
      .post(`${apiUrl}boligmappa/plant/${boligmappaNumber}/file`, body)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        commit("error", true);
        throw new Error(error);
      })
      .finally(() => {
        commit("uploading", false);
      });
  },
  resetState({ commit }) {},
};

const mutations = {
  setTypesAndTags(state, typesAndTags) {
    state.documentTypes = typesAndTags.documentTypes;
    state.professionTypes = typesAndTags.professionTypes;
    state.rooms = typesAndTags.rooms;

    if (Array.isArray(typesAndTags.chapterTags)) {
      state.chapterTags = typesAndTags.chapterTags.filter(i => i.id !== 1);
    }
  },
  addRoom(state, room) {
    state.rooms.push(room);
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  uploading(state, isUploading) {
    state.isUploading = isUploading;
  },
  error(state, hasError) {
    state.hasError = hasError;
  },
};

export const boligmappaFileUpload = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
