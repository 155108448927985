import { normalizeHistoryLog } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  items: [],
  isLoading: false,
  isLoadingMore: false,
  canLoadMore: false,
  offset: 0,
});

const getters = {};

const actions = {
  async getHistory({ commit, state }, { id, offset, limit = 10, model = [] }) {
    commit("setOffset", limit + offset);
    if (offset > 0) {
      commit("loadingMore", true);
    } else {
      commit("loading", true);
    }
    return axios
      .get(`${apiUrl}audit/${id}`, {
        params: { model, limit, offset },
      })
      .then(({ data }) => {
        let items = normalizeHistoryLog(data);

        if (offset > 0) {
          let joinedItems = [...state.items, ...items];
          joinedItems = [...new Set(joinedItems.map((i) => i.id))].map((id) =>
            joinedItems.find((i) => i.id === id),
          );

          commit("setItems", joinedItems);
        } else {
          commit("setItems", items);
        }

        commit("loadMore", data.hasMore);
        commit("loading", false);
        commit("loadingMore", false);
      })
      .catch((error) => {
        commit("loading", false);
        commit("loadingMore", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setOffset(state, offset) {
    state.offset = offset;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  loadingMore(state, isLoadingMore) {
    state.isLoadingMore = isLoadingMore;
  },
  loadMore(state, canLoadMore) {
    state.canLoadMore = canLoadMore;
  },
};

export const history = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
