import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function generateProjectSystemFolders({ projectId }) {
  return axios
    .put(`${apiUrl}project/${projectId}/systemfolders`)
    .then(({ data }) => {
      const project = data ? data.project : null;
      return project;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function decoupleBoligmappaAddress({ projectId }) {
  return axios
    .delete(`${integrationApiUrl}boligmappa/decouple`, {
      data: { projectId },
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function getProjectDetails({ projectIds, fields }) {
  return axios
    .post(`${apiUrl}project/details`, { ids: projectIds, fields })
    .then(({ data }) => {
      const details = data.details;
      return details;
    });
}
