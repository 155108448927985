<template>
  <AppMenuItem
    :text="$t('users.action.setInactive')"
    :icon="$icon.REGULAR.COMMON.USER_SLASH"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
