import { REGULAR } from "../icons";
import { ROUTE_NAMES } from "../routeNames";

export const CONTACT_USER_ROUTE_NAMES = [
  ROUTE_NAMES.TASK.ROOT,
  ROUTE_NAMES.FORM.ROOT,
  ROUTE_NAMES.TIME_REGISTRATION.ROOT,
  ROUTE_NAMES.PROJECT.ROOT,
];

export const VALID_LANDING_PAGE_ROUTE_NAMES_AND_ICONS = [
  { name: ROUTE_NAMES.DASHBOARD.ROOT, icon: REGULAR.ROUTE.DASHBOARD },
  { name: ROUTE_NAMES.TASK.ROOT, icon: REGULAR.ROUTE.TASK },
  { name: ROUTE_NAMES.FORM.ROOT, icon: REGULAR.ROUTE.FORM },
  { name: ROUTE_NAMES.TIME_REGISTRATION.ROOT, icon: REGULAR.ROUTE.TIME_REGISTRATION },
  { name: ROUTE_NAMES.PROJECT.ROOT, icon: REGULAR.ROUTE.PROJECT },
  { name: ROUTE_NAMES.STORAGE.ROOT, icon: REGULAR.ROUTE.STORAGE },
  { name: ROUTE_NAMES.CONTACT.ROOT, icon: REGULAR.ROUTE.CONTACT },
  { name: ROUTE_NAMES.EMPLOYEE.ROOT, icon: REGULAR.ROUTE.USER },
];
