<template>
  <AppTaskDetailsDrawer
    :task="task"
    :isLoading="isLoading"
    :isUpdating="isUpdating"
    @task:update="updateTask"
    @task:delete="onTaskDelete"
    @form:update="$emit('form:update', $event)"
    @form:create="$emit('form:create', $event)"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    taskId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    ...mapState("formTask", {
      task: (state) => state.task,
      isLoading: (state) => state.isLoading,
      isUpdating: (state) => state.isUpdating,
    }),
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    async onTaskDelete(e) {
      await this.$store.dispatch("formTask/deleteTask", { id: this.taskId });
      this.$emit("task:delete", e);
    },
    getTask() {
      this.$store.dispatch("formTask/getTask", { id: this.taskId });
    },
    async updateTask({ data, task }) {
      await this.$store.dispatch("formTask/updateTask", {
        id: task?.id,
        data,
      });

      this.$emit("task:update", { data, task });
    },
  },
  mounted() {
    this.getTask();
  },
};
</script>
