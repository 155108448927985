import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, domainGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { LANDING, NEW_WORKSPACE } = ROUTE_NAMES.GET_STARTED;

export const getStartedRoutes = [
  {
    path: "/get-started/landing",
    name: LANDING,
    component: () =>
      import(/* webpackChunkName: "getStarted" */ "../../pages/GetStarted/LandingPage"),
    beforeEnter: multiguard([authGuard, domainGuard]),
  },
  {
    path: "/get-started/new-workspace",
    name: NEW_WORKSPACE,
    component: () =>
      import(/* webpackChunkName: "getStarted" */ "../../pages/GetStarted/SignupPage"),
    beforeEnter: multiguard([authGuard, domainGuard]),
  },
];
