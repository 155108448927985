export const WOPI_ACCESS_TOKEN_TTL_OFFSET = 36000000; // 10 hours (this is the recommended ttl according to the docs)
export const OFFICE_WORD_EXTENSIONS = [
  "doc",
  "docx",
  "docm",
  "dot",
  "dotx",
  "dotm",
  "odt",
];
export const OFFICE_EXCEL_EXTENSIONS = [
  "xls",
  "xlsx",
  "xlsm",
  "xlsb",
  "xltx",
  "xltm",
  "xlt",
  "ods",
];
export const OFFICE_POWERPOINT_EXTENSIONS = [
  "ppt",
  "pptx",
  "pptm",
  "pot",
  "potx",
  "potm",
  "pps",
  "ppsx",
  "ppsm",
  "odp",
];
