<template>
  <div class="p-relative">
    <div class="label--small">
      {{ $t(labelKey) | capitalize }}
    </div>
    <AppDefaultAutocomplete
      v-model="localValue"
      :items="items"
      :loading="loading"
      :noDataText="$t('common.noData')"
      :item-value="itemValue"
      :item-text="itemText"
      :user-avatar="userAvatar"
      multiple
      clearable
    />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    items: Array,
    loading: Boolean,
    userAvatar: Boolean,
    value: Array,
    itemValue: String,
    itemText: String,
    userAvatar: Boolean,
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
