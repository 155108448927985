<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">{{
        $t("common.overview")
      }}</v-toolbar-title>
      <v-spacer />
      <AppDefaultTooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="taskDialog = true" icon v-bind="attrs" v-on="on">
            <v-icon>
              {{ $icon.LIGHT.ACTION.EXPAND }}
            </v-icon>
          </v-btn>
        </template>
        <span class="break-word">
          {{ $t("taskManagement.menu.details.fullscreenButtonTooltip") }}
        </span>
      </AppDefaultTooltip>
      <div class="pl-1"></div>
      <AppToggleMenuBtn>
        <AppMenuCopyUrlBtn
          :route="{
            name: this.$routeNames.TASK_VIEW.ROOT,
            params: { taskId: this.task?.id },
          }"
        />
        <AppMenuDeleteBtn :disabled="!canEdit" @click="onDeleteTaskClick" />
      </AppToggleMenuBtn>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="pa-5 pb-0 secondary-height overflow-y-auto" v-if="!isLoading && task">
      <AppTask
        :task="task"
        @task:update="$emit('task:update', $event)"
        :isUpdating="isUpdating"
      />
    </div>
    <AppTaskViewDialog
      v-model="taskDialog"
      :task="task"
      :isUpdating="isUpdating"
      @task:update="$emit('task:update', $event)"
      @form:update="$emit('form:update', $event)"
      @form:create="$emit('form:create', $event)"
    />
    <AppDeleteConfirmationDialog
      v-model="deleteDialog"
      @delete="deleteTask"
      skip-validation
      :title="$t('taskManagement.deleteConfirmation.title')"
      :subtitle="$t('taskManagement.deleteConfirmation.description')"
    />
  </div>
</template>

<script>
export default {
  props: {
    task: Object,
    isLoading: Boolean,
    isUpdating: Boolean,
  },
  data() {
    return {
      taskDialog: false,
      deleteDialog: false,
    };
  },
  computed: {
    canEdit() {
      return this.task?.permissions?.canEdit;
    },
  },
  methods: {
    onDeleteTaskClick() {
      this.deleteDialog = true;
    },
    deleteTask() {
      this.$emit("task:delete", { task: this.task });
    },
  },
};
</script>
