import { normalizeProjectMembers } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  members: [],
  isLoading: false,
  filter: null,
});

const getters = {};

const actions = {
  async getMembers({ state, commit }, { id, filter }) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}project/${id}/members`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const members = data?.members;
        const normalizedMembers = normalizeProjectMembers(members);

        commit("setMembers", normalizedMembers);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  /*async createMember({ state, dispatch }, body) {
    return axios
      .post(`${apiUrl}member`, { ...body })
      .then(({ data }) => {
        dispatch("getMembers", state.filter);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateMember({ commit }, { id, body }) {
    return axios
      .put(`${apiUrl}member/${id}`, { ...body })
      .then(({ data }) => {
        const member = data?.member;
        if (member) {
          commit("updateMember", member);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteMember({ commit }, id) {
    return axios
      .delete(`${apiUrl}member/${id}`)
      .then(async ({ data }) => {
        const member = data?.member;
        if (member) {
          commit("removeMember", member);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },*/
};

const mutations = {
  setMembers(state, members) {
    state.members = members;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  /*updateMember(state, member) {
    if (!member) throw new Error("No member");
    const index = state.members.findIndex((x) => x.id === member.id);
    if (index !== -1) {
      state.members.splice(index, 1, member);
    }
  },
  removeMember(state, member) {
    if (!member) throw new Error("No member");
    const index = state.members.findIndex((x) => x.id === member.id);
    if (index !== -1) {
      state.members.splice(index, 1);
    }
  },*/
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const projectMembers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
