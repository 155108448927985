<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card>
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("fileManagement.preview.navMenu.e-signature.requestDialog.title") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn class="mr-2" @click="sendToSign" depressed color="primary">{{
          $t("fileManagement.preview.navMenu.e-signature.requestDialog.sendButton")
        }}</v-btn>
        <v-btn icon dark @click="close">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="main-height d-flex overflow-y-auto">
        <iframe
          ref="embeddedRequestIframe"
          class="w-100"
          frameBorder="0"
          :src="src"
        ></iframe>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    src: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.iframeRecieveMessageListener();
      } else {
        this.clearIframeRecieveMessageListener();
      }
    },
  },
  methods: {
    close() {
      this.localDialog = false;
    },
    sendToSign() {
      const embeddedRequestIframe = this.$refs.embeddedRequestIframe;
      embeddedRequestIframe.contentWindow.postMessage("onSendClick", "*");
    },
    iframeRecieveMessageListener() {
      window.addEventListener("message", this.iframeRecieveMessage);
    },
    clearIframeRecieveMessageListener() {
      window.removeEventListener("message", this.iframeRecieveMessage);
    },
    iframeRecieveMessage(event) {
      if (event?.data === "onCreateSuccess") {
        this.$emit("afterDocumentSentToSign");
        this.close();
      }
    },
  },
  destroyed() {
    this.clearIframeRecieveMessageListener();
  },
};
</script>
