<template>
  <AppMenuItem
    :text="$t('common.copyUrl')"
    :icon="$icon.REGULAR.ACTION.LINK"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    route: Object,
  },
  methods: {
    click() {
      this.$emit("click");
      const taskUrl = this.$router.resolve(this.route).href;

      const fullPath = `${window.location.origin}${taskUrl}`;

      navigator.clipboard.writeText(fullPath);

      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t("common.copied"),
        color: "success",
      });
    },
  },
};
</script>
