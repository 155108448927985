<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background">
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("fileManagement.cmsDocumentTemplatePicker.title") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="localDialog = false">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="main-height d-flex overflow-y-auto">
        <div class="page-container">
          <div class="pb-4 d-flex align-center">
            <div class="d-flex">
              <AppDefaultTextField
                class="mw-250px mr-2"
                :label="$t('common.search')"
                :append-icon="$icon.LIGHT.ACTION.SEARCH"
                :value="filter.search"
                @input="debounceSearch"
                hide-details
                autofocus
                background-color="white"
                clearable
              />
              <AppDefaultSelect
                class="mw-250px"
                v-model="filter.category"
                :items="categories"
                :loading="isCategoriesLoading"
                :label="$t('common.category')"
                hide-details
                item-text="text"
                item-value="value"
                background-color="white"
              />
            </div>
            <v-spacer />
            <v-btn
              @click="onAddTemplateClick"
              color="primary"
              depressed
              :disabled="selectedTemplates.length <= 0"
              :loading="isSaving"
            >
              {{ $t("fileManagement.cmsDocumentTemplatePicker.saveBtn") }}
            </v-btn>
          </div>
          <AppDataTable
            v-model="selectedTemplates"
            :loading="isLoading"
            :headers="_headers"
            :items="templates"
            :noDataText="$t('common.noTemplates')"
            show-select
          >
            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-checkbox
                class="mt-0"
                :value="props.value"
                :indeterminate="props.indeterminate"
                @click="on.input(!props.value)"
                :on-icon="$icon.LIGHT.COMMON.SQUARE_CHECK"
                :indeterminate-icon="$icon.LIGHT.COMMON.SQUARE_MINUS"
                color="success"
                hide-details
              />
            </template>
            <template v-slot:item="{ item, isSelected, select }">
              <tr
                class="list_tr"
                :class="item.id ? (isSelected ? 'list_tr--selected-success' : '') : ''"
              >
                <td>
                  <v-checkbox
                    class="mt-0"
                    :input-value="isSelected"
                    @click="select(!isSelected)"
                    :on-icon="$icon.LIGHT.COMMON.SQUARE_CHECK"
                    color="success"
                    hide-details
                  />
                </td>
                <td class="text-start">
                  <div class="d-flex align-center">
                    <span>{{ item.name }}</span>
                    <v-spacer />
                    <v-btn icon @click="downloadItem(item)" :loading="item.isDownloading">
                      <v-icon>{{ $icon.LIGHT.ACTION.DOWNLOAD }}</v-icon>
                    </v-btn>
                  </div>
                </td>
                <td>
                  <AppColorChip
                    v-if="item.category"
                    :text="
                      $te(`cms.template.document.category.${item.category.name}`)
                        ? $t(`cms.template.document.category.${item.category.name}`)
                        : item.category.name
                    "
                    color="info--text"
                  />
                </td>

                <td>
                  {{ new Date(item.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
                </td>
                <td>
                  <v-btn
                    icon
                    color="error"
                    @click="onDeleteItem(item)"
                    :disabled="!item.storage"
                    :loading="item.isDeleting"
                  >
                    <v-icon>
                      {{ $icon.LIGHT.ACTION.DELETE }}
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </AppDataTable>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    group: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      filter: {
        search: null,
        category: null,
      },
      searchDebounce: null,
      selectedTemplates: [],
    };
  },
  computed: {
    ...mapGetters("cmsDocumentTemplateLibrary", {
      alreadyImportedTemplates: "alreadyImportedTemplates",
    }),
    ...mapState("cmsDocumentTemplateLibrary", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
      isSaving: (state) => state.isSaving,
    }),
    ...mapState("documentTemplateLibraryCategory", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$options.filters.capitalize(
            this.$t("templateManagement.templateName"),
          ),
          value: "name",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.category")),
          width: "15%",
          value: "category.name",
          show: true,
        },
        {
          text: this.$t("common.lastUpdated"),
          width: "15%",
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          width: "8%",
          sortable: false,
          show: true,
        },
      ];
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getCategories();
          this.getTemplates(this.filterArgs());
        }
      },
    },
    filter: {
      handler(filter) {
        this.getTemplates(this.filterArgs(filter));
      },
      deep: true,
    },
    alreadyImportedTemplates: {
      handler(templates) {
        if (Array.isArray(templates)) {
          this.selectedTemplates = templates;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getTemplates(args) {
      this.$store.dispatch("cmsDocumentTemplateLibrary/getTemplates", args);
    },
    getCategories() {
      this.$store.dispatch(
        "documentTemplateLibraryCategory/getCMSCategories",
        this.group,
      );
    },
    async onDeleteItem(item) {
      await this.$store.dispatch("cmsDocumentTemplateLibrary/deleteItem", item);
      this.getTemplates(this.filterArgs());
    },
    async onAddTemplateClick() {
      const selectedIds = this.selectedTemplates.map((x) => x.id);

      const body = {
        ids: selectedIds,
      };
      const params = {
        group: this.group,
      };
      await this.$store.dispatch("cmsDocumentTemplateLibrary/saveTemplates", {
        body,
        params,
      });
      this.localDialog = false;
    },
    downloadItem(item) {
      this.$store.dispatch("cmsDocumentTemplateLibrary/downloadItem", item);
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },
    filterArgs(args) {
      return {
        ...this.filter,
        ...args,
        group: this.group,
      };
    },
  },
};
</script>
