<template>
  <AppDefaultSelect
    :items="languages"
    :placeholder="$t('settings.workspaces.workspaceCommon.selectLanguage')"
    v-model="localValue"
    :rules="$rules.REQUIRED"
    :clearable="false"
    :backgroundColor="backgroundColor"
  >
    <template v-slot:[`prepend-inner`]>
      <div :class="`vti__flag ${flagCode}`" v-if="flagCode"></div>
    </template>
    <template v-slot:item="{ item }">
      <div class="d-flex align-center">
        <div :class="`vti__flag ${item.flagCode}`"></div>
        <span class="pl-2">{{ item.text }}</span>
      </div>
    </template>
  </AppDefaultSelect>
</template>

<script>
export default {
  props: {
    value: String,
    rules: Array,
    backgroundColor: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    flagCode() {
      return this.languages.find((l) => l.value === this.localValue)?.flagCode || null;
    },
  },
  data() {
    return {
      languages: [
        { text: "Norsk", value: "nb", flagCode: "no" },
        { text: "English", value: "en", flagCode: "gb" },
      ],
    };
  },
};
</script>
