<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card v-if="dialog">
      <v-card-title>
        <span class="headline">
          {{ title ? title : $t("project.newProject.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="newProjectForm"
          v-model="project.valid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative" v-if="!isEditMode">
            <div class="label--small d-flex align-end">
              <div class="pr-1">{{ $t("project.newProject.projectTemplate") }}</div>
              <AppStonlyGuideBtn guideId="AfTKiIuPV1" />
              <v-spacer />
              <AppLinkText
                underline-on-hover
                v-if="project.templateId.value"
                @click="previewDialiog.active = true"
              >
                {{ $t("project.newProject.previewProjectTemplate") }}
              </AppLinkText>
            </div>
            <AppDefaultAutocomplete
              :items="templates"
              v-model="project.templateId.value"
              :loading="isTemplatesLoading"
              item-text="name"
              item-value="id"
              :noDataText="$t('common.noTemplates')"
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("project.list.projectName") }}
            </div>
            <AppDefaultTextField
              v-model="project.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
              autofocus
            />
          </div>
          <AppFieldMenuDatePicker
            class="pb-5"
            :label="$t('projectView.info.startDate')"
            hide-details
            :max="project.endDate.value"
            v-model="project.startDate.value"
          />
          <AppFieldMenuDatePicker
            class="pb-5"
            :label="$t('projectView.info.estimatedEndDate')"
            hide-details
            :min="project.startDate.value"
            :defaultPickerDate="project.startDate.value"
            v-model="project.endDate.value"
          />
          <div>
            <div class="d-flex align-end">
              <div class="label--small required-asterisk">
                {{ $t("common.status") }}
              </div>
              <v-spacer />
              <AppSettingsBtn
                @click.stop="onEditStatusesClick"
                :tooltip="statusMixins_tooltip('project')"
              />
            </div>
            <AppChangeCustomStatusAutocomplete
              v-if="dialog"
              v-model="project.statusId.value"
              :rules="project.statusId.rules"
              model="project"
              :shouldSetDefaultValue="!isEditMode"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("contact.client.type.customer") | capitalize }}
            </div>
            <AppClientAutocomplete
              v-model="project.clientId.value"
              isReadyToInitialFetch
              :type="this.$constant.CUSTOMER"
              :queryParams="{
                active: true,
                alwaysIncludeIds: [data?.clientId],
              }"
              @created="onClientCreated"
            />
          </div>
          <AppProjectAutocompleteWithLabel
            v-model="project.parentId.value"
            :idsToExclude="[data?.id]"
            isReadyToInitialFetch
            :queryParams="{
              alwaysIncludeIds: [project.parentId.value],
            }"
            type="mainProject"
            @created="onMainProjectCreated"
          />
          <div class="p-relative">
            <div class="label--small">
              {{ $t("project.list.projectManager") }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="project.manager.value"
              :items="assignableProjectLeaders"
              :loading="isAssignableProjectLeadersLoading"
              user-avatar
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.teams") | capitalize }}
            </div>
            <AppDefaultAutocomplete
              v-model="project.teams.values"
              :items="teams"
              :loading="isTeamsLoading"
              itemText="name"
              multiple
              clearable
              @change="onTeamChange"
              :noDataText="$t('common.noTeams')"
              select-all
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("common.members") | capitalize }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="project.members.values"
              :items="assignableProjectMembers"
              :loading="isAssignableProjectMembersLoading"
              user-avatar
              multiple
              clearable
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
              select-all
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.permissionLevel.title") | capitalize }}
            </div>
            <v-radio-group class="mt-0" v-model="project.permissionLevel.value" row>
              <v-radio
                v-for="(level, i) in permissionLevels"
                :key="i"
                :label="level.text"
                :value="level.value"
              />
            </v-radio-group>
          </div>

          <AppErrorAlert v-if="errorMessage">
            {{ errorMessage }}
          </AppErrorAlert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="close"
          :loading="isSubmitting"
          :confirmText="submitBtnText ? submitBtnText : $t('project.newProject.create')"
        />
      </v-card-actions>
      <AppProjectTemplatePreviewDialog
        v-if="!isEditMode"
        v-model="previewDialiog.active"
        :templateId="project.templateId.value"
      />
      <AppEditCustomStatusDialog v-model="editStatusDialog.active" model="project" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { statusMixins } from "@/helpers/mixins";
import moment from "moment";

export default {
  mixins: [statusMixins],
  props: {
    dialog: Boolean,
    title: String,
    submitBtnText: String,
    data: Object,
    defaultValue: Object,
    parentId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isSubmitting: false,
      assignableProjectLeaders: [],
      assignableProjectMembers: [],
      isAssignableProjectLeadersLoading: false,
      isAssignableProjectMembersLoading: false,
      errorMessage: "",
      project: {
        valid: false,
        name: {
          maxCharacters: this.$rules.MAX_CHARACTERS,
          value: "",
          rules: [
            (v) => !!v || this.$t("common.required"),
            (v) => (v && !!v.trim()) || this.$t("common.required"),
            (v) =>
              v
                ? v.length <= this.project.name.maxCharacters ||
                  this.$t("common.maxCharacters", {
                    amount: this.project.name.maxCharacter,
                  })
                : "",
          ],
        },
        clientId: {
          value: null,
        },
        parentId: {
          value: null,
        },
        manager: {
          value: null,
        },
        teams: {
          values: [],
        },
        members: {
          values: [],
        },
        templateId: {
          value: null,
        },
        startDate: {
          value: null,
        },
        endDate: {
          value: null,
        },
        statusId: {
          value: null,
          rules: this.$rules.REQUIRED,
        },
        permissionLevel: {
          value: "PUBLIC",
        },
      },
      permissionLevels: [
        { text: this.$t("common.permissionLevel.public"), value: "PUBLIC" },
        { text: this.$t("common.permissionLevel.restricted"), value: "RESTRICTED" },
        { text: this.$t("common.permissionLevel.private"), value: "PRIVATE" },
      ],
      previewDialiog: {
        active: false,
      },
      editStatusDialog: {
        active: false,
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
    }),
    ...mapState("selectableTeams", {
      teams: (state) => state.teams,
      isTeamsLoading: (state) => state.isLoading,
    }),
    ...mapState("selectableProjectTemplates", {
      templates: (state) => state.templates,
      isTemplatesLoading: (state) => state.isLoading,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    isEditMode() {
      return !!this.data;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData();
          this.getAssignableProjectLeaders();
          this.getAssignableProjectMembers();
          this.getTeams();
          this.getTemplates();
        }
      },
    },
  },
  methods: {
    fillFormWithData() {
      const data = this.data;
      if (this.parentId) {
        this.project.parentId.value = this.parentId;
      }
      if (data) {
        this.project.name.value = data.name;
        this.project.startDate.value = data.startDate;
        this.project.endDate.value = data.endDate;
        this.project.clientId.value = data.clientId;
        this.project.parentId.value = data.parentId;
        this.project.manager.value = data.ownerId;
        this.project.permissionLevel.value = data.permissionLevel;
        this.project.members.values = Array.isArray(data.members)
          ? data.members.map((member) => member.id)
          : [];
        this.project.teams.values = Array.isArray(data.teams)
          ? data.teams.map((team) => team.id)
          : [];
        this.project.statusId.value = data.statusId;
      } else {
        if (!this.project.permissionLevel.value) {
          this.project.permissionLevel.value = "PUBLIC";
        }
        if (!this.project.manager.value) {
          this.project.manager.value = this.currentUserId;
        }
        this.setDefaultValue();
      }
    },
    onMainProjectCreated({ project }) {
      this.project.parentId.value = project.id;
    },
    allowedEndDates(date) {
      const startDate = this.project.startDate.value;
      if (!startDate) {
        return true; // Allow all dates if start date is not set
      }
      return moment(date).isSameOrAfter(moment(startDate), "day");
    },
    setDefaultValue() {
      if (!this.defaultValue) return;
      this.setDefaultStartDate();
    },
    setDefaultStartDate() {
      this.project.startDate.value = this.defaultValue.startDate;
    },
    removeManager(item) {
      this.project.manager.value = null;
    },
    removeMember(item) {
      const index = this.project.members.values.indexOf(item.id);
      if (index >= 0) this.project.members.values.splice(index, 1);
    },
    removeTeam(item) {
      const index = this.project.teams.values.indexOf(item.id);
      if (index >= 0) this.project.teams.values.splice(index, 1);
    },
    onTeamChange(teamIds) {
      let memberIds = this.getMembersByTeams(teamIds);
      for (const memberId of memberIds) {
        const hasMember = this.project.members.values.find((id) => id === memberId);
        if (!hasMember) {
          this.project.members.values.push(memberId);
        }
      }
    },
    getMembersByTeams(teamIds) {
      let ids = [];
      for (const teamId of teamIds) {
        const team = this.teams.find((i) => i.id === teamId);
        const memberIds = Array.isArray(team?.members)
          ? team.members.map((i) => i.id)
          : [];
        ids = [...new Set([...ids, ...memberIds])];
      }
      return ids;
    },
    onEditStatusesClick() {
      this.editStatusDialog.active = true;
    },
    onClientCreated({ client }) {
      this.project.clientId.value = client.id;
    },
    getAssignableProjectLeaders() {
      this.isAssignableProjectLeadersLoading = true;

      const params = {
        projectId: this.data?.id,
        isAssignableAsProjectLeader: true,
        alwaysIncludeIds: [this.data?.ownerId],
      };

      getSearchUsers({ params })
        .then((users) => {
          this.assignableProjectLeaders = users;
        })
        .finally(() => {
          this.isAssignableProjectLeadersLoading = false;
        });
    },
    getAssignableProjectMembers() {
      this.isAssignableProjectMembersLoading = true;
      const memberIds = this.data?.members.map((member) => member.id) ?? [];
      const params = {
        projectId: this.data?.id,
        isAssignableAsProjectMember: true,
        alwaysIncludeIds: [...memberIds],
      };
      getSearchUsers(params)
        .then((users) => {
          this.assignableProjectMembers = users;
        })
        .finally(() => {
          this.isAssignableProjectMembersLoading = false;
        });
    },
    getTeams() {
      this.$store.dispatch("selectableTeams/getTeams");
    },
    getTemplates() {
      this.$store.dispatch("selectableProjectTemplates/getTemplates");
    },
    createOrUpdateProject(data) {
      const storeType = this.isEditMode
        ? "projects/updateProject"
        : "projects/createProject";
      this.isSubmitting = true;
      this.errorMessage = "";
      this.$store
        .dispatch(storeType, data)
        .then((project) => {
          this.afterSubmit(project);
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    afterSubmit(project) {
      this.$emit("confirm", { project });
      this.$refs.newProjectForm.reset();
      this.project.members.values = [];
      this.project.manager.value = null;
      this.project.teams.values = [];
      this.project.templateId.value = null;
      this.project.clientId.value = null;
      this.project.statusId.value = null;
      this.project.parentId.value = null;
      this.localDialog = false;
    },
    onSubmit() {
      const valid = this.$refs.newProjectForm.validate();
      if (valid) {
        const data = {
          id: this.data?.id,
          body: {
            name: this.project.name.value,
            clientId: this.project.clientId.value,
            ownerId: this.project.manager.value,
            members: this.project.members.values,
            teams: this.project.teams.values,
            permissionLevel: this.project.permissionLevel.value,
            templateId: this.project.templateId.value,
            startDate: this.project.startDate.value,
            endDate: this.project.endDate.value,
            statusId: this.project.statusId.value,
            parentId: this.project.parentId.value,
          },
        };
        this.createOrUpdateProject(data);
      }
    },
    close() {
      this.localDialog = false;
      this.$refs.newProjectForm.resetValidation();
    },
  },
};
</script>
