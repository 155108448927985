<template>
  <div>
    <div class="p-relative">
      <div class="d-flex align-end" style="height: 40px">
        <div class="label--small required-asterisk">
          {{
            $t(`timeRegistration.newTimeEntry.${isExtra ? "extraHourType" : "hourType"}`)
              | capitalize
          }}
        </div>
        <v-btn
          v-if="showDelete"
          small
          icon
          color="error"
          @click="$emit('delete')"
          class="ml-2"
          style="margin-bottom: 2px"
        >
          <v-icon small>{{ $icon.REGULAR.ACTION.DELETE }}</v-icon>
        </v-btn>
        <v-spacer />
        <AppDefaultSwitch
          class="mt-0 font-weight-regular mb-2 pt-0"
          @change="toggleUseTime"
          :input-value="timeEntry.useTime"
          :label="$t('timeRegistration.newTimeEntry.useTime')"
          :inset="true"
        />
      </div>
      <AppDefaultSelect
        v-if="dialog"
        :value="timeEntry.hourTypeId"
        :rules="$rules.REQUIRED"
        :items="filteredHourTypes"
        @change="onTimeEntryChange('hourTypeId', $event)"
        :loading="isHourTypesLoading"
        item-text="name"
        item-value="id"
      />
    </div>
    <AppAccountingTimeEntryProductAutocomplete
      :value="timeEntry.accountingProductId"
      @change="onTimeEntryChange('accountingProductId', $event)"
    />
    <div class="d-flex w-100">
      <template v-if="timeEntry.useTime">
        <AppFieldMenuTimePicker
          class="w-100 pb-5"
          :label="$t('common.from')"
          :value="timeEntry.fromTime"
          @change="onTimeEntryChange('fromTime', $event)"
          :rules="fromTimeRules"
          @blur="calculateDuration"
          :clearable="false"
          hide-details
        />
        <div class="pr-4"></div>
        <AppFieldMenuTimePicker
          class="w-100 pb-5"
          :label="$t('common.to')"
          :value="timeEntry.toTime"
          @change="onTimeEntryChange('toTime', $event)"
          :rules="toTimeRules"
          @blur="calculateDuration"
          :clearable="false"
          hide-details
        />
      </template>
      <div class="w-100" v-else>
        <AppWorkHoursTextField
          :value="timeEntry.duration"
          @input="onTimeEntryChange('duration', $event)"
          :rules="$rules.REQUIRED"
          :label="$t('timeRegistration.newTimeEntry.howLongDidYouWork') | capitalize"
          required
          :durationShortcuts="[1800, 3600, 27000, 28800]"
        />
      </div>
    </div>
    <div v-if="timeEntry.useTime && !isExtra">
      <div class="pb-5">
        <AppWorkHoursTextField
          :value="timeEntry.breakDuration"
          @input="onTimeEntryChange('breakDuration', $event)"
          hide-details
          :label="$t('timeRegistration.newTimeEntry.break') | capitalize"
          :durationShortcuts="[900, 1800, 2700, 3600, 4500, 5400]"
        />
      </div>
    </div>

    <div class="p-relative">
      <div class="label--small">
        {{ $t("timeRegistration.newTimeEntry.comment") }}
      </div>
      <AppDefaultTextarea
        v-if="dialog"
        :value="timeEntry.comment"
        @change="onTimeEntryChange('comment', $event)"
        hide-details
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHourTypesLoading: Boolean,
    timeEntry: Object,
    durationRules: Array,
    fromTimeRules: Array,
    toTimeRules: Array,
    hourTypes: Array,
    isEditMode: Boolean,
    isExtra: Boolean,
    showDelete: Boolean,
    dialog: Boolean,
    projectId: String,
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.setup();
        }
      },
    },
    filteredHourTypes: {
      handler() {
        this.setDefaultHourTypeId();
      },
    },
  },
  computed: {
    lsKey() {
      if (this.isExtra) return "extraTimeEntry:durationType";
      return "timeEntry:durationType";
    },
    filteredHourTypes() {
      const extraFilteredItems = this.hourTypes.filter((hourType) => {
        const isExtra = hourType.isExtra === this.isExtra;
        return isExtra;
      });

      const internalFilteredItems = extraFilteredItems.filter((hourType) => {
        return hourType.isInternal !== !!this.projectId;
      });

      return internalFilteredItems.sort();
    },
  },
  methods: {
    setup() {
      this.setDefaultHourType();
      this.setDefaultUseTime();
    },
    setDefaultHourType() {
      if (this.filteredHourTypes.length > 0) {
        this.$nextTick(() => {
          this.setDefaultHourTypeId();
        });
      }
    },
    setDefaultHourTypeId() {
      if (this.isEditMode && this.timeEntry?.hourTypeId) return;
      if (this.filteredHourTypes?.length === 1) {
        this.onTimeEntryChange("hourTypeId", this.filteredHourTypes[0].id);
      }
    },
    onTimeEntryChange(field, value) {
      this.$emit("timeEntryActivity:change", { field, value });
    },
    calculateDuration() {
      if (this.timeEntry.fromTime && this.timeEntry.toTime) {
        const duration = this.calculateTimeDifferenceInSeconds(
          this.timeEntry.fromTime,
          this.timeEntry.toTime,
        );
        this.onTimeEntryChange("duration", duration);
      }
    },
    setDefaultUseTime() {
      if (!this.isEditMode) {
        const useTime = this.getStoredUseTime();
        this.onTimeEntryChange("useTime", useTime);
      }
    },
    getStoredUseTime() {
      return JSON.parse(localStorage.getItem(this.lsKey));
    },
    toggleUseTime() {
      const useTime = this.timeEntry.useTime;

      this.onTimeEntryChange("useTime", !useTime);
      localStorage.setItem(this.lsKey, JSON.stringify(!useTime));
    },
    calculateTimeDifferenceInSeconds(time1, time2) {
      // Split the time strings into hours and minutes
      const [hours1, minutes1] = time1.split(":").map(Number);
      const [hours2, minutes2] = time2.split(":").map(Number);

      // Calculate the total minutes for each time
      const totalMinutes1 = hours1 * 60 + minutes1;
      const totalMinutes2 = hours2 * 60 + minutes2;

      // Calculate the time difference in minutes
      let timeDifferenceMinutes;

      if (totalMinutes2 >= totalMinutes1) {
        timeDifferenceMinutes = totalMinutes2 - totalMinutes1;
      } else {
        timeDifferenceMinutes = totalMinutes2 + (24 * 60 - totalMinutes1);
      }

      // Convert the time difference to seconds
      const timeDifferenceSeconds = timeDifferenceMinutes * 60;

      return timeDifferenceSeconds;
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
