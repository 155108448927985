import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import i18n from "../../../plugins/i18n";
import { normalizeUsers } from "@/helpers/normalization";

const state = () => ({
  users: [],
  isLoading: false,
  filter: null,
});

const getters = {
  assignableUsers: (state) => {
    const users = state.users.map((user) => ({
      ...user,
      text: user.fullName ? user.fullName : user.email,
    }));

    const unassigned = {
      id: null,
      text: i18n.t("common.unassigned"),
    };
    return [unassigned, ...users];
  },
};

const actions = {
  async getUsers({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}user`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const normalizedUsers = normalizeUsers(data);
        commit("setUsers", normalizedUsers);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
