<template>
  <div class="d-flex">
    <!-- <AppHelperTextChip
      v-if="storage.isLocked"
      :icon="$icon.LIGHT.COMMON.LOCK"
      :text="$t('fileManagement.fileExplorer.node.locked')"
    /> -->
    <!-- <AppHelperTextChip
      v-if="storage.permissionLevel"
      :icon="storageMixins_getPermissionLevelIconName(storage.permissionLevel)"
      :text="
        $t(
          `fileManagement.fileExplorer.permissionLevel.${storage.permissionLevel.toLowerCase()}`,
        )
      "
    /> -->
    <AppHelperTextChip
      v-if="storage.type === $constant.FILE_RELATION"
      :icon="$icon.LIGHT.FILE.FILE_LINKED"
      :text="$t('fileManagement.fileExplorer.node.linked')"
      :tooltip="$t('fileManagement.linkedFile.tooltipShort')"
    />
    <AppHelperTextChip
      v-if="getStorageLookupByIntegrationId('BOLIGMAPPA')"
      iconSize="small"
      icon="$boligmappa"
      :tooltip="$t('fileManagement.fileExplorer.infoRow.boligmappa.tooltip')"
    />
  </div>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  props: {
    storage: Object,
  },
  methods: {
    getStorageLookupByIntegrationId(integrationId) {
      if (!Array.isArray(this.storage.lookup)) return null;
      const lookup = this.storage.lookup.find((i) => (i.integrationId = integrationId));
      if (!lookup || !lookup?.integrationId) return null;
      return lookup.integrationId;
    },
  },
};
</script>
