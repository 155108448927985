<template>
  <div>
    <div class="label--small" :class="required ? 'required-asterisk' : ''">
      {{ label | capitalize }}
    </div>
    <v-menu
      ref="menu"
      v-model="timeMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-layout v-on="on" v-bind="attrs">
          <AppDefaultTextField
            class="date-field-cursor-pointer"
            v-model="localTimeFormatted"
            :append-icon="$icon.LIGHT.COMMON.CLOCK"
            :label="placeholder"
            :clearable="clearable"
            :hide-details="hideDetails"
            :rules="rules"
            name="time"
            readonly
          />
        </v-layout>
      </template>
      <div>
        <v-time-picker scrollable v-model="localTime" color="primary" format="24hr" />
      </div>
    </v-menu>
  </div>
</template>

<script>
import * as moment from "moment";
export default {
  props: {
    label: String,
    value: String,
    placeholder: String,
    hideDetails: Boolean,
    required: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: Array,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      timeMenu: false,
    };
  },
  watch: {
    timeMenu: {
      handler(val) {
        if (!val) {
          this.$emit("blur");
        }
      },
    },
  },
  computed: {
    localTime: {
      get() {
        if (this.value) {
          const currentDate = new Date();
          // Split the time string into hours and minutes
          const [hours, minutes] = this.value.split(":").map(Number);

          // Create a new Date object with the same year, month, day, and the extracted time
          return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            hours,
            minutes,
          );
        }
        return new Date();
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    localTimeFormatted: {
      get() {
        return this.localTime ? moment(this.localTime).format("HH:mm") : "";
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
