<template>
  <div
    style="height: calc(100% - 68px)"
    class="overflow-y-auto d-flex justify-center align-center"
  >
    <div class="text-center pa-2">
      <!-- <img class="mb-7" :src="getEmptyStateIcon()" /> -->
      <div class="text-h5 font-weight-bold mb-2">
        {{ $t("fileManagement.preview.navMenu.readConfirmation.NOT_STARTED.title") }}
      </div>
      <div class="text-body-1 mb-7">
        {{ $t("fileManagement.preview.navMenu.readConfirmation.NOT_STARTED.subTitle") }}
      </div>
      <v-btn depressed color="primary" @click="addRecipients">
        {{ $t("fileManagement.preview.navMenu.readConfirmation.NOT_STARTED.button") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import emptyStateImage from "../../../../../assets/empty_state_signature.svg";

export default {
  data() {
    return {
      dialog: {
        addRecipients: false,
      },
    };
  },
  props: {
    storageId: String,
  },
  methods: {
    getEmptyStateIcon() {
      return emptyStateImage;
    },
    addRecipients() {
      this.$emit("addRecipients");
    },
  },
};
</script>
