<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("formManagement.templatePicker.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="pb-4">
          <AppDefaultTextField
            :label="$t('common.search')"
            :append-icon="$icon.LIGHT.ACTION.SEARCH"
            hide-details
            clearable
            :value="filter.search"
            @input="debounceSearch"
          />
        </div>
        <div class="pb-4 d-flex">
          <AppDefaultAutocomplete
            :label="$t('common.category')"
            :value="filter.categoryIds"
            @change="filter.categoryIds = $event"
            :items="categories"
            :loading="isCategoriesLoading"
            hide-details
            multiple
            clearable
            :noDataText="$t('common.noCategories')"
          />
        </div>

        <v-simple-table
          class="border-a overflow-hidden rounded"
          fixed-header
          height="400px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="input-background" style="height: 30px"></th>
                <th class="text-left input-background" style="height: 30px">
                  <span class="text--disabled">
                    {{ $t("formManagement.templatePicker.templateName") }}
                    ({{ templates.length }})
                  </span>
                </th>
                <th class="input-background" style="height: 30px"></th>
              </tr>
            </thead>
            <tbody class="border-radius-0">
              <tr
                v-for="(item, i) in templates"
                :key="i"
                class="cursor-pointer"
                :class="
                  item.id ? (item.id === templateId ? 'list_tr--selected' : '') : ''
                "
                @click="selectTemplate(item)"
              >
                <td width="1">
                  <v-btn
                    icon
                    plain
                    v-ripple="false"
                    @click.stop.prevent="toggleFavorite(item)"
                    ><v-icon color="#FFC107">
                      {{
                        item.isFavorite
                          ? $icon.SOLID.COMMON.STAR
                          : $icon.LIGHT.COMMON.STAR
                      }}
                    </v-icon>
                  </v-btn>
                </td>
                <td class="border-radius-0">
                  <AppTextTruncateValue :text="item.name" :truncate="50" />
                </td>
                <td class="border-radius-0 text-right">
                  <AppColorChip
                    v-if="item.category"
                    :text="
                      $te(`formManagement.category.${item.category.name}`)
                        ? $t(`formManagement.category.${item.category.name}`)
                        : item.category.name
                    "
                    color="info--text"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSelect"
          @cancel="localDialog = false"
          :loading="isCreatingForm"
          :disabled="!templateId"
          :confirmText="$t('formManagement.list.newForm')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { setFavorite } from "@/services/userSettings/favourite";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      filter: {
        search: null,
        tags: [],
        categoryIds: [],
      },
      searchDebounce: null,
      templateId: null,
      isCreatingForm: false,
    };
  },
  computed: {
    ...mapState("selectableFormTemplates", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("formIndustries", {
      industries: (state) => state.industries,
      isIndustriesLoading: (state) => state.isLoading,
    }),
    ...mapState("formCategories", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getFormTemplates(this.filterArgs());
          this.getFormIndustries();
          this.getCategories();
        }
      },
    },
    filter: {
      handler(filter) {
        this.getFormTemplates(this.filterArgs(filter));
      },
      deep: true,
    },
  },
  methods: {
    toggleFavorite(template) {
      const isFavorite = !template.isFavorite;
      const templateId = template.id;
      this.$store.commit("selectableFormTemplates/setFavorite", {
        templateId,
        isFavorite,
      });
      setFavorite({
        model: "template",
        modelId: templateId,
        isFavorite,
      }).then((res) => {
        this.getFormTemplates(this.filterArgs());
      });
    },
    getFormTemplates(args) {
      this.$store.dispatch("selectableFormTemplates/getTemplates", args);
    },
    getFormIndustries() {
      //this.$store.dispatch("formIndustries/getIndustries");
    },
    getCategories() {
      this.$store.dispatch("formCategories/getCategories");
    },
    selectTemplate(template) {
      if (!template) return;
      if (this.templateId === template.id) {
        this.templateId = null;
        return;
      }
      this.templateId = template.id;
    },
    onSelect() {
      if (!this.templateId) return;
      this.isCreatingForm = true;
      this.$emit("select", {
        templateId: this.templateId,
        onFormCreated: () => this.onFormCreated(),
        onFormFinally: () => this.onFormFinally(),
      });
    },
    onFormCreated() {
      this.localDialog = false;
      this.templateId = null;
    },
    onFormFinally() {
      this.isCreatingForm = false;
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },
    filterArgs(args) {
      return {
        ...this.filter,
        sortIsFavorite: true,
        ...args,
      };
    },
  },
};
</script>

<style>
.star-icon {
  transition: color 0.2s ease-in-out;
}

.star-icon:hover,
.star-icon:hover.fa-light.fa-star {
  color: #ffa000; /* or any other color you prefer for the hover effect */
}
</style>
