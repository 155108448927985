import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const {
  ROOT,
  TEMPLATES,
  TEMPLATE_VIEW,
  TEMPLATE_DETAILS,
  TEMPLATE_CATEGORY,
  TEMPLATE_STATUS,
  TEMPLATE_HISTORY,
  FORM_TEMPLATES,
  PROJECT_TEMPLATES,
  PROJECT_TEMPLATE_BUILDER,
  PROJECT_TEMPLATE_BUILDER_DETAILS,
  PROJECT_TEMPLATE_BUILDER_HISTORY,
  FORM_TEMPLATE_BUILDER,
  FORM_TEMPLATE_BUILDER_DETAILS,
  FORM_TEMPLATE_BUILDER_TAGS,
  FORM_TEMPLATE_BUILDER_HISTORY,
} = ROUTE_NAMES.CMS;

export const cmsRoutes = [
  {
    path: "/cms",
    name: ROOT,
    redirect: { name: TEMPLATES },
    component: () => import(/* webpackChunkName: "cms" */ "../../pages/CMS"),
    beforeEnter: multiguard([authGuard, userGuard()]),
    children: [
      {
        path: "templates",
        name: TEMPLATES,
        component: () =>
          import(/* webpackChunkName: "cms" */ "../../pages/CMS/Templates"),
        children: [
          {
            path: "view/:templateId",
            name: TEMPLATE_VIEW,
            component: () =>
              import(
                /* webpackChunkName: "cms" */ "../../pages/CMS/Templates/TemplateViewPage"
              ),
            children: [
              {
                path: "details",
                name: TEMPLATE_DETAILS,
                component: () =>
                  import(
                    /* webpackChunkName: "cms" */ "../../pages/CMS/Templates/TemplateViewPage/DetailsPage"
                  ),
              },
              {
                path: "category",
                name: TEMPLATE_CATEGORY,
                component: () =>
                  import(
                    /* webpackChunkName: "cms" */ "../../pages/CMS/Templates/TemplateViewPage/CategoryPage"
                  ),
              },
              {
                path: "status",
                name: TEMPLATE_STATUS,
                component: () =>
                  import(
                    /* webpackChunkName: "cms" */ "../../pages/CMS/Templates/TemplateViewPage/StatusPage"
                  ),
              },
              {
                path: "history",
                name: TEMPLATE_HISTORY,
                component: () =>
                  import(
                    /* webpackChunkName: "cms" */ "../../pages/CMS/Templates/TemplateViewPage/HistoryPage"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "form-templates",
        name: FORM_TEMPLATES,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/FormTemplate/FormTemplatesPage"
          ),
      },
      {
        path: "project-templates",
        name: PROJECT_TEMPLATES,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/ProjectTemplate/ProjectTemplatesPage"
          ),
      },
    ],
  },
  {
    path: "/cms/project-template-builder/:templateId",
    name: PROJECT_TEMPLATE_BUILDER,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(
        /* webpackChunkName: "cms" */ "../../pages/CMS/ProjectTemplate/ProjectTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: PROJECT_TEMPLATE_BUILDER_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/ProjectTemplate/ProjectTemplateBuilderPage/Menu/DetailsPage"
          ),
      },
      {
        path: "history",
        name: PROJECT_TEMPLATE_BUILDER_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/ProjectTemplate/MenuPages/HistoryPage"
          ),
      },
    ],
  },
  {
    path: "/cms/form-template-builder/:templateId",
    name: FORM_TEMPLATE_BUILDER,
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(
        /* webpackChunkName: "cms" */ "../../pages/CMS/FormTemplate/FormTemplateBuilderPage"
      ),
    children: [
      {
        path: "details",
        name: FORM_TEMPLATE_BUILDER_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/FormTemplate/FormTemplateBuilderPage/Menu/DetailsPage"
          ),
      },
      {
        path: "tags",
        name: FORM_TEMPLATE_BUILDER_TAGS,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/FormTemplate/FormTemplateBuilderPage/Menu/TagsPage"
          ),
      },
      {
        path: "history",
        name: FORM_TEMPLATE_BUILDER_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "cms" */ "../../pages/CMS/FormTemplate/MenuPages/HistoryPage"
          ),
      },
    ],
  },
];
