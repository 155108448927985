import { normalizeIntegrations } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  isLoading: false,
  integrations: [],
});

const getters = {};

const actions = {
  async getIntegrations({ commit }, load = false) {
    if (load) {
      commit("loading", true);
    }
    return axios
      .get(`${apiUrl}integration`)
      .then(({ data }) => {
        const integrations = data?.integrations;
        const normalizedIntegrations = normalizeIntegrations(integrations);
        commit("setIntegrations", normalizedIntegrations);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
  async updateInstance({ commit, dispatch }, { id, body }) {
    if (typeof body.active === "boolean") {
      commit("toggleField", {
        id,
        field: "isUpdatingActive",
        value: true,
      });
    }
    return axios
      .put(`${apiUrl}integration-instance/${id}`, body)
      .then(({ data }) => {
        dispatch("getIntegrations");
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("toggleField", {
          id,
          field: "isUpdatingActive",
          value: false,
        });
      });
  },
  async deleteInstance({ dispatch }, { id }) {
    return axios
      .delete(`${apiUrl}integration-instance/${id}`)
      .then(({ data }) => {
        dispatch("getIntegrations");
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setIntegrations(state, integrations) {
    state.integrations = integrations;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  toggleField(state, { id, field, value }) {
    const index = state.integrations.findIndex((x) => x.integrationInstance?.id === id);
    if (index === -1) return;
    state.integrations.splice(index, 1, {
      ...state.integrations[index],
      [field]: value,
    });
  },
};

export const integrations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
