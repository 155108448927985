import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  forms: [],
  isLoading: false,
  limit: 0,
  offset: 0,
  count: 0,
});

const getters = {
  getFormById: (state) => (id) => {
    return state.forms.find((form) => form.id === id);
  },
  isValidForm: (state, getters) => (id) => {
    if (state.isLoading) return true;
    return getters.getFormById(id) ? true : false;
  },
  getFormFolderId: (state, getters) => (id) => {
    const form = getters.getFormById(id);
    return form?.folderId || null;
  },
};

const actions = {
  async getForms(
    { commit, state },
    { filter, projectId, limit = 30, shouldPushItems, groupBy, groupDesc, statusIds },
  ) {
    const prevLimit = state.limit;
    commit("loading", true);
    commit("setLimit", limit);
    commit("setOffset", { prevLimit });

    const params = {
      ...filter,
      offset: state.offset,
      limit: state.limit,
      groupBy,
      groupDesc,
      projectId,
      statusIds,
    };

    return axios
      .get(`${apiUrl}form`, { params })
      .then(({ data }) => {
        const { forms, count } = data;
        commit("setCount", count);
        if (shouldPushItems) {
          commit("pushForms", forms);
        } else {
          commit("setForms", forms);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async updateForm({ commit }, { id, data }) {
    if (typeof data?.statusId === "string") {
      commit("toggleUpdatingStatus", {
        id,
        isUpdatingStatus: true,
      });
    }
    return axios
      .put(`${apiUrl}form/${id}`, data)
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          commit("updateForm", form);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        if (typeof data?.statusId === "string") {
          commit("toggleUpdatingStatus", {
            id,
            isUpdatingStatus: false,
          });
        }
      });
  },
};

const mutations = {
  setCount(state, count) {
    state.count = count;
  },
  setOffset(state, { prevLimit }) {
    state.offset += prevLimit;
  },
  setLimit(state, limit) {
    state.limit = limit;
  },
  setForms(state, forms) {
    const uniqueFormIds = new Set();
    const uniqueForms = forms.filter((form) => {
      if (uniqueFormIds.has(form.id)) {
        return false;
      } else {
        uniqueFormIds.add(form.id);
        return true;
      }
    });
    state.forms = uniqueForms;
  },
  updateForm(state, form) {
    if (!form) throw new Error("No form");
    const index = state.forms.findIndex((x) => x.id === form.id);
    if (index !== -1) {
      state.forms.splice(index, 1, form);
    }
  },
  toggleUpdatingStatus(state, { id, isUpdatingStatus }) {
    const index = state.forms.findIndex((x) => x.id === id);
    if (index !== -1) {
      if (state.forms[index]) {
        state.forms.splice(index, 1, { ...state.forms[index], isUpdatingStatus });
      }
    }
  },
  pushForms(state, forms) {
    state.forms.push(...forms);
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  resetState(state) {
    state.forms = [];
    state.offset = 0;
    state.count = 0;
    state.limit = 0;
  },
};

export const formsInfinityList = {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
