import moment from "moment";

export function capitalize(value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function truncate(text, length, clamp) {
  clamp = clamp || "...";
  const node = document.createElement("div");
  node.innerHTML = text;
  const content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
}

export function currencyFormat(value, currency) {
  //MANUAL LOCALE FORMATTING (look up this comment when new locales are added)
  let locales;
  if (currency === "NOK") {
    locales = "nb-NO";
  }

  let currencyProperties = {
    locales,
    options: {
      style: "currency",
      currency,
      currencyDisplay: "code",
    },
  };

  const formatted = new Intl.NumberFormat(
    currencyProperties.locales,
    currencyProperties.options,
  ).format(value);

  return formatted;
}

export function translatedDateFormat(value, format) {
  //MANUAL LOCALE FORMATTING (look up this comment when new locales are added)
  const language = localStorage.getItem("language");
  return value ? moment(value).locale(language).format(format) : "";
}

export default [
  {
    key: "capitalize",
    cb: capitalize,
  },
  {
    key: "truncate",
    cb: truncate,
  },
  {
    key: "currencyFormat",
    cb: currencyFormat,
  },
  {
    key: "translatedDateFormat",
    cb: translatedDateFormat,
  },
];
