<script>
import { VSpacer, VIcon, VForm } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
  },
  data() {
    return {
      edit: false,
      name: {
        isValid: false,
      },
    };
  },
  computed: {
    editDisabled() {
      return this.edit && !this.name.isValid;
    },
  },
  methods: {
    onFormUpdate(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (typeof value !== "undefined" && value !== this.item[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.formTemplateBuilderMixins_updateItem({
          item: this.item,
          body: { [field]: value },
        });
      }
      this.onEditEnd();
    },
    onEdit() {
      this.edit = true;
    },
    onEditEnd() {
      this.edit = false;
    },
    deleteItem() {
      this.$emit("item:delete", { item: this.item });
    },
    genTitleInput() {
      return this.$createElement(
        VForm,
        {
          props: { value: this.name.isValid },
          class: "w-100",
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.name.isValid = e),
          },
        },
        [
          this.$createElement(
            "div",
            {
              on: {
                click: (e) => {
                  e.stopPropagation();
                },
              },
            },
            [
              this.$createElement("AppDefaultTextField", {
                class: "text-h6",
                props: {
                  label: this.$t("templateManagement.form.builder.item.groupTitle"),
                  hideDetails: true,
                  value: this.item?.name,
                  rules: this.$rules.NAME_RULES,
                  autofocus: true,
                },
                on: {
                  blur: (e) => this.onFormUpdate(e, "name"),
                  keydown: (e) => {
                    if (e.key === "Enter") {
                      this.onFormUpdate(e, "name");
                    }
                  },
                },
              }),
            ],
          ),
        ],
      );
    },
    genTitleMenu() {
      return this.$createElement(
        "AppToggleMenuBtn",
        {
          props: {
            activatorClass: "ml-1 mr-1",
          },
        },
        [
          this.$createElement("AppMenuDeleteBtn", {
            on: { click: () => this.deleteItem({ item: this.item }) },
          }),
        ],
      );
    },
    genTitle() {
      return this.$createElement("div", { class: "d-flex align-center w-100" }, [
        [
          this.$createElement(
            "div",
            {
              class: "d-flex align-center justify-center",
              style: "min-width: 32px",
            },
            [this.$createElement(VIcon, this.$icon.REGULAR.ACTION.FORM_SECTION)],
          ),

          this.edit
            ? this.genTitleInput()
            : this.$createElement(
                "div",
                {
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                      this.onEdit();
                    },
                  },
                  style: "min-height: 40px; letter-spacing: normal !important;",
                  class:
                    "pl-3 font-weight-medium text-h6 w-100 d-flex align-center cursor-text",
                },
                this.item.name,
              ),
        ],
        this.$createElement(VSpacer),
        this.genTitleMenu(),
      ]);
    },
  },
  render(h) {
    return h(
      "div",
      {
        class: "ui-background",
        style: "position: sticky; top: 40px; z-index: 1;",
      },
      [this.genTitle()],
    );
  },
};
</script>
