export const redirectFromLocalStorage = ({
  localStorageKey,
  validRoutes,
  defaultRoute,
}) => {
  const localStorageItem = localStorage.getItem(localStorageKey) || "";

  if (validRoutes.includes(localStorageItem)) {
    return { name: localStorageItem };
  } else {
    localStorage.setItem(localStorageKey, defaultRoute);
    return { name: defaultRoute };
  }
};

export const makeBeforeEnter =
  ({ localStorageKey }) =>
  (to, from, next) => {
    next();
    localStorage.setItem(localStorageKey, to.name);
  };
