import { normalizeCmsFormTemplateItems } from "@/helpers/normalization";
import axios from "axios";
import _ from "lodash";
const apiUrl = process.env.VUE_APP_API_URL;
import { v4 } from "uuid";

const state = () => ({
  items: [],
  isLoading: false,
  isCreating: false,
});

const getters = {
  sortedItems: (state) => {
    return [...state.items].sort((a, b) => a.index - b.index);
  },
};

const actions = {
  async getItems({ commit }, { templateId, load = true }) {
    if (load) {
      commit("loading", true);
    }
    return axios
      .get(`${apiUrl}cms/form-item`, {
        params: { templateId },
      })
      .then(({ data }) => {
        const items = data?.items;
        const normalizedItems = normalizeCmsFormTemplateItems(items);
        commit("setItems", normalizedItems);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async createItem({ commit }, { body, tempId = v4() }) {
    commit("addItem", { ...body, id: tempId, isLoading: true });
    commit("moveItem", { id: tempId, data: body });
    commit("creating", true);
    return axios
      .post(`${apiUrl}cms/form-item`, { ...body })
      .then(({ data }) => {
        const item = data?.item;
        if (item) {
          commit("removeItem", tempId);
          commit("addItem", item);
        }
        commit("creating", false);
        return item;
      })
      .catch((error) => {
        commit("removeItem", tempId);
        commit("creating", false);
        throw new Error(error);
      });
  },
  async updateItem({ commit, state }, { id, body }) {
    const oldItem = state.items.find((x) => x.id === id);
    const newItem = { ...oldItem, ...body };

    if (_.isEqual(oldItem, newItem)) return;

    commit("updateItem", newItem);

    return axios
      .put(`${apiUrl}cms/form-item/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        const item = data?.item;
        if (item) {
          commit("updateItem", item);
        }
      })
      .catch((error) => {
        commit("updateItem", oldItem);
        throw new Error(error);
      });
  },
  async moveItem({ dispatch, commit }, { id, body, templateId }) {
    commit("moveItem", { id, data: body });

    return axios
      .put(`${apiUrl}cms/form-item/${id}`, {
        ...body,
      })
      .then(({ data }) => {
        dispatch("getItems", { templateId, load: false });
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteItems({ commit, dispatch, state }, { ids, templateId }) {
    const itemId = ids[0];

    const oldItem = state.items.find((x) => x.id === itemId);
    commit("removeItem", itemId);

    return axios
      .delete(`${apiUrl}cms/form-item`, {
        data: { ids },
      })
      .then(({ data }) => {
        dispatch("getItems", { templateId, load: false });
      })
      .catch((error) => {
        commit("addItem", oldItem);
        throw new Error(error);
      });
  },
};

const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  updateItem(state, item) {
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.items.splice(index, 1, item);
    }
  },
  moveItem(state, { id, data }) {
    const { parentId, index } = data;
    const oldArrayIndex = state.items.findIndex((x) => x.id === id);
    const oldItem = state.items[oldArrayIndex];
    if (!oldItem) return;

    if (oldItem.parentId !== parentId) {
      const oldParentItems = state.items.filter(
        (x) => x.parentId === oldItem.parentId && x.id !== id,
      );

      for (const [newIndex, item] of oldParentItems.entries()) {
        item.index = newIndex;
      }

      oldItem.parentId = parentId;
    }

    const items = state.items.filter((x) => x.parentId === parentId && x.id !== id);
    items.splice(index, 0, oldItem);

    for (const [newIndex, item] of items.entries()) {
      item.index = newIndex;
    }
  },
  addItem(state, item) {
    state.items.push(item);
  },
  removeItem(state, itemId) {
    const index = state.items.findIndex((x) => x.id === itemId);
    if (index !== -1) {
      state.items.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  creating(state, isCreating) {
    state.isCreating = isCreating;
  },
};

export const cmsFormTemplateItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
