import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
import { getInstance } from "../auth";

const state = () => ({
  domain: null,
  profile: null,
  isLoading: false,
  isSaving: false,
  isUpdatingFromBrreg: false,
});

const getters = {};

const actions = {
  async getDomain({ commit }, id) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}domain/${id}`)
      .then(({ data }) => {
        const domain = data?.domain;
        const profile = data?.profile;
        commit("setDomain", domain);
        commit("setProfile", profile);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
  async updateDomain({ commit }, { id, body }) {
    commit("saving", true);
    return axios
      .put(`${apiUrl}domain/${id}`, body)
      .then(({ data }) => {
        const domain = data?.domain;
        const profile = data?.profile;
        commit("setDomain", domain);
        commit("setProfile", profile);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("saving", false);
      });
  },
  async updateOrganizationWithBronnoysund({ commit }) {
    commit("isUpdatingFromBrreg", true);
    return axios
      .put(`${integrationApiUrl}bronnoysund/domain-info`)
      .then(({ data }) => {
        const domain = data?.domain;
        const profile = data?.profile;
        commit("setDomain", domain);
        commit("setProfile", profile);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("isUpdatingFromBrreg", false);
      });
  },
  async uploadProfileLogo({ commit }, { id, formData }) {
    return axios
      .post(`${apiUrl}domain/${id}/logo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {})
      .catch((error) => {
        throw new Error(error);
      });
  },
  async getProfileLogo({ commit }, id) {
    return axios
      .get(`${apiUrl}domain/${id}/logo`, { responseType: "blob" })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async deleteProfileLogo({ commit }, id) {
    return axios
      .delete(`${apiUrl}domain/${id}/logo`)
      .then((res) => {})
      .catch((error) => {
        throw new Error(error);
      });
  },
};

const mutations = {
  setDomain(state, domain) {
    state.domain = domain;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  saving(state, isSaving) {
    state.isSaving = isSaving;
  },
  isUpdatingFromBrreg(state, isUpdating) {
    state.isUpdatingFromBrreg = isUpdating;
  },
};

export const domain = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
