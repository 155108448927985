import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function convertCmsTemplates({ ids }) {
  return axios
    .post(`${apiUrl}template/cms-form/convert`, { ids })
    .then(({ data }) => {
      return data?.templates || [];
    })
    .catch(error => {
      throw new Error(error);
    });
}
