import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const {
  ROOT,
  PROFILE,
  PROFILE_DETAILS,
  PROFILE_PERSONALIZATION,
  PROFILE_PRIVACY,
  WORKSPACES,
  NOTIFICATIONS,
  EMAIL_NOTIFICATIONS,
  PUSH_NOTIFICATIONS,
  SUPPORT_WORKSPACES,
  ACCOUNT,
  ACCOUNT_DETAILS,
  ACCOUNT_REFERRAL,
  ACCOUNT_SECURITY,
  PRODUCTS,
  PRODUCTS_LIST,
  PRODUCT_SETTINGS,
  SYSTEM_SETTINGS,
  TIME_ENTRY_SETTINGS,
  MODULE_CONTROL,
  INTEGRATIONS,
  BILLING,
  BILLING_SUBSCRIPTION,
  BILLING_ADMIN,
  BILLING_USAGE,
  BILLING_DETAILS,
  BILLING_INVOICE_HISTORY,
} = ROUTE_NAMES.SETTINGS;

export const settingsRoutes = [
  {
    path: "/settings",
    name: ROOT,
    redirect: { name: PROFILE },
    beforeEnter: multiguard([authGuard, userGuard()]),
    component: () =>
      import(/* webpackChunkName: "settings" */ "../../pages/SettingsPage"),
    children: [
      {
        path: "profile",
        name: PROFILE,
        redirect: { name: PROFILE_DETAILS },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/YourProfilePage"
          ),
        children: [
          {
            path: "details",
            name: PROFILE_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/YourProfilePage/ProfileDetailsPage/"
              ),
          },
          {
            path: "personalization",
            name: PROFILE_PERSONALIZATION,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/YourProfilePage/ProfilePersonalizationPage/"
              ),
          },
          {
            path: "privacy",
            name: PROFILE_PRIVACY,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/YourProfilePage/ProfilePrivacy"
              ),
          },
        ],
      },
      {
        path: "workspaces",
        name: WORKSPACES,
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/ProfileWorkspacesPage/"
          ),
      },
      {
        path: "notifications",
        name: NOTIFICATIONS,
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/ProfileNotificationsPage/"
          ),
        redirect: { name: EMAIL_NOTIFICATIONS },
        children: [
          {
            path: "email",
            name: EMAIL_NOTIFICATIONS,
            component: () =>
              import(
                /* webpackChunkName: "company" */ "../../pages/SettingsPage/ProfilePage/ProfileNotificationsPage/EmailNotificationsPage/"
              ),
          },
          {
            path: "push",
            name: PUSH_NOTIFICATIONS,
            component: () =>
              import(
                /* webpackChunkName: "company" */ "../../pages/SettingsPage/ProfilePage/ProfileNotificationsPage/PushNotificationsPage/"
              ),
          },
        ],
      },
      {
        path: "support-workspaces",
        name: SUPPORT_WORKSPACES,
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/ProfilePage/SupportWorkspacesPage/"
          ),
      },
      {
        path: "account",
        name: ACCOUNT,
        redirect: { name: ACCOUNT_DETAILS },
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/Account"
          ),
        children: [
          {
            path: "details",
            name: ACCOUNT_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/Account/AccountDetails"
              ),
          },
          {
            path: "referral",
            name: ACCOUNT_REFERRAL,
            component: () =>
              import(
                /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/Account/AccountReferral"
              ),
          },
          {
            path: "security",
            name: ACCOUNT_SECURITY,
            component: () =>
              import(
                /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/Account/AccountSecurity"
              ),
          },
        ],
      },
      {
        path: "products",
        name: PRODUCTS,
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/ProductsPage"
          ),
        redirect: { name: PRODUCTS_LIST },
        children: [
          {
            path: "list",
            name: PRODUCTS_LIST,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/ProductsPage/ProductList"
              ),
          },
          {
            path: "settings",
            name: PRODUCT_SETTINGS,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/ProductsPage/ProductSettings"
              ),
          },
        ],
      },
      {
        path: "system-settings",
        name: SYSTEM_SETTINGS,
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "company" */ "../../pages/SettingsPage/AccountPage/SystemSettings"
          ),
        redirect: { name: TIME_ENTRY_SETTINGS },
        children: [
          {
            path: "list",
            name: TIME_ENTRY_SETTINGS,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/SystemSettings/TimeEntrySettings"
              ),
          },
        ],
      },
      {
        path: "module-control",
        name: MODULE_CONTROL,
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/ModuleControlPage"
          ),
      },
      {
        path: "integrations",
        name: INTEGRATIONS,
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/IntegrationsPage"
          ),
      },
      {
        path: "billing",
        name: BILLING,
        redirect: { name: BILLING_SUBSCRIPTION },
        beforeEnter: multiguard([authGuard, userGuard({ admin: true, owner: true })]),
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage"
          ),
        children: [
          {
            path: "subscription",
            name: BILLING_SUBSCRIPTION,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage/SubscriptionPage"
              ),
          },
          {
            path: "admin",
            name: BILLING_ADMIN,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage/GriprAdmin"
              ),
          },
          {
            path: "usage",
            name: BILLING_USAGE,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage/UsagePage"
              ),
          },
          {
            path: "details",
            name: BILLING_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage/DetailsPage"
              ),
          },
          {
            path: "invoice-history",
            name: BILLING_INVOICE_HISTORY,
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "../../pages/SettingsPage/AccountPage/BillingPage/InvoiceHistoryPage"
              ),
          },
        ],
      },
    ],
  },
];
