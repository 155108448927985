import { normalizeFormIndustries } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  industries: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getIndustries({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}template/tag`, { params: { type: "INDUSTRY" } })
      .then(({ data }) => {
        const industries = data?.tags;
        const normalizedIndustries = normalizeFormIndustries(industries);
        commit("setIndustries", normalizedIndustries);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setIndustries(state, industries) {
    state.industries = industries;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const formIndustries = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
