<template>
  <AppModuleCombobox
    v-bind="params"
    v-on="listeners"
    :items="formattedRecipients"
    :rules="$rules.REQUIRED"
    :clearable="false"
    autofocus
    :label="label"
    style="width: 200px"
    item-text="name"
    item-value="name"
    :filter="filter"
    return-object
    :titleField="$constant.MODULE_FIELD_CONFIG.USER_AND_CONTACT.TITLE_FIELD"
    :subTitleFields="$constant.MODULE_FIELD_CONFIG.USER_AND_CONTACT.SUB_TITLE_FIELDS"
  />
</template>

<script>
export default {
  props: {
    label: String,
    users: Array,
    contacts: Array,
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
    formattedRecipients() {
      const items = [];

      items.push({
        header: this.$options.filters.capitalize(this.$t("common.users")),
        type: "user",
      });

      this.users.forEach((user) => {
        items.push({
          ...user,
          type: "user",
        });
      });

      items.push({ header: this.$t("drawer.route.contacts"), type: "contact" });
      this.contacts.forEach((contact) => {
        items.push({
          ...contact,
          name: contact.fullName,
          type: "contact",
        });
      });
      return items;
    },
  },
  methods: {
    filter(item, queryText, itemText) {
      if (item.header) {
        return true;
      }

      return item.searchStr.includes(queryText);
    },
  },
};
</script>
