<template>
  <div @click="onSelect" class="w-100">
    <v-card
      min-width="250"
      class="w-100 border-a overflow-hidden cursor-pointer align-center justify-center d-flex py-8 mt-1"
      :class="isSelected ? 'dialog__selected-card' : ''"
      flat
    >
      <div>
        <div class="text-center mb-3">
          <v-icon x-large>{{ icon }}</v-icon>
        </div>
        <div
          class="text-center font-weight-bold mb-2 primary-text--text"
          style="user-select: none"
        >
          {{ $t(title) }}
        </div>
        <div class="text-center px-10 primary-text--text" style="user-select: none">
          {{ $t(subTitle) }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    subTitle: String,
    isSelected: Boolean,
  },
  methods: {
    onSelect() {
      this.$emit("click");
    },
  },
};
</script>
