<template>
  <Config v-on="listeners" v-bind="params" :completeMethod="completeMethod" />
</template>

<script>
import Config from "./Config.vue";
import {
  getAuthUrl,
  authorize,
} from "../../../../../../services/integration/integrationSetup/oAuth2SetupService";

export default {
  components: { Config },
  methods: {
    completeMethod(code) {
      console.log("completeMethod", this.$attrs);
      return getAuthUrl({
        integrationName: this.$attrs.integrationName,
        code,
      }).then((url) => {
        return authorize({ url });
      });
    },
  },
  computed: {
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
};
</script>
