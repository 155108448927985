<template>
  <v-dialog v-model="localDialog" :max-width="maxWidth" scrollable>
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text v-if="description">
        {{ description }}
      </v-card-text>

      <v-card-actions> <AppColorPicker /> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    color: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      presetColors: [],
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    onConfirm(event) {
      this.$emit("dialog:confirm", { event, object: this.object });
      this.localDialog = false;
    },
  },
};
</script>
