import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT } = ROUTE_NAMES.OFFICE_ONLINE;

export const officeOnlineRoutes = [
  {
    path: "/office-online/:fileId",
    name: ROOT,
    component: () =>
      import(
        /* webpackChunkName: "getStarted" */ "../../pages/FileStorage/OfficeOnlinePage"
      ),
    beforeEnter: multiguard([authGuard, userGuard()]),
  },
];
