<template>
  <div>
    <AppContactDialog
      v-model="dialog.updateContact"
      :data="selectedItem"
      :title="$t('contact.contactPerson.updateContact.title')"
      @confirm="afterContactUpdate"
      :confirmText="$t('common.save')"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteContact"
      :title="$t('contact.contactPerson.deleteContact.title')"
      :subtitle="$t('contact.contactPerson.deleteContact.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('contact.contactPerson.deleteContact.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      :item="selectedItem"
      @delete="deleteContact"
    />
    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="contacts"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      disable-multi-select
      :noDataText="$t('common.noContacts')"
      :columnsToFreeze="1"
    >
      <template v-slot:item="{ item }">
        <AppContactTableItem
          :contact="item"
          :jobTitleLabel="getJobTitleLabel(item)"
          :responsibilityLabel="getResponsibilityLabel(item)"
          :isHighlighted="isHighlighted(item)"
          :canEditLabels="isDomainOwner || isDomainAdmin"
          @contact:update="updateContact"
          @menu:click="onMenuClick"
          @responsibilityLabel:click="openResponsibilityMenu"
          @jobTitleLabel:click="openJobTitleMenu"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppActivityIndicator
      :show="isInviting"
      :text="$t('users.userInvite.sendingInvite')"
    />
    <AppLabelMenu
      ref="labelMenuJobTitle"
      @label:change="onJobTitleLabelChange"
      :labelType="jobTitleLabelType"
      selectedItemLabelPath="label"
    />
    <AppLabelMenu
      ref="labelMenuResponsibility"
      @label:change="onResponsibilityLabelChange"
      :labelType="responsibilityLabelType"
      selectedItemLabelPath="relation.label"
    />
    <AppContextMenu
      v-if="!projectId"
      ref="contextMenu"
      @input="isContextMenuOpen = $event"
    >
      <AppMenuEditBtn @click="onContactEdit" :disabled="!!projectId" />
      <AppMenuItem
        @click="onInviteContactToWorkspace"
        :icon="$icon.REGULAR.ACTION.EMAIL"
        :text="$t('common.inviteToWorkspace')"
        :disabled="isInviteDisabled || !!projectId"
      />
      <AppMenuDeleteBtn @click="onContactDelete" :disabled="!!projectId" />
    </AppContextMenu>
  </div>
</template>

<script>
import { inviteUser } from "@/services/auth/inviteUser";
import { mapGetters } from "vuex";

export default {
  props: {
    contacts: Array,
    isLoading: Boolean,
    serverItemsLength: Number,
    tableModel: String,
  },
  data() {
    return {
      isInviting: false,
      dialog: {
        updateContact: false,
        deleteContact: false,
      },
      selectedItem: null,
      jobTitleLabelType: this.$constant.LABEL_TYPE.JOB_TITLE,
      responsibilityLabelType: this.$constant.LABEL_TYPE.RESPONSIBILITY,
      isContextMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
    }),
    ...mapGetters("label", {
      labelsByType: "labelsByType",
    }),
    projectId() {
      return this.$route.params.projectId;
    },
    dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    isInviteDisabled() {
      if (!this.isDomainOwner && !this.isDomainAdmin) return true;
      if (this.hasStatus(this.$constant.ACTIVE)) return true;
      return false;
    },
    headers() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t("common.name")),
          value: this.dataFields.NAME,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.customerSupplier")),
          value: this.dataFields.CLIENT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("users.label.jobTitle.title")),
          value: this.dataFields.LABEL,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("contact.contactPerson.label.responsibility.title"),
          ),
          value: this.dataFields.RELATION_LABEL,
          show: !!this.projectId,
          sortable: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.phoneNumber")),
          value: this.dataFields.PHONE,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.email")),
          value: this.dataFields.EMAIL,
          show: true,
        },
        {
          text: this.$t("common.active"),
          value: this.dataFields.ACTIVE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: !this.projectId,
        },
      ];
    },
  },
  methods: {
    getResponsibilityLabel(item) {
      return this.labelsByType(this.responsibilityLabelType).find(
        (label) => label?.id === item?.relation?.labelId,
      );
    },
    getJobTitleLabel(item) {
      return this.labelsByType(this.jobTitleLabelType).find(
        (label) => label?.id === item?.labelId,
      );
    },
    getLabels() {
      this.$store.dispatch("label/getLabels", {
        type: this.jobTitleLabelType,
      });
      if (!this.projectId) return;
      this.$store.dispatch("label/getLabels", {
        type: this.responsibilityLabelType,
      });
    },
    onInviteContactToWorkspace() {
      const showSnack = ({ text, color = "success" }) => {
        this.$store.dispatch("snackbar/snackbar", {
          show: true,
          text,
          color,
        });
      };

      if (!this.selectedItem?.email) {
        showSnack({
          text: this.$t("common.emailIsRequiredToInvite"),
          color: "error",
        });
        return;
      }

      this.isInviting = true;

      const body = {
        email: this.selectedItem.email,
        role: "CONTACT",
        contactId: this.selectedItem.id,
      };

      inviteUser({ body })
        .then((data) => {
          if (!!data?.message?.key) {
            const translationKeys = {
              userAlreadyInvited: "userHasBeenReinvited",
              contactNotFound: "contactNotFound",
            };
            const translationKey = translationKeys[data.message.key];
            const path = `users.userInvite.${translationKey}`;

            if (translationKey && this.$te(path)) {
              showSnack({
                text: this.$t(path),
              });
              return;
            }
          }
          showSnack({
            text: this.$t("users.userInvite.inviteSent"),
          });
        })
        .catch(() => {
          showSnack({
            text: this.$t("common.error.title"),
            color: "error",
          });
        })
        .finally(() => {
          this.isInviting = false;
        });
    },
    hasStatus(status) {
      if (!this.selectedItem) return false;
      return this.selectedItem?.user?.status === status;
    },
    openJobTitleMenu({ pointerEvent, item: selectedItem }) {
      this.$refs.labelMenuJobTitle.open({
        pointerEvent,
        data: { selectedItem },
      });
    },
    openResponsibilityMenu({ pointerEvent, item: selectedItem }) {
      this.$refs.labelMenuResponsibility.open({
        pointerEvent,
        data: { selectedItem },
      });
    },
    isHighlighted(item) {
      const isCurrentItem = this.selectedItem === item;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);

      return this.isContextMenuOpen || isAnyDialogsOpen;
    },
    onMenuClick({ event, contact }) {
      if (this.projectId) return;
      this.selectedItem = contact;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onContactDelete() {
      this.dialog.deleteContact = true;
    },
    deleteContact(e) {
      this.$emit("contact:delete", e);
    },
    onContactEdit() {
      this.dialog.updateContact = true;
    },
    onJobTitleLabelChange({ selectedItem, label }) {
      this.updateContact({
        id: selectedItem.id,
        body: {
          labelId: label.id,
          projectId: this.projectId,
        },
      });
    },
    onResponsibilityLabelChange({ selectedItem, label }) {
      this.updateContact({
        id: selectedItem.id,
        body: {
          relation: {
            model: "project",
            modelId: this.projectId,
            labelId: label.id,
          },
        },
      });
    },
    updateContact(e) {
      this.$emit("contact:update", e);
    },
    afterContactUpdate() {
      this.$emit("contact:afterUpdate");
    },
  },
  mounted() {
    this.getLabels();
  },
};
</script>
