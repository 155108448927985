import { mapGetters } from "vuex";
export const planRestrictionsMixins = {
  computed: {
    ...mapGetters("auth", {
      subscriptionPlan: "subscriptionPlan",
    }),
    planRestrictionsMixins_canAddDocumentTemplates() {
      return this.subscriptionPlan.canGetLibraryTemplate.document;
    },
    planRestrictionsMixins_canAddFormTemplates() {
      return this.subscriptionPlan.canGetLibraryTemplate.form;
    },
    planRestrictionsMixins_canIntegrateAccounting() {
      return this.subscriptionPlan.canIntegrate.accounting;
    },
    planRestrictionsMixins_canIntegrateBoligmappa() {
      return this.subscriptionPlan.canIntegrate.boligmappa;
    },
    planRestrictionsMixins_canIntegrateEbyggesok() {
      return this.subscriptionPlan.canIntegrate.ebyggesok;
    },
    planRestrictionsMixins_canIntegrateAutogear() {
      return this.subscriptionPlan.canIntegrate.autogear;
    },
    planRestrictionsMixins_canCreateProject() {
      return this.subscriptionPlan.hasAvailableProject;
    },
    planRestrictionsMixins_canUploadToStorage() {
      return this.subscriptionPlan.hasAvailableStorage;
    },
    planRestrictionsMixins_canInviteUser() {
      return this.subscriptionPlan.hasAvailableLisence;
    },
    planRestrictionsMixins_canUseOfficeOnline() {
      return this.subscriptionPlan.canEditOfficeOnline;
    },
    planRestrictionsMixins_canMergeFiles() {
      return this.subscriptionPlan.canMergeFiles;
    },
    planRestrictionsMixins_canSaveFormDocument() {
      return this.subscriptionPlan.canSaveFormDocument;
    },
    planRestrictionsMixins_canSendFormToSign() {
      return this.subscriptionPlan.canSendFormToSign;
    },
    planRestrictionsMixins_canActivateTimeEntry() {
      return this.subscriptionPlan.canActivateTimeEntry;
    },
  },
  methods: {
    planRestrictionsMixins_canIntegrateByIntegrationName(name) {
      if (this.$constant.ACCOUNTING_INTEGRATIONS.includes(name)) {
        return this.planRestrictionsMixins_canIntegrateAccounting;
      }
      if (name === this.$constant.BOLIGMAPPA) {
        return this.planRestrictionsMixins_canIntegrateBoligmappa;
      }
      if (name === this.$constant.EBYGGESOK) {
        return this.planRestrictionsMixins_canIntegrateEbyggesok;
      }
      if (name === this.$constant.AUTOGEAR) {
        return this.planRestrictionsMixins_canIntegrateAutogear;
      }
      return false;
    },
    planRestrictionsMixins_canAddWidget(name) {
      if (this.$constant.ADD_HSE_TEMPLATES_WIDGET.name === name) {
        return this.planRestrictionsMixins_canAddDocumentTemplates;
      }
      if (this.$constant.ADD_FORM_TEMPLATES_WIDGET.name === name) {
        return this.planRestrictionsMixins_canAddFormTemplates;
      }
      if (this.$constant.AUTOGEAR_VEHICLE_WIDGET.name === name) {
        return this.planRestrictionsMixins_canIntegrateAutogear;
      }
      return true;
    },
    planRestrictionsMixins_planCodeByIntegrationName(name) {
      const isProIntegration = this.$constant.PRO_INTEGRATIONS.includes(name);
      if (isProIntegration) return this.$constant.PLAN_CODE.GRIPR_PRO;
      return this.$constant.PLAN_CODE.GRIPR_FREE;
    },
  },
};
