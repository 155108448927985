<template>
  <AppMenuItem
    :text="$t('settings.billing.invoiceHistory.viewInvoice')"
    :icon="$icon.REGULAR.FILE.FILE_INVOICE"
    @click="click"
  />
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
