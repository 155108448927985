<template>
  <div style="height: calc(100% - 68px)" class="overflow-y-auto">
    <AppCenterLoader
      class="ma-0"
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'100%'"
    />

    <template v-if="!isLoading">
      <div class="d-flex flex-column pa-4">
        <div class="mb-4">
          <div class="mb-1 d-flex">
            <div class="font-weight-bold body-2">
              {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.sentOn") }}
            </div>
            <v-spacer />

            <AppColorChip
              style="max-height: 24px"
              :text="completedChipText()"
              color="success--text"
            />
          </div>
          <div>
            {{ createdDate | dateFormat("DD.MM.YYYY HH:mm") }}
          </div>
        </div>

        <div class="mb-4">
          <div class="mb-1 font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.completedOn") }}
          </div>
          <div>
            {{ completedDate | dateFormat("DD.MM.YYYY HH:mm") }}
          </div>
        </div>

        <div class="mb-4">
          <div class="mb-1 font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.sentBy") }}
          </div>
          <AppUserAvatar :user="sender" :truncate="30" show-name />
        </div>

        <div class="font-weight-bold body-2">
          {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.signedBy") }}
        </div>

        <div>
          <v-divider class="my-2" />
          <div v-for="(signer, index) of signers" :key="index">
            <div class="d-flex flex-column">
              <div class="mb-1">
                <div class="d-flex">
                  <span class="text-caption">{{ $t("common.name") }}</span>
                </div>
                <AppTextTruncateValue
                  :disabled="signer.name.length <= truncateLength"
                  :text="signer.name"
                  :truncate="truncateLength"
                />
              </div>
              <div>
                <div class="d-flex">
                  <span class="text-caption">{{ $t("common.email") }}</span>
                </div>
                <a
                  class="underline-on-hover primary-link--text"
                  :href="`mailto:${signer.email}`"
                  target="_blank"
                >
                  <AppTextTruncateValue
                    :disabled="signer.email.length <= truncateLength"
                    :text="signer.email"
                    :truncate="truncateLength"
                  />
                </a>
              </div>
            </div>
            <v-divider class="my-2" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      truncateLength: 35,
    };
  },
  props: {
    storageId: String,
  },
  computed: {
    ...mapState("completedDocumentProperties", {
      properties: (state) => state.properties,
      isPropertiesLoading: (state) => state.isLoading,
    }),
    ...mapGetters("completedDocumentProperties", {
      sender: "sender",
      signers: "signers",
      createdDate: "createdDate",
      completedDate: "completedDate",
    }),
    isLoading() {
      return this.isPropertiesLoading || !this.properties;
    },
  },
  methods: {
    getDocumentProperties(storageId, load = true) {
      this.$store.dispatch("completedDocumentProperties/getProperties", {
        storageId,
        load,
      });
    },
    completedChipText() {
      const count = `${this.signers.length}/${this.signers.length}`;
      const inProgressText = `${count} ${this.$t(
        "fileManagement.preview.navMenu.e-signature.status.COMPLETED",
      )}`;

      return inProgressText;
    },
  },
  mounted() {
    this.getDocumentProperties(this.storageId);
  },
};
</script>
