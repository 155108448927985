<template>
  <AppWidgetLibraryItemTemplate
    :title="$t('widgetLibrary.urlEmbed.title')"
    :subTitle="$t('widgetLibrary.urlEmbed.subTitle')"
    :planCode="planCode"
    :showStar="showStar"
    :icon="$icon.LIGHT.COMMON.BROWSER"
    @add="onAdd"
  />
</template>

<script>
export default {
  props: {
    planCode: String,
    showStar: Boolean,
  },
  methods: {
    onAdd() {
      this.$emit("add");
    },
  },
};
</script>
