<script>
import Title from "./Title.vue";
import DocumentTemplateItem from "./DocumentTemplateItem.vue";

export default {
  props: {
    documentTemplateItems: Array,
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    removeBorderBotIfNoRows() {
      return this.documentTemplateItems.length === 0 ? "" : "border-b";
    },

    genDocumentTemplateItems() {
      const documentTemplateRows = [];
      for (const item of this.documentTemplateItems) {
        const documentTemplateRow = this.$createElement(DocumentTemplateItem, {
          props: { item },
        });
        documentTemplateRows.push(documentTemplateRow);
      }
      return this.$createElement(
        "div",
        {
          class: `border-l border-r ${this.removeBorderBotIfNoRows()} rounded-b-lg overflow-hidden}`,
          props: {
            list: this.documentTemplateItems,
          },
        },
        documentTemplateRows,
      );
    },
    showBorderAndBorderRadiusIfNoItems() {
      return this.documentTemplateItems.length === 0 ? "rounded-b-lg" : "";
    },
  },
  render(h) {
    return h("div", { class: "pb-8" }, [
      h(Title, {
        props: {
          title: this.$t(`templateManagement.project.builder.${this.type}.title`),
        },
      }),
      h("div", [
        h(
          "div",
          {
            class: `d-flex rounded-t-lg ${this.showBorderAndBorderRadiusIfNoItems()} pa-2 px-4 border-a text-subtitle-2`,
          },
          [
            h(
              "div",
              this.$t(`templateManagement.project.builder.${this.type}.tableHeader`),
            ),
          ],
        ),
        this.genDocumentTemplateItems(),
      ]),
    ]);
  },
};
</script>
