import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function updateUser({ data, id }) {
  return axios
    .put(`${apiUrl}user/${id}`, data)
    .then(({ data }) => {
      const user = data ? data.user : null;
      return user;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
