<template>
  <div class="my-2 d-flex align-center">
    <div>
      <div :class="`${fontSize} font-weight-bold pb-1`">
        {{ title }}
      </div>
      <v-skeleton-loader type="heading" v-if="isLoadingSubTitle" />
      <template v-else>
        <div class="text-body-1" v-if="vHtml" v-html="vHtml"></div>
        <div class="text-body-1" v-else>{{ subTitle }}</div>
      </template>
    </div>
    <v-spacer />
    <slot name="append"></slot>
    <div v-if="showAppend" class="d-flex align-center ml-2">
      <v-btn
        v-if="showActionBtn"
        color="primary"
        @click="$emit('action:click')"
        :disabled="isActionBtnDisabled"
        :loading="isActionBtnLoading"
        depressed
      >
        {{ actionBtnText }}
        <AppSubscriptionPlanStar
          v-if="showStar"
          class="pl-2"
          :planCode="planCode"
          small
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    large: Boolean,
    vHtml: String,
    isLoadingSubTitle: Boolean,
    showAppend: Boolean,
    showActionBtn: Boolean,
    actionBtnText: String,
    isActionBtnDisabled: Boolean,
    isActionBtnLoading: Boolean,
    showStar: Boolean,
    planCode: String,
  },
  computed: {
    fontSize() {
      if (this.large) return "text-h4";
      return "text-h5";
    },
  },
};
</script>
