<template>
  <v-dialog v-model="localDialog" max-width="600px">
    <template v-for="integration in $constant.CUSTOM_AUTH_INTEGRATIONS">
      <template v-if="integrationName === integration.name">
        <CustomAuthConfig
          :guideId="integration.guideId"
          :integrationName="integration.name"
          @cancel="cancel"
          @complete="complete"
        />
      </template>
    </template>
    <template v-if="integrationName === this.$constant.POWEROFFICE">
      <PowerofficeConfig
        guideId="N3OMEB7sCt"
        :integrationName="integrationName"
        @cancel="cancel"
        @complete="complete"
      />
    </template>
  </v-dialog>
</template>

<script>
import PowerofficeConfig from "./PowerofficeConfig.vue";
import CustomAuthConfig from "./CustomAuthConfig.vue";

export default {
  components: { PowerofficeConfig, CustomAuthConfig },
  props: {
    dialog: Boolean,
    integrationName: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    cancel() {
      this.localDialog = false;
    },
    complete(integrationName) {
      this.localDialog = false;
      this.$emit("complete", integrationName);
    },
  },
};
</script>
