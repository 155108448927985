<script>
import { VTextField } from "vuetify/lib";
export default {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "input-background",
    },
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
