export function canEditClient(client, user) {
  if (!client || !user) return false;
  if (user.currentDomain.isOwner || user.currentDomain.isAdmin) return true;
  if (client.creatorId === user.id) {
    return true;
  }
  if (client.ownerId === user.id) {
    return true;
  }

  return false;
}

export function canDeleteClient(client, user) {
  if (!client || !user) return false;
  if (user.currentDomain.isOwner || user.currentDomain.isAdmin) return true;
  if (client.creatorId === user.id) {
    return true;
  }
  return false;
}

export function clientPermissions(client, user) {
  return {
    canDelete: canDeleteClient(client, user),
    canEdit: canEditClient(client, user),
  };
}
