<template>
  <div class="information_drawer">
    <v-toolbar class="border-b" dense flat fixed height="68">
      <v-toolbar-title class="font-weight-bold">
        {{ $t("common.overview") }}
      </v-toolbar-title>
      <v-spacer />
      <AppToggleMenuBtn>
        <AppMenuItem
          :text="$t('projectView.info.useTemplate')"
          :icon="$icon.REGULAR.ACTION.ADD"
          :disabled="hasTemplate"
          @click="templatePickerDialog = true"
        />
        <AppMenuCopyUrlBtn
          :route="{
            name: this.$routeNames.PROJECT.VIEW,
            params: { projectId: this.project?.id },
          }"
        />
        <AppMenuDeleteBtn
          @click="deleteProjectDialog = true"
          :text="$t('project.deleteProject.title')"
        />
      </AppToggleMenuBtn>
      <v-btn icon @click="close">
        <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
      </v-btn>
    </v-toolbar>
    <AppCenterLoader
      v-model="isLoading"
      :title="$t('common.loading')"
      :height="'calc(100% - 68px)'"
    />
    <div class="overflow-y-auto secondary-height" v-if="project">
      <div class="pa-5 pb-0">
        <div class="p-relative">
          <div class="d-flex">
            <div class="label--small">
              {{ $t("common.address") | capitalize }}
            </div>
            <v-spacer />
            <AppAddressDetails
              v-if="addressItems.length !== 0"
              :addressItems="addressItems"
            />
          </div>
          <AppAddressCombobox
            ref="address"
            :value="project.projectAddress"
            @change="onAddressChange"
            class="pb-5"
          />
        </div>
        <AppFieldMenuDatePicker
          class="pb-5"
          hide-details
          :label="$t('projectView.info.startDate')"
          :date="project.startDate"
          :max="project.endDate"
          @date:change="onStartDateChange"
        />
        <AppFieldMenuDatePicker
          class="pb-5"
          hide-details
          :label="$t('projectView.info.estimatedEndDate')"
          :date="project.endDate"
          :min="project.startDate"
          :defaultPickerDate="project.startDate"
          @date:change="onEndDateChange"
        />
        <div class="label--small">
          {{ $t("projectView.info.projectManager") | capitalize }}
        </div>
        <AppClientGroupedUserAndContactAutocomplete
          class="mb-5"
          user-avatar
          hide-details
          :value="project.ownerId"
          :items="assignableOwners"
          :loading="isAssignableOwnersLoading"
          :labelPaths="['workTitleLabel', 'contact.relation.label']"
          @change="onProjectManagerChange"
        />
        <div class="label--small">
          {{ $t("projectView.info.customer") | capitalize }}
        </div>

        <AppClientAutocomplete
          class="pb-5"
          hide-details
          :value="project.clientId"
          :isReadyToInitialFetch="!!project"
          :type="$constant.CUSTOMER"
          :queryParams="{
            active: true,
            alwaysIncludeIds: [project?.clientId],
          }"
          @change="onClientChange"
          @created="onClientCreated"
        />

        <div class="label--small">
          {{ $t("projectView.info.projectAccess") | capitalize }}
        </div>
        <AppDefaultSelect
          :value="project.permissionLevel"
          :items="permissionLevels"
          :clearable="false"
          class="pb-5"
          item-text="name"
          item-value="value"
          hide-details
          @change="onPermissionLevelChange"
        />
        <div class="d-flex align-end">
          <div class="label--small">
            {{ $t("projectView.info.projectStatus") | capitalize }}
          </div>
          <v-spacer />
          <AppSettingsBtn
            @click.stop="onEditStatusesClick"
            :tooltip="statusMixins_tooltip('project')"
          />
        </div>
        <AppChangeCustomStatusAutocomplete
          class="pb-5"
          :value="project.statusId"
          :loading="project.isUpdatingStatus"
          model="project"
          hide-details
          @change="updateProject({ statusId: $event })"
        />
        <AppProjectAutocompleteWithLabel
          v-model="localParentId"
          :idsToExclude="[project?.id]"
          :isReadyToInitialFetch="!!project"
          hide-details
          type="mainProject"
          @change="
            {
              ({ project }) => (localParentId = project.id);
            }
          "
        />
        <div class="label--small">
          {{ $t("projectView.info.projectId") | capitalize }}
        </div>
        <div class="pb-8">{{ project.number }}</div>
      </div>
      <div v-if="!isHighestPriorityRoleContact">
        <template v-if="!isIntegrationEnabled($constant.BOLIGMAPPA)">
          <v-card
            flat
            class="pa-10 ma-5 mb-0"
            color="input-background"
            v-if="showBoligmappaActivateCard"
          >
            <div class="h-100 d-flex flex-column align-center justify-center">
              <div class="mb-2 font-weight-bold text-h6 text-center">Boligmappa</div>
              <div class="mb-8 font-weight-medium text-center">
                {{ $t("projectView.info.boligmappa.notActivatedSubTitle") }}
              </div>
              <v-btn color="primary" @click="onActivateBoligmappaClick" depressed
                >{{ $t("projectView.info.boligmappa.activateButton") }}
                <AppSubscriptionPlanStar
                  class="pl-2"
                  small
                  :planCode="$constant.PLAN_CODE.GRIPR_PRO"
                  v-if="!planRestrictionsMixins_canIntegrateBoligmappa"
                />
              </v-btn>
            </div>
          </v-card>
        </template>
        <div v-else>
          <v-toolbar class="border-b border-t" dense flat fixed height="68">
            <v-toolbar-title class="font-weight-bold">Boligmappa</v-toolbar-title>
            <v-spacer />
            <div class="mr-1">
              <AppToggleMenuBtn v-if="project.boligmappaNumber">
                <AppMenuItem
                  :text="$t('projectView.info.boligmappa.changeAddress')"
                  :icon="$icon.REGULAR.COMMON.LOCATION_DOT"
                  @click="boligmappaPropertyWizardDialog = true"
                />
                <AppMenuItem
                  :text="$t('projectView.info.boligmappa.disconnectAddress')"
                  color="error"
                  :icon="$icon.REGULAR.COMMON.LOCATION_DOT_SLASH"
                  @click="decoupleBoligmappaAddress"
                />
              </AppToggleMenuBtn>
            </div>
          </v-toolbar>
          <div v-if="!project.boligmappaNumber" class="pa-5">
            <v-btn
              block
              depressed
              color="primary"
              @click="boligmappaPropertyWizardDialog = true"
            >
              {{ $t("projectView.info.boligmappa.ConnectToAddress") }}
            </v-btn>
          </div>
          <div class="pa-5" v-else>
            <div class="font-weight-bold">
              {{ $t("projectView.info.boligmappa.boligmappaNumber") }}
            </div>
            <div class="pt-1">{{ project.boligmappaNumber }}</div>
            <div class="font-weight-bold pt-5">
              {{ $t("projectView.info.boligmappa.boligmappaAddress") }}
            </div>
            <div class="pt-1">{{ project.boligmappaAddress }}</div>
            <v-btn
              depressed
              color="primary"
              class="mt-5"
              block
              href="https://proff.boligmappa.no/Account/Logon"
              target="_blank"
            >
              {{ $t("projectView.info.boligmappa.logInToBoligmappa") }}
            </v-btn>
          </div>
        </div>
      </div>
      <AppBoligmappaPropertyWizardDialog
        v-model="boligmappaPropertyWizardDialog"
        @completed="onBoligmappaWizardCompletion"
        :project="project"
      />
      <AppTemplatePickerDialog
        v-model="templatePickerDialog"
        :type="$constant.PROJECT"
        @select="onTemplateSelect"
      />
      <AppEditCustomStatusDialog v-model="editStatusDialog" model="project" />
      <AppDeleteConfirmationDialog
        v-model="deleteProjectDialog"
        :title="$t('project.deleteProject.title')"
        :subtitle="$t('project.deleteProject.subTitle')"
        :validator="$t('common.delete').toLowerCase()"
        :validatorText="
          $t('project.deleteProject.validatorText', {
            delete: $t('common.delete').toLowerCase(),
          })
        "
        @delete="deleteProject"
      />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { decoupleBoligmappaAddress } from "@/services/project/projectService";
import { openOauth2Window } from "@/services/integration/integrationSetup/oAuth2SetupService";
import { planRestrictionsMixins, routerMixins } from "@/helpers/mixins";
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [planRestrictionsMixins, routerMixins, statusMixins],
  props: {
    project: Object,
  },
  data() {
    return {
      permissionLevel: "",
      isAssignableOwnersLoading: false,
      boligmappaPropertyWizardDialog: false,
      templatePickerDialog: false,
      deleteProjectDialog: false,
      editStatusDialog: false,
      assignableOwners: [],
      startDateMenu: false,
      permissionLevels: [
        { name: this.$t("common.permissionLevel.public"), value: "PUBLIC" },
        { name: this.$t("common.permissionLevel.restricted"), value: "RESTRICTED" },
        { name: this.$t("common.permissionLevel.private"), value: "PRIVATE" },
      ],
      activeLevels: [
        { name: this.$t("projectView.info.active"), value: true },
        { name: this.$t("projectView.info.inactive"), value: false },
      ],
      projectAddressFields: [
        "projectAddress",
        "bruksnummer",
        "gardsnummer",
        "festenummer",
        "latitude",
        "longitude",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", {
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isHighestPriorityRoleContact: "isHighestPriorityRoleContact",
    }),
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    showBoligmappaActivateCard() {
      const show = this.isDomainOwner || this.isDomainAdmin;
      return show;
    },
    isLoading() {
      return !this.project;
    },
    hasTemplate() {
      return !!this.project?.templateId;
    },
    addressItems() {
      return this.projectAddressFields
        .filter((prop) => this.project[prop])
        .map((prop) => ({ key: prop, value: this.project[prop] }));
    },
    localParentId: {
      get() {
        return this.project?.parentId;
      },
      set(parentId) {
        this.updateProject({ parentId });
      },
    },
  },
  methods: {
    async deleteProject() {
      await this.$store.dispatch("projects/deleteProject", this.project.id).then(() => {
        this.routerMixins_goBack({
          fallbackRoute: { name: this.$routeNames.PROJECT.ROOT },
        });
      });
    },
    onClientCreated({ client }) {
      this.onClientChange(client.id);
    },
    getAssignableOwners() {
      this.isAssignableOwnersLoading = true;
      const params = {
        projectId: this.project?.id,
        isAssignableAsProjectLeader: true,
        alwaysIncludeIds: [this.project?.ownerId],
      };
      getSearchUsers({ params })
        .then((users) => {
          this.assignableOwners = users;
        })
        .finally(() => (this.isAssignableOwnersLoading = false));
    },
    onActivateBoligmappaClick() {
      if (!this.planRestrictionsMixins_canIntegrateBoligmappa) {
        this.openUpgradePlanDialog();
      } else {
        openOauth2Window({ integrationName: this.$constant.BOLIGMAPPA }, () => {
          this.$store.dispatch("activeIntegrations/getActiveIntegrations");
        });
      }
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    onAddressChange(location) {
      if (location !== null && typeof location === "object") {
        const address = location.text;
        const gardsnummer = location.gardsnummer?.toString();
        const festenummer = location.festenummer?.toString();
        const bruksnummer = location.bruksnummer?.toString();
        const latitude = location.lat?.toString();
        const longitude = location.lon?.toString();

        const body = {
          address,
          gardsnummer,
          festenummer,
          bruksnummer,
          latitude,
          longitude,
        };

        this.updateProject(body);
      } else {
        const body = {
          address: location,
          gardsnummer: null,
          festenummer: null,
          bruksnummer: null,
          latitude: null,
          longitude: null,
        };
        this.updateProject(body);
      }
    },
    onClientChange(clientId) {
      this.updateProject({ clientId });
    },
    onProjectManagerChange(ownerId) {
      this.updateProject({ ownerId });
    },
    onPermissionLevelChange(permissionLevel) {
      this.updateProject({ permissionLevel });
    },
    onStartDateChange(startDate) {
      this.updateProject({ startDate });
    },
    onEndDateChange(endDate) {
      this.updateProject({ endDate });
    },
    updateProject(body, afterUpdate) {
      this.$emit("project:update", { body, afterUpdate });
    },
    projectRefresh() {
      this.$emit("project:refresh");
    },
    onBoligmappaWizardCompletion(e) {
      this.projectRefresh();
    },
    onEditStatusesClick() {
      this.editStatusDialog = true;
    },
    async decoupleBoligmappaAddress() {
      await decoupleBoligmappaAddress({ projectId: this.project?.id });
      this.projectRefresh();
    },
    onTemplateSelect({ templateId, afterTemplateSelect }) {
      this.updateProject({ templateId }, afterTemplateSelect);
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    close() {
      this.$router.replace({ name: this.$routeNames.PROJECT.INFO });
    },
  },
  mounted() {
    this.getAssignableOwners();
  },
};
</script>
