import { normalizeUser } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  user: null,
  isLoading: false,
});

const getters = {};

const actions = {
  async getUser({ state, commit }, id) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}user/${id}`)
      .then(({ data }) => {
        const user = data?.user;
        const normalizedUsers = normalizeUser(user);
        commit("setUser", normalizedUsers);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
