<template>
  <div class="p-relative">
    <div class="d-flex align-end">
      <div class="label--small">
        {{ $t("common.status") | capitalize }}
      </div>
      <v-spacer />
      <AppSettingsBtn
        @click.stop="dialog.editStatus.active = true"
        :tooltip="statusMixins_tooltip(model)"
      />
    </div>
    <AppChangeCustomStatusAutocomplete
      v-model="localValue"
      multiple
      :model="model"
      :clearable="clearable"
    />
    <AppEditCustomStatusDialog v-model="dialog.editStatus.active" :model="model" />
  </div>
</template>

<script>
import { statusMixins } from "@/helpers/mixins";

export default {
  mixins: [statusMixins],
  props: {
    value: Array,
    labelKey: String,
    clearable: Boolean,
    model: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  data() {
    return {
      dialog: {
        editStatus: {
          active: false,
        },
      },
    };
  },
};
</script>
