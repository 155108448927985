import { formatItemWithSearchStr } from "@/helpers/util/moduleItem";

export function normalizeUsers(users) {
  const usersWithSearchStr = formatItemWithSearchStr(users, "USER_AND_CONTACT");
  return (usersWithSearchStr || []).map((user) => normalizeUser(user));
}

export function normalizeUser(user) {
  return {
    ...user,
    text: user.fullName ? user.fullName : user.email,
    status: user.domainUser ? user.domainUser.status : "",
    roles: [],
    isAdmin: false,
  };
}
