import { normalizeWidgets } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  widgetLibrary: [],
  isLoading: false,
});

const getters = {};

const actions = {
  async getLibrary({ commit }, { model, modelId, notAdded = false }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}widget/templates`, { params: { model, modelId, notAdded } })
      .then(({ data }) => {
        const widgetLibrary = data?.widgetTemplates;
        const normalizedWidgetLibrary = normalizeWidgets(widgetLibrary);
        commit("setLibrary", normalizedWidgetLibrary);
        commit("loading", false);
      })
      .catch((error) => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setLibrary(state, widgetLibrary) {
    state.widgetLibrary = widgetLibrary;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const widgetLibrary = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
