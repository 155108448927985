import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, contactUserRedirectGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, USER_LIST, CONTACT_USER_LIST, TEAM_LIST } = ROUTE_NAMES.EMPLOYEE;

export const employeeRoutes = [
  {
    path: "/employee",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard(), contactUserRedirectGuard]),
    component: () =>
      import(/* webpackChunkName: "contact" */ "../../pages/EmployeesPage"),
    redirect: { name: USER_LIST },
    children: [
      {
        path: "user-list",
        name: USER_LIST,
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/EmployeesPage/UsersListPage"
          ),
      },
      {
        path: "contact-user-list",
        name: CONTACT_USER_LIST,
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/EmployeesPage/ContactUsersListPage"
          ),
      },
      {
        path: "teams-list",
        name: TEAM_LIST,
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../../pages/EmployeesPage/TeamsListPage"
          ),
      },
    ],
  },
];
