export function normalizeNotifications(notifications) {
    return notifications.map(notification => {
    return {
      ...notification,
      triggers: notification.triggers.map(trigger => ({
        isLoading: false,
        ...trigger,
      })),
    };
  });
}