import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getConstructionCases(projectId) {
  return axios
    .get(`${apiUrl}ebyggesok/${projectId}/construction-cases`)
    .then(({ data }) => {
      const { cases } = data;
      return cases;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
