<template>
  <div v-if="isRenaming && isEditStatus" class="block-draggable">
    <AppDefaultTextField
      ref="statusNameInput"
      @blur="onRenameEnd"
      @keypress.enter="$refs.statusNameInput.blur()"
      :value="editStatus.name"
      @change="$emit('editStatus:nameChange', $event)"
      autofocus
      hide-details
    />
  </div>
  <div v-else @click="onRenameStart" class="cursor-text">
    <v-card
      class="border-a d-flex align-center rounded input-background--background"
      style="height: 40px"
      flat
    >
      <div :class="`cursor-grab pa-3 d-flex`">
        <v-icon>
          {{ $icon.SOLID.ACTION.DRAG }}
        </v-icon>
      </div>
      <v-menu
        ref="menu"
        v-model="showColorMenu"
        offset-x
        nudge-left="5px"
        nudge-top="5px"
        max-width="325"
        :elevation="9"
        :closeOnContentClick="false"
      >
        <template v-slot:activator="{ attrs, on }">
          <div
            v-bind="attrs"
            v-on="on"
            class="d-flex justify-center py-3 px-2 mr-2 block-draggable"
          >
            <v-icon :color="status.color">
              {{ $icon.SOLID.ACTION.STATUS }}
            </v-icon>
          </div>
        </template>
        <v-card
          class="pa-3 ui-background d-flex flex-column"
          style="min-width: 70px"
          elevation="9"
        >
          <div class="d-flex align-center">
            <div style="font-size: 14px" class="user-select-none">
              <AppTextTruncateValue :text="status.name" :truncate="33" />
            </div>

            <v-spacer />
            <v-btn small icon @click="onCustomColorClick" class="mr-1">
              <v-icon>{{ $icon.REGULAR.ACTION.CUSTOM_COLOR }}</v-icon>
            </v-btn>
            <v-btn small icon @click="showColorMenu = false">
              <v-icon>{{ $icon.REGULAR.ACTION.CLOSE }}</v-icon>
            </v-btn>
          </div>
          <div class="d-flex flex-wrap">
            <v-color-picker
              class="mt-1 mb-1"
              v-if="showColorPicker"
              v-model="selectedColor"
            />
            <div
              v-for="color of predefinedColors"
              @click="onChangeColorClick(color)"
              class="cursor-pointer"
              :class="
                color === selectedColor
                  ? 'outline-a rounded input-background--background'
                  : ''
              "
            >
              <v-icon :color="color" style="padding: 5px">
                {{ $icon.SOLID.ACTION.STATUS }}
              </v-icon>
            </div>
            <div
              v-if="hasSelectedNoPredefinedColors"
              class="cursor-pointer"
              :class="
                hasSelectedNoPredefinedColors
                  ? 'outline-a rounded input-background--background'
                  : ''
              "
            >
              <v-icon :color="selectedColor" style="padding: 5px">
                {{ $icon.SOLID.ACTION.STATUS }}
              </v-icon>
            </div>
          </div>
          <v-btn
            depressed
            small
            color="primary"
            @click="onSave"
            class="mt-2"
            :disabled="isColorSaved"
            >{{ isColorSaved ? $t("common.saved") : $t("common.save") }}</v-btn
          >
        </v-card>
      </v-menu>
      <div class="user-select-none">
        <AppTextTruncateValue :text="status.name" :truncate="56" />
      </div>
      <v-spacer />
      <span class="block-draggable">
        <AppToggleMenuBtn>
          <AppMenuEditBtn :text="$t('common.rename')" @click="onRenameStart" />
          <AppMenuItem
            :text="$t('common.changeColor')"
            :icon="$icon.REGULAR.ACTION.COLOR"
            @click="showColorMenu = true"
          />
          <AppMenuDeleteBtn @click="onDeleteClick" />
        </AppToggleMenuBtn>
      </span>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    status: Object,
    editStatus: Object,
    isRenaming: Boolean,
    predefinedColors: Array,
  },
  data() {
    return {
      showColorMenu: false,
      showColorPicker: false,
      selectedColor: this.status.color,
      clickTimeoutId: null,
    };
  },
  watch: {
    "status.color": {
      handler(val) {
        this.selectedColor = val;
      },
    },
  },
  computed: {
    isEditStatus() {
      return this.editStatus?.id === this.status.id;
    },
    hasSelectedNoPredefinedColors() {
      return !this.predefinedColors.some((color) => color === this.selectedColor);
    },
    isStatusColorNotPredefinedColors() {
      return !this.predefinedColors.some((color) => color === this.status.color);
    },
    isColorSaved() {
      return this.status?.color === this.selectedColor;
    },
  },
  methods: {
    onCustomColorClick() {
      this.showColorPicker = !this.showColorPicker;
      this.onResize();
    },
    onDeleteClick() {
      this.$emit("delete:click");
    },
    onChangeColorClick(color) {
      this.selectedColor = color;
    },
    onRenameStart() {
      this.showColorMenu = false;
      this.$emit("rename:start");
    },
    onRenameEnd() {
      const shouldUpdate =
        this.status.name !== this.editStatus.name && this.editStatus?.name.trim() !== "";
      this.$emit("rename:end", { shouldUpdate });
    },
    onResize() {
      this.$refs.menu.updateDimensions();
    },
    onSave() {
      this.$emit("changeColor:click", this.selectedColor);
    },
  },
};
</script>
