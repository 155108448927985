import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;
import { base64ToBlobObjectUrl } from "../../../../helpers/util";
import { normalizeInvoices } from "@/helpers/normalization";

const state = () => ({
  isLoading: false,
  invoices: [],
});

const getters = {};

const actions = {
  async getInvoiceHistory({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}billing/invoice`)
      .then(({ data }) => {
        const invoices = normalizeInvoices(data.invoices);
        commit("setInvoices", invoices);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  async getInvoiceHistoryById({ commit }, invoiceId) {
    commit("updateOpeningPdf", { id: invoiceId, isOpeningPdf: true });
    return axios
      .get(`${apiUrl}billing/invoice/${invoiceId}/pdf`, {
        responseType: "blob",
      })
      .then(async ({ data }) => {
        const blobUrl = URL.createObjectURL(data);
        return blobUrl;
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("updateOpeningPdf", { id: invoiceId, isOpeningPdf: false });
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setInvoices(state, invoices) {
    state.invoices = invoices;
  },
  updateOpeningPdf(state, { id, isOpeningPdf }) {
    const invoice = state.invoices.find((invoice) => invoice.id === id);
    if (invoice) {
      invoice.isOpeningPdf = isOpeningPdf;
    }
  },
};

export const invoiceHistory = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
