import { mapGetters } from "vuex";
import { setLocalStorageDataTableOptions } from "../constants";

export const filtersMixins = {
  //any components that use this mixin must have this.tableModel for it to work
  methods: {
    filtersMixins_filterByKey(key) {
      const filters = this.filtersMixins_filters;
      if (!filters) return null;
      return filters[key];
    },
    filtersMixins_updateFilters({ filters, dataTableOptions }) {
      this.$store.dispatch(`filters/updateFiltersByModel`, {
        model: this.tableModel,
        filters: filters,
        dataTableOptions: dataTableOptions,
        key: "filtersCollection",
      });

      setLocalStorageDataTableOptions({ model: this.tableModel, ...dataTableOptions });
    },
    filtersMixins_resetToSaved() {
      this.$store.dispatch(`filters/resetFiltersByModel`, {
        model: this.tableModel,
        key: "savedFiltersCollection",
      });
    },
    filtersMixins_resetToDefault() {
      this.$store.dispatch(`filters/resetFiltersByModel`, {
        model: this.tableModel,
        key: "defaultFiltersCollection",
      });
    },
    filtersMixins_getFilters() {
      this.$store.dispatch(`filters/getFilters`, { model: this.tableModel });
    },
    filtersMixins_debounceSearch(search) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filtersMixins_updateFilters({
          filters: { search: search },
          dataTableOptions: this.filtersMixins_dataTableOptions,
        });
      }, 600);
    },
    filtersMixins_saveFilter() {
      this.$store.dispatch("filters/saveFilter", {
        model: this.tableModel,
        filter: this.filtersMixins_filters,
        dataTableOptions: this.filtersMixins_dataTableOptions,
      });
    },
  },
  computed: {
    filtersMixins_filters() {
      return this.filtersByModel(this.tableModel, "filtersCollection");
    },
    filtersMixins_appliedFiltersCount() {
      return this.appliedFiltersCountByModel(this.tableModel, "filtersCollection");
    },
    filtersMixins_dataTableOptions() {
      const dataTableOptions = this.dataTableOptionsByModel(this.tableModel) || {};
      return dataTableOptions;
    },
    ...mapGetters("filters", {
      filtersByModel: "filtersByModel",
      appliedFiltersCountByModel: "appliedFiltersCountByModel",
      dataTableOptionsByModel: "dataTableOptionsByModel",
      loading: "isLoading",
      saving: "isSaving",
    }),
    filtersMixins_isLoadingFilters() {
      return this.loading(this.tableModel);
    },
    filtersMixins_isSavingFilters() {
      return this.saving(this.tableModel);
    },
    filtersMixins_isSaved() {
      const currentFilters = {
        ...this.filtersByModel(this.tableModel, "filtersCollection"),
      };
      const savedFiltersCollection = {
        ...this.filtersByModel(this.tableModel, "savedFiltersCollection"),
      };

      delete currentFilters.search;
      delete savedFiltersCollection.search;

      return JSON.stringify(currentFilters) === JSON.stringify(savedFiltersCollection);
    },
    filtersMixins_dataFields() {
      return this.$constant.TABLE_HEADER[this.tableModel];
    },
    filtersMixins_stickyCols: {
      get() {
        return this.filtersMixins_dataTableOptions.stickyCols;
      },
      set(value) {
        this.filtersMixins_updateFilters({
          dataTableOptions: { ...this.filtersMixins_dataTableOptions, stickyCols: value },
        });
      },
    },
  },
};
