import { mapState } from "vuex";

export const routerMixins = {
  computed: {
    ...mapState("route", {
      visitedRoutes: (state) => state.visitedRoutes,
    }),
  },
  methods: {
    routerMixins_navigateToToggle(to, from) {
      if (this.$route.name === to?.name) {
        return from;
      }
      return to;
    },
    routerMixins_navigate(path) {
      if (typeof path === "string") {
        this.$router
          .push({
            path,
          })
          .catch((err) => {});
      } else {
        this.$router.push(path).catch((err) => {});
      }
    },
    routerMixins_replace(path) {
      if (typeof path === "string") {
        this.$router
          .replace({
            path,
          })
          .catch((err) => {});
      } else {
        this.$router.replace(path).catch((err) => {});
      }
    },
    routerMixins_goBack({ fallbackRoute }) {
      const visitedRoutes = this.visitedRoutes;
      const hasInternalHistory = visitedRoutes.length > 0;

      if (hasInternalHistory) {
        //this.$store.commit("route/removeLastRouteFromHistory");
        this.$router.go(-1);
      } else {
        this.routerMixins_replace(fallbackRoute);
      }
    },
  },
};
