export const labelMixins = {
  methods: {
    labelMixins_translationPath(labelType) {
      if (labelType === this.$constant.LABEL_TYPE.JOB_TITLE) {
        return "users.label.jobTitle";
      } else if (labelType === this.$constant.LABEL_TYPE.RESPONSIBILITY) {
        return "contact.contactPerson.label.responsibility";
      }
    },
  },
};
