<template>
  <div class="d-flex flex-column mw-650px">
    <div class="text-h4 pb-2 text-center font-weight-bold">
      {{ $t(`integration.${importType}.${state}.title`) }}
    </div>
    <div class="text-center">
      {{
        error
          ? `${error}, ${$t(`integration.${importType}.${state}.title`)}`
          : $t(`integration.${importType}.${state}.subTitle`)
      }}
    </div>
    <div class="mt-10">
      <div v-if="reportPath" class="d-flex flex-column justify-center align-center">
        <div class="mb-2">
          {{ $t(`integration.${importType}.${state}.downloadReportTitle`) }}
        </div>
        <v-btn
          class="mb-4"
          depressed
          color="primary"
          v-if="reportPath"
          @click="downloadReport"
          >{{ $t(`integration.${importType}.${state}.downloadReportButton`) }}</v-btn
        >
      </div>
      <div class="text-center">
        <v-btn color="background" depressed @click="onImport">
          {{ $t(`integration.${importType}.${state}.tryAgain`) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadProductErrorReport } from "@/services/product/productDownloadErrorReport";
export default {
  props: {
    error: Error,
    importType: String,
    state: String,
    reportPath: String,
  },
  methods: {
    onImport() {
      this.$emit("import:click");
    },
    downloadReport() {
      downloadProductErrorReport({ reportUrl: this.reportPath });
    },
  },
};
</script>
