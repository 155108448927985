import i18n from "@/plugins/i18n";

const categoriesTranslation = i18n.t("cms.template.document.category");

export function normalizeCmsDocumentTemplateCategories(categories) {
  let normalizedCategories = (categories || []).map((item) => {
    const name = item.name;
    const translatedName = categoriesTranslation[name?.toLowerCase()];
    return {
      ...item,
      value: name,
      text: translatedName ? translatedName : name ? name : "undefined",
    };
  });

  return normalizedCategories;
}
