<template>
  <v-dialog v-model="localDialog" maxWidth="520">
    <v-card class="d-flex flex-column align-center pa-10">
      <v-card-text>
        <AppCenterLoader v-model="dialog" :title="title" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    title: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
};
</script>
