<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { getSearchUsers } from "@/services/search/users";
import { getTeams } from "@/services/team/assignableTeams";
import { filtersItemsMixins } from "@/helpers/mixins";
import { mapGetters } from "vuex";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
    hideProjectLevel: Boolean,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      managers: [],
      isManagersLoading: false,
      members: [],
      isMembersLoading: false,
      creators: [],
      isCreatorsLoading: false,

      teams: [],
      isTeamsLoading: false,
    };
  },
  methods: {
    getData() {
      this.getManagers();
      this.getMembers();
      this.getCreators();
      this.getTeams();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getManagers() {
      this.isManagersLoading = true;
      const managerIds = this.managerIds;
      const params = { alwaysIncludeIds: managerIds };
      getSearchUsers({ params })
        .then((users) => {
          this.managers = users;
        })
        .finally(() => {
          this.isManagersLoading = false;
        });
    },
    async getMembers() {
      this.isMembersLoading = true;
      const memberIds = this.memberIds;
      const params = { alwaysIncludeIds: memberIds };
      getSearchUsers({ params })
        .then((users) => {
          this.members = users;
        })
        .finally(() => {
          this.isMembersLoading = false;
        });
    },
    async getCreators() {
      this.isCreatorsLoading = true;
      const creatorIds = this.creatorIds;
      const params = { alwaysIncludeIds: creatorIds };
      getSearchUsers({ params })
        .then((users) => {
          this.creators = users;
        })
        .finally(() => {
          this.isCreatorsLoading = false;
        });
    },
    async getTeams() {
      this.isTeamsLoading = true;
      getTeams()
        .then((teams) => {
          this.teams = teams;
        })
        .finally(() => {
          this.isTeamsLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters("activeIntegrations", {
      hasProjectImportIntegrationEnabled: "hasProjectImportIntegrationEnabled",
    }),
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    projectIds() {
      return this.filtersMixins_filterByKey("projectIds") ?? [];
    },
    managerIds() {
      return this.filtersMixins_filterByKey("mangagerIds") ?? [];
    },
    memberIds() {
      return this.filtersMixins_filterByKey("memberIds") ?? [];
    },
    creatorIds() {
      return this.filtersMixins_filterByKey("creatorIds") ?? [];
    },
    clientIds() {
      return this.filtersMixins_filterByKey("clientIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppCustomStatusFilter",
          labelKey: "filters.projectFilters.status",
          filterValueKey: "statusIds",
          itemValue: "value",
          itemText: "text",
          clearable: true,
          model: "project",
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.projectFilters.projectManager",
          filterValueKey: "mangagerIds",
          itemValue: "id",
          itemText: "name",
          items: this.managers ?? null,
          loading: this.isManagersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.projectFilters.teams",
          filterValueKey: "teamIds",
          itemValue: "id",
          itemText: "name",
          items: this.teams ?? null,
          loading: this.isTeamsLoading,
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.projectFilters.members",
          filterValueKey: "memberIds",
          itemValue: "id",
          itemText: "name",
          items: this.members ?? null,
          loading: this.isMembersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientMultiAutocomplete",
          labelKey: "filters.projectFilters.customer",
          filterValueKey: "clientIds",
          itemValue: "id",
          itemText: "name",
          isReadyToInitialFetch: this.isReadyToInitialFetch,
          type: this.$constant.CUSTOMER,
          queryParams: {
            alwaysIncludeIds: this.clientIds,
          },
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.projectFilters.creator",
          filterValueKey: "creatorIds",
          itemValue: "id",
          itemText: "name",
          items: this.creators ?? null,
          loading: this.isCreatorsLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.startDate",
          filterValueKey: "startDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItemsWithFutureDates,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.endDate",
          filterValueKey: "endDate",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_dateItemsWithFutureDates,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.createdDate",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "createdDate",
          items: this.filtersItemsMixins_dateItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.projectAccess",
          filterValueKey: "projectPermissionLevel",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_projectPermissionLevels,
        },
        ...(!this.hideProjectLevel
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.projectFilters.projectLevel",
                filterValueKey: "levelType",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_projectLevels,
              },
            ]
          : []),
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.isBoligmappaActive",
          filterValueKey: "isActiveBoligmappa",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.projectFilters.HasAddress",
          filterValueKey: "hasAddress",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        ...(this.hasProjectImportIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.projectFilters.isSynchronized",
                itemValue: "value",
                itemText: "text",
                filterValueKey: "isSynchronized",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
      ];

      return children;
    },
  },
};
</script>
