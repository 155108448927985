<script>
import { VForm } from "vuetify/lib";
import { formTemplateBuilderMixins } from "@/helpers/mixins";
export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
  },
  data() {
    return {
      localValue: this.item?.value ?? [],
      isValid: false,
    };
  },
  computed: {
    rules() {
      let rules = [];
      if (this.item?.isRequired) {
        rules = this.$rules.REQUIRED;
      }
      return rules;
    },
  },
  watch: {
    "item.value": {
      handler(value) {
        if (!Array.isArray(value)) return;
        this.localValue = [...value];
      },
      immediate: true,
    },
  },
  methods: {
    onChange(e) {
      this.localValue = e;

      this.formTemplateBuilderMixins_updateItem({
        item: this.item,
        body: { value: this.localValue },
      });
    },
    genForm() {
      return this.$createElement(
        VForm,
        {
          props: { value: this.isValid },
          on: {
            submit: (e) => e.preventDefault(),
            input: (e) => (this.isValid = e),
          },
        },
        [
          this.$createElement("AppDefaultSelect", {
            props: {
              value: this.localValue,
              items: this.item?.values,
              multiple: true,
              selectAll: true,
              dense: false,
            },
            attrs: {
              rules: this.rules,
              placeholder: this.$t(
                "templateManagement.form.builder.item.type.MULTI_CHOICE",
              ),
              hideDetails: true,
            },
            on: { change: (e) => this.onChange(e) },
          }),
        ],
      );
    },
  },
  render(h) {
    return h("div", [this.genForm()]);
  },
};
</script>
