import { normalizeUsers } from "@/helpers/normalization";
import i18n from "@/plugins/i18n";
import store from "@/store";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  users: [],
  isLoading: false,
  isInviting: false,
  isUpdatingStatus: false,
  filter: null,
});

const getters = {};

const actions = {
  async getUsers({ commit, dispatch }, { filters }) {
    commit("isLoading", true);
    return axios
      .get(`${apiUrl}user`, {
        params: { ...filters },
      })
      .then(({ data }) => {
        const users = normalizeUsers(data);

        commit("setUsers", users);
        for (let user of users) {
          dispatch("getUserRoles", { userId: user.id });
        }
        commit("isLoading", false);
      })
      .catch((error) => {
        commit("isLoading", false);
        throw new Error(error);
      });
  },
  async getUserRoles({ commit }, { userId }) {
    return axios.get(`${apiUrl}role-user/${userId}`).then(({ data }) => {
      const roles = (data.roleUsers || []).map((roleUser) => ({ ...roleUser.role }));

      const isRole = (role) => roles.some((r) => r.type === role);
      const isAdmin = isRole("ADMIN");
      const isHrAdmin = isRole("HR_ADMIN");
      const isContact = isRole("CONTACT");

      commit("addRoles", { userId, roles, isAdmin, isHrAdmin, isContact });
    });
  },
  async addRole({ commit }, { userId, role }) {
    return axios.post(`${apiUrl}role-user`, { userId, role }).then(({ data }) => {});
  },
  async removeRole({ commit }, { userId, role }) {
    return axios
      .delete(`${apiUrl}role-user`, { data: { userId, role } })
      .then(({ data }) => {});
  },
  async inviteUser({ dispatch, commit }, { body }) {
    commit("inviting", true);
    return axios
      .post(`${apiUrl}auth/invite`, { ...body })
      .then(({ data }) => {
        const { user } = data;

        commit("updateUser", user);
        dispatch("getUserRoles", { userId: user.id });
        dispatch("auth/getUser", null, { root: true });
        store.dispatch("snackbar/snackbar", {
          show: true,
          text: i18n.t("users.userInvite.inviteSent"),
          color: "success",
        });
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("inviting", false);
      });
  },
  async updateDomainUser({ commit, dispatch, state }, { id, status, labelId }) {
    const oldUser = state.users.find((user) => user.id === id);

    if (status) {
      commit("updatingStatus", true);
    }
    if (labelId || labelId === null) {
      commit("updateUser", { ...oldUser, isUpdatingLabel: true });
    }

    return axios
      .put(`${apiUrl}user/${id}/domain-user`, { status, labelId })
      .then(({ data }) => {
        commit("updateUser", data);
        dispatch("getUserRoles", { userId: data.id });
        dispatch("auth/getUser", null, { root: true });
      })
      .catch((error) => {
        commit("updateUser", oldUser);
        throw new Error(error);
      })
      .finally(() => {
        commit("updatingStatus", false);
      });
  },
  resetState({ commit }) {
    commit("setUsers", []);
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  updateUser(state, user) {
    const index = state.users.findIndex((oldUser) => oldUser.id === user.id);
    const newUser = {
      ...user,
      status: user.domainUser ? user.domainUser.status : "",
    };
    if (index !== -1) {
      state.users.splice(index, 1, newUser);
    } else {
      state.users.unshift(newUser);
    }
  },
  addRoles(state, { userId, roles, isAdmin, isHrAdmin, isContact }) {
    const index = state.users.findIndex((user) => user.id === userId);
    if (index !== -1) {
      state.users.splice(index, 1, {
        ...state.users[index],
        roles,
        isAdmin,
        isHrAdmin,
        isContact,
      });
    }
  },
  isLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  inviting(state, isInviting) {
    state.isInviting = isInviting;
  },
  updatingStatus(state, isUpdatingStatus) {
    state.isUpdatingStatus = isUpdatingStatus;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
};

export const settingsUsers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
