<template>
  <AppDefaultCombobox
    :placeholder="placeholder ?? $t('common.searchOrWriteFreeText')"
    v-model="localValue"
    :items="locations"
    :search-input.sync="search"
    :loading="isLoading"
    item-text="text"
    :hide-details="hideDetails"
    :dense="dense"
    :rules="rules"
    :backgroundColor="backgroundColor"
    hide-no-data
    no-filter
    return-object
  />
</template>

<script>
import { kartdataAutocomplete } from "../../services/integration/kartdata/kartdataService";
export default {
  props: {
    value: [String, Object],
    dense: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rules: Array,
    placeholder: String,
    backgroundColor: String,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      locations: [],
      search: null,
      searchDebounce: null,
      isLoading: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  watch: {
    search: {
      handler(newVal) {
        this.debounceSearch(newVal);
      },
    },
  },
  methods: {
    debounceSearch(newVal) {
      this.isLoading = true;
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(async () => {
        if (!newVal || newVal.length < 1) {
          this.locations = [];
          this.isLoading = false;
          return;
        }
        this.locations = await kartdataAutocomplete(newVal);
        this.isLoading = false;
      }, 400);
    },
  },
  mounted() {
    if (this.value) {
      this.locations = [{ id: this.value, text: this.value }];
    }
  },
};
</script>
