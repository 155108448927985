import axios from "axios";
const apiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

export async function getAuthUrl({ integrationName, code }) {
  return axios
    .get(`${apiUrl}oauth2/auth`, { params: { integrationName, code } })
    .then(({ data }) => {
      return data.url;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function openOauth2Window({ integrationName, code }, callback) {
  return await getAuthUrl({ integrationName, code })
    .then((url) => {
      const options = "toolbar=no, menubar=no, width=420, height=700, top=100, left=100";
      const configWindow = window.open(url, "oauth2", options);

      const timer = setInterval(() => {
        if (configWindow.closed) {
          clearInterval(timer);
          callback();
        }
      }, 1000);
    })
    .catch((error) => {
      // callback();
    });
}

export async function authorize({ url }) {
  return axios
    .get(url)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
