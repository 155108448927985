<template>
  <AppMenuItem
    :text="text || $t('common.delete')"
    color="error"
    :icon="$icon.REGULAR.ACTION.DELETE"
    :disabled="disabled"
    @click="click"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    text: String,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
