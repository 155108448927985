<template>
  <AppMenuItem
    :text="$t('common.saveWord')"
    :icon="$icon.REGULAR.FILE.FILE_WORD"
    :disabled="disabled"
    @click="click"
    :showStar="showStar"
    :planCode="$constant.PLAN_CODE.GRIPR_PRO"
  />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    showStar: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
