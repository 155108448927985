import i18n from "@/plugins/i18n";

export function normalizeIntegrations(integrations) {
  return (integrations || []).map((integration) => {
    const integrationTitle = i18n.t(`settings.integrations.${integration?.name}.title`);
    const searchStr = `${integrationTitle}`.toLowerCase();
    return {
      ...integration,
      isUpdatingActive: false,
      searchStr,
    };
  });
}
