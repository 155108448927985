import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import helpers from "./plugins/helpers";
import i18n from "./plugins/i18n";
import packageJson from "../package.json";
import { Auth0Plugin } from "./auth";
import {
  axiosAuthSetup,
  axiosErrorHandlingSetup,
  axiosBoligmappaResponseSetup,
} from "./helpers/interceptors";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueApexCharts from "vue-apexcharts";
import Rollbar from "rollbar";

import "./styles/main.scss";
import "./helpers/registerAppComponents";
import "./helpers/animation/customAnimation";

require("./plugins/googleMaps");

Vue.use(helpers);

const scope = "openid email profile read:current_user update:current_user_identities";
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: { audience: process.env.VUE_APP_AUTH0_AUDIENCE, scope },
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
    );
  },
});

Vue.use(VueFilterDateFormat);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

axiosAuthSetup();
axiosErrorHandlingSetup();
axiosBoligmappaResponseSetup();

Vue.config.productionTip = false;

if (location.hostname !== "localhost") {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
      code_version: packageJson.version,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: packageJson.version,
          guess_uncaught_frames: true,
        },
      },
    },
  });

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err;
  };
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.addEventListener("popstate", (e) => {
  store.commit("route/removeLastRouteFromHistory");
});
