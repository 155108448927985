<template>
  <div>
    <v-card
      flat
      class="w-100 border-a"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        min-height: 158px;
      "
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
    >
      <AppSubscriptionPlanStar
        v-if="showStar"
        style="position: absolute; top: 10px; right: 10px"
        :planCode="planCode"
        small
      />
      <v-row
        :style="{ opacity: transparency }"
        class="ma-0 w-100 py-3 px-9"
        align-content="center"
        justify="center"
        no-gutters
      >
        <v-col class="text-center pb-2 pt-0" cols="12">
          <v-icon
            :style="isLogo ? 'height: 20px;' : ''"
            :size="isLogo ? 100 : 26"
            class="align-center justify-center"
          >
            {{ icon }}
          </v-icon>
        </v-col>
        <v-col class="text-center pb-0 pt-0" cols="12">
          <span class="font-weight-bold user-select-none">{{ title }}</span>
        </v-col>
        <v-col class="text-center pb-0 pt-0 text-body-2" cols="12">
          <span class="user-select-none">{{ subTitle }}</span>
        </v-col>
      </v-row>
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          justify-content: center;
          align-items: center;
        "
        v-show="isHovering"
      >
        <v-btn :disabled="!isHovering" class="primary" @click.stop="onAdd">{{
          computedButtonText
        }}</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    isLogo: Boolean,
    title: String,
    subTitle: String,
    showStar: Boolean,
    planCode: String,
    buttonText: String,
  },
  data() {
    return {
      isClicked: false,
      isHovering: false,
    };
  },
  computed: {
    transparency() {
      return this.isHovering ? "0.3" : "1";
    },
    computedButtonText() {
      if (this.buttonText) {
        return this.buttonText;
      }
      return this.$t(!this.showStar ? "common.add" : "common.upgrade");
    },
  },
  methods: {
    onAdd() {
      if (this.showStar) {
        this.openUpgradePlanDialog();
      } else {
        this.$emit("add");
      }
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.planCode,
      });
    },
    setHover(isHovering) {
      setTimeout(() => {
        this.isHovering = isHovering;
      }, 3);
    },
  },
};
</script>
