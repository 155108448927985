<template>
  <AppFiltersDrawer
    v-if="localShowDrawer"
    @close="closeFilters"
    :tableModel="tableModel"
    :children="children"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { getSearchUsers } from "@/services/search/users";
import { filtersItemsMixins } from "@/helpers/mixins";
import { filtersMixins } from "@/helpers/mixins";
import { filtersWatchMixins } from "@/helpers/mixins";

export default {
  mixins: [filtersItemsMixins, filtersMixins, filtersWatchMixins],
  props: {
    showDrawer: Boolean,
    tableModel: String,
  },
  model: {
    prop: "showDrawer",
    event: "change",
  },
  data() {
    return {
      owners: [],
      isOwnersLoading: false,
      creators: [],
      isCreatorsLoading: false,
    };
  },
  methods: {
    getData() {
      this.getOwners();
      this.getCreators();
    },
    closeFilters() {
      this.localShowDrawer = false;
    },
    async getOwners() {
      this.isOwnersLoading = true;

      const params = {
        alwaysIncludeIds: this.ownerIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.owners = users;
        })
        .finally(() => {
          this.isOwnersLoading = false;
        });
    },
    async getCreators() {
      this.isCreatorsLoading = true;
      const params = {
        alwaysIncludeIds: this.creatorIds,
      };
      getSearchUsers({ params })
        .then((users) => {
          this.creators = users;
        })
        .finally(() => {
          this.isCreatorsLoading = false;
        });
    },
  },
  computed: {
    localShowDrawer: {
      get() {
        return this.showDrawer;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasClientImportIntegrationEnabled: "hasClientImportIntegrationEnabled",
    }),
    creatorIds() {
      return this.filtersMixins_filterByKey("creatorIds") ?? [];
    },
    ownerIds() {
      return this.filtersMixins_filterByKey("ownerIds") ?? [];
    },
    children() {
      const children = [
        {
          is: "AppFiltersSelect",
          labelKey: "filters.clientFilters.active",
          filterValueKey: "isActive",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_isActiveItems,
        },
        {
          is: "AppFiltersMultiAutocomplete",
          labelKey: "filters.clientFilters.type",
          filterValueKey: "types",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_clientTypeItems,
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.clientFilters.owner",
          filterValueKey: "ownerIds",
          itemValue: "id",
          itemText: "name",
          items: this.owners ?? null,
          loading: this.isOwnersLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersClientGroupedUserAndContactAutocomplete",
          labelKey: "filters.clientFilters.creator",
          filterValueKey: "creatorIds",
          itemValue: "id",
          itemText: "name",
          items: this.creators ?? null,
          loading: this.isCreatorsLoading,
          userAvatar: true,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.clientFilters.hasProject",
          filterValueKey: "hasProject",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.clientFilters.hasEmail",
          filterValueKey: "hasEmail",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.clientFilters.hasPhone",
          filterValueKey: "hasPhone",
          itemValue: "value",
          itemText: "text",
          items: this.filtersItemsMixins_yesOrNoItems,
        },
        {
          is: "AppFiltersSelect",
          labelKey: "filters.clientFilters.createdDate",
          itemValue: "value",
          itemText: "text",
          filterValueKey: "createdDate",
          items: this.filtersItemsMixins_dateItems,
        },
        ...(this.hasClientImportIntegrationEnabled
          ? [
              {
                is: "AppFiltersSelect",
                labelKey: "filters.clientFilters.isSynchronized",
                filterValueKey: "isSynchronized",
                itemValue: "value",
                itemText: "text",
                items: this.filtersItemsMixins_yesOrNoItems,
              },
            ]
          : []),
      ];

      return children;
    },
  },
};
</script>
