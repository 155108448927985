<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $t("fileManagement.fileExplorer.editFile.title")
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editFileForm"
          v-model="file.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("fileManagement.fileExplorer.editFile.nameLabel") }}
            </div>
            <AppDefaultTextField
              ref="editFileFormName"
              v-model="file.name.value"
              :rules="$rules.NAME_RULES"
              :counter="$rules.MAX_CHARACTERS"
              autofocus
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    data: Object,
    nameMaxInput: {
      type: Number,
      default: 255,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.file.name.value = newVal ? newVal.name : "";
      },
    },
  },
  data() {
    return {
      file: {
        valid: false,
        name: {
          value: "",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.editFileForm.resetValidation();
    },
    onSave() {
      const valid = this.$refs.editFileForm.validate();
      if (valid && this.data) {
        const data = {
          ...this.data,
          name: this.file.name.value,
        };
        this.$emit("submit:form", data);
        this.closeDialog();
        this.$refs.editFileForm.reset();
      }
    },
  },
};
</script>
