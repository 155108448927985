<template>
  <div class="h-100">
    <apexchart :options="_options" :series="_series" height="100%" />
    <AppChartDialog
      :chartOptions="_options"
      :chartData="subChart.selectedDataPoint?.subChart"
      :title="subChart.selectedDataPoint?.label"
      v-model="subChart.show"
    />
  </div>
</template>
<script>
import _ from "lodash";
import { chartMixins } from "@/helpers/mixins/chartMixins";
import moment from "moment";
var nb = require("apexcharts/dist/locales/nb.json");
var en = require("apexcharts/dist/locales/en.json");

export default {
  mixins: [chartMixins],
  props: {
    chartData: Object,
    chartOptions: Object,
  },
  data() {
    return {
      subChart: {
        show: false,
        selectedDataPoint: null,
      },
    };
  },
  computed: {
    series() {
      return this.chartMixins_formatSeries({ chartData: this.chartData });
    },
    isSingleDatasetChartType() {
      return ["pie", "donut", "radialBar", "polarArea"].includes(
        this.chartOptions?.chart.type,
      );
    },
    _labels() {
      const labels = this.series?.[0]?.data?.map((item) => item.label) ?? [];

      if (this.chartOptions.xaxis?.type === "datetime") {
        const dateLabels = labels.map((label) => moment(label).format("DD.MM.YYYY"));
        return dateLabels;
      }

      if (this.isSingleDatasetChartType) {
        return labels;
      }

      return [];
    },
    _series() {
      if (this.isSingleDatasetChartType) {
        return this.series?.[0].data.map((item) => item?.value);
      }
      return this.series;
    },
    _colors() {
      if (this.isSingleDatasetChartType) {
        return this.series?.[0]?.data?.every((item) => item?.color)
          ? this.series?.[0]?.data?.map((item) => item?.color)
          : this.chartOptions?.colors;
      }
      return this.chartOptions?.colors;
    },
    shouldDisableAnimation() {
      const MAX_DATAPOINTS_FOR_ANIMATION = 1000;
      return (
        this.series?.reduce(
          (total, serie) => total + (serie.data ? serie.data?.length : 0),
          0,
        ) > MAX_DATAPOINTS_FOR_ANIMATION
      );
    },
    isDatetime() {
      return this.chartOptions.xaxis?.type === "datetime";
    },
    _options() {
      const baseOptions = {
        chart: {
          fontFamily: "Roboto, sans-serif",
          events: {
            dataPointSelection: this.onSelect,
            // zoomed: (chartContext, { xaxis }) => {
            //   this.handleZoom(chartContext, xaxis);
            // },
            // scrolled: (chartContext, { xaxis }) => {
            //   this.handleScroll(chartContext, xaxis);
            // },
          },
          locales: [nb, en],
          defaultLocale: this.$i18n.locale,
          animations: {
            enabled: !this.shouldDisableAnimation,
          },
          zoom: {
            enabled: this.isDatetime,
          },
        },
        xaxis: {
          categories: this._labels,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM yyyy",
              day: "dd. MMM",
              hour: "HH:mm",
            },
          },
        },
        colors: this._colors,
        labels: this._labels,
        dataLabels: {
          enabled: this.shouldEnableLabels(),
          dropShadow: {
            enabled: true,
          },
        },
        plotOptions: this.getPlotOptions(),
      };
      const options = _.merge(baseOptions, this.chartOptions);
      return options;
    },
  },
  methods: {
    shouldEnableLabels() {
      const MAX_DATAPOINTS_FOR_LABELS = 50; // Set the threshold as needed
      return (
        this.series?.reduce((total, serie) => total + serie.data?.length, 0) <=
        MAX_DATAPOINTS_FOR_LABELS
      );
    },
    getPlotOptions() {
      const plotOptions = {
        bar: {
          borderRadius: 8,
          borderRadiusApplication: "end",
        },
      };

      const isDonut = this.chartOptions.chart.type === "donut";

      plotOptions.pie = {
        donut: {
          labels: {
            show: true,
            total: {
              show: isDonut,
              color: this.$vuetify.theme.themes.light.primary.base,
            },
            name: {
              show: isDonut,
            },
            value: {
              show: isDonut,
            },
          },
        },
      };

      return plotOptions;
    },
    // Handles selection of data points on the chart.
    onSelect(event, chartContext, config) {
      const { seriesIndex, dataPointIndex } = config;
      // Return early if the indices are invalid.
      if (seriesIndex === -1 || dataPointIndex === -1) return;
      // Determine the chart type from the options.
      const chartType = this._options.chart.type;
      // Retrieve the data point based on the chart type and indices.
      const dataPoint = this.getDataPoint(chartType, seriesIndex, dataPointIndex);
      // Check if the data point has a subChart and is not empty.
      if (!dataPoint || _.isEmpty(dataPoint.subChart)) return;
      // Show the subChart and set the selected data point for drilldown.
      this.subChart.show = true;
      this.subChart.selectedDataPoint = dataPoint;
    },
    // Retrieves a data point from the series based on the chart type and indices.
    getDataPoint(chartType, seriesIndex, dataPointIndex) {
      const dataset = this.series[seriesIndex];
      // Return the data point format based on the chart type.
      if (this.isSingleDatasetChartType) {
        return dataset?.data?.[dataPointIndex]; // For pie charts, data is directly under 'data' array.
      } else {
        return dataset?.dataPoints?.[dataPointIndex]; // For other charts, data is under 'dataPoints' array.
      }
    },
    handleZoom(chartContext, xaxis) {
      const { min, max } = xaxis;
      this.fetchDataForRange(min, max);
    },
    handleScroll: _.debounce(function (chartContext, xaxis) {
      const { min, max } = xaxis;
      this.fetchDataForRange(min, max);
    }, 300),
    fetchDataForRange(start, end) {
      this.$emit("dateRange:change", { start, end });
    },
  },
};
</script>
