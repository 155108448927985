<template>
  <AppWidgetShortcutButton
    @click="navigateHSE"
    @delete:click="onDelete"
    :templateProps="templateProps"
    :icon="$icon.LIGHT.COMMON.BOOK_OPEN_READER"
    :title="$t('dashboard.shortcutButton.hseTemplatesTitle')"
    :description="$t('dashboard.shortcutButton.hseTemplatesDescription')"
  />
</template>

<script>
import { getMetaStoragesByField } from "@/services/storage/metaStorage/metaStoragesByField";

export default {
  props: {
    templateProps: Object,
  },
  methods: {
    async navigateHSE() {
      const metaStorages = await getMetaStoragesByField("ROOT_HSE");
      if (!metaStorages) return;

      this.$router.replace({
        name: this.$routeNames.STORAGE.ROOT,
        query: { autoOpen: "HSE" },
      });
    },
    onDelete() {
      this.$emit("delete:click");
    },
  },
};
</script>
