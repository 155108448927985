<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card class="pa-10 d-flex flex-column" height="480" width="650">
      <img
        style="position: absolute; bottom: 0; right: 0"
        src="@/assets/gripr_hand_snap.svg"
      />
      <div class="dialog__close-btn">
        <v-btn icon dark @click="onClose">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </div>
      <template v-if="isLoading || !billingDetails">
        <div class="d-flex align-center justify-center h-100">
          <AppCircularLoader />
        </div>
      </template>
      <template v-else>
        <v-card-title>
          <div>
            <v-icon color="primary" size="45">{{
              $icon.SOLID.COMMON.PAPER_PLANE
            }}</v-icon>

            <div class="text-h6 pt-5">
              {{ title }}
            </div>
          </div>
        </v-card-title>
        <v-card-text class="primary-text--text">
          <div>
            {{ subTitle }}
          </div>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <div>
            <AppDialogActionBtnPair
              @confirm="onConfirm"
              @cancel="onClose"
              :confirmText="$t('increaseLicenseDialog.confirm')"
              :loading="isUpgrading"
              :disabled="!canChangeSubscriptionQuantity"
            />
            <div class="text-caption pt-5" v-html="bottomText"></div>
          </div>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { billingMixins } from "@/helpers/mixins";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [billingMixins],
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isUpgrading: false,
    };
  },
  watch: {
    dialog: {
      handler(val) {
        if (val) {
          this.getBillingDetails();
        }
      },
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      subscriptionPlan: "subscriptionPlan",
    }),
    ...mapState("billingDetails", {
      billingDetails: (state) => state.billingDetails,
      isLoading: (state) => state.isLoading,
    }),
    ...mapGetters("billingDetails", {
      currency: "currency",
      usedSeats: "usedSeats",
      intervalType: "intervalType",
      canChangeSubscriptionQuantity: "canChangeSubscriptionQuantity",
      planByCode: "planByCode",
      activePlanSubscription: "activePlanSubscription",
    }),
    plan() {
      return this.planByCode(this.subscriptionPlan.planCode);
    },
    additionalSeats() {
      const currentSeats = this.activePlanSubscription?.quantity;
      // to insure that result is not negative
      const additionalSeats = Math.max(this.usedSeats - currentSeats + 1, 0);
      return additionalSeats;
    },
    neededSeats() {
      return this.usedSeats + this.additionalSeats;
    },
    calculatedPrice() {
      const price = this.additionalSeats * this.plan?.price;
      return this.billingMixins_priceFormat(price, this.currency);
    },
    title() {
      return this.$t("increaseLicenseDialog.title");
    },
    subTitle() {
      return this.$t("increaseLicenseDialog.subTitle", { seats: this.additionalSeats });
    },
    bottomText() {
      return this.$t("increaseLicenseDialog.termsAndConditions", {
        seats: this.additionalSeats,
        count: this.additionalSeats,
        totalPrice: this.calculatedPrice,
        intervalType: this.$t(
          `settings.billing.subscription.planCard.intervals.${this.intervalType}`,
        ),
      });
    },
  },
  methods: {
    getBillingDetails() {
      this.$store.dispatch("billingDetails/getBillingDetails");
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
    },
    onConfirm() {
      this.isUpgrading = true;
      this.$store
        .dispatch("billingDetails/updateMainSubscription", {
          quantity: this.neededSeats,
          updateKey: "setIsChangingPlan",
        })
        .then(() => {
          this.closeDialog();
          this.$emit("plan:upgrade", { quantity: this.neededSeats });
        })
        .finally(() => {
          this.isUpgrading = false;
        });
    },
  },
};
</script>
