import { ALL_WIDGETS } from "@/helpers/constants";

export function normalizeLayout(layout) {
  return { ...layout, widgetItems: normalizeWidgets(layout?.widgetItems) };
}

export function normalizeWidgets(widgets) {
  const filteredWidgets = (widgets || [])
    .filter(
      (widget) =>
        widget !== null && ALL_WIDGETS.some((item) => item.name === widget.name),
    )
    .map((widget) => normalizeWidgetItem(widget));

  return sortWidgets(filteredWidgets);
}

export function normalizeWidgetItem(widgetItem) {
  const constantObject = ALL_WIDGETS.find(
    (constant) => constant.name === widgetItem.name,
  );
  if (!constantObject) return null;
  const component = constantObject.component;
  return {
    ...widgetItem,
    component,
  };
}

function sortWidgets(widgets) {
  return widgets.sort((a, b) => {
    const indexA = ALL_WIDGETS.findIndex((item) => item.name === a.name);
    const indexB = ALL_WIDGETS.findIndex((item) => item.name === b.name);

    if (indexA === -1 || indexB === -1) {
      return 0;
    }

    return indexA - indexB;
  });
}
