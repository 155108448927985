import Vue from "vue";
import * as util from "../helpers/util";
import filters from "../helpers/filters";
import { makeSlot } from "../helpers/makeSlot";
import * as constants from "../helpers/constants";
import * as regexes from "../helpers/regex";
import * as rules from "../helpers/rules";
import * as icons from "@/helpers/icons";
import * as textHelpers from "@/helpers/textHelpers";
import { ROUTE_NAMES } from "@/helpers/routeNames";

for (const filter of filters) {
  Vue.filter(filter.key, filter.cb);
}

export default {
  install: () => {
    Vue.prototype.$util = { ...util };
    Vue.$util = { ...util };

    Vue.prototype.makeSlot = (fn) => makeSlot(fn);

    Vue.prototype.$constant = {};
    for (const constant in constants) {
      Vue.prototype.$constant[constant] = constants[constant];
    }
    Vue.prototype.$icon = {};
    for (const icon in icons) {
      Vue.prototype.$icon[icon] = icons[icon];
    }

    Vue.prototype.$regex = {};
    for (const regex in regexes) {
      Vue.prototype.$regex[regex] = regexes[regex];
    }

    Vue.prototype.$rules = {};
    for (const rule in rules) {
      Vue.prototype.$rules[rule] = rules[rule];
    }

    Vue.prototype.$textHelpers = {};
    for (const textHelper in textHelpers) {
      Vue.prototype.$textHelpers[textHelper] = textHelpers[textHelper];
    }

    Vue.prototype.$routeNames = {};

    for (const routeName in ROUTE_NAMES) {
      Vue.prototype.$routeNames[routeName] = ROUTE_NAMES[routeName];
    }

    Vue.prototype.$isProduction =
      process.env.NODE_ENV === "production" || location.hostname === "gripr.io";
  },
};
