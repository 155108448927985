import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  //   settings: {},
  result: {},
  isLoading: false,
  isUpdating: false,
});

const getters = {};

const actions = {
  async getSettingsResult({ commit }, { params }) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}settings/user/result`, { params })
      .then(({ data }) => {
        const settings = data?.settings;
        commit("setResult", settings);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
  //   async updatesetting({ commit }, { body }) {
  //     commit("loading", true);

  //     return axios
  //       .put(`${apiUrl}setting/user`, { ...body })
  //       .then(({ data }) => {
  //         const setting = data?.setting;
  //         if (setting) {
  //           commit("setSettings", setting);
  //         }
  //       })
  //       .catch((error) => {
  //         throw new Error(error);
  //       })
  //       .finally(() => {
  //         commit("loading", true);
  //       });
  //   },
};

const mutations = {
  setSettings(state, settings) {
    state.settings = settings;
  },
  setResult(state, settings) {
    state.result = settings;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  updating(state, isUpdating) {
    state.isUpdating = isUpdating;
  },
};

export const userSettings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
