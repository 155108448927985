const state = () => ({
  dialog: {
    active: false,
    planCode: null,
    onAfterUpgrade: null,
  },
});

const getters = {};

const actions = {
  setUpgradePlanDialog({ commit }, { active, planCode, onAfterUpgrade }) {
    commit("setDialog", { active, planCode, onAfterUpgrade });
  },
};

const mutations = {
  setDialog(state, dialog) {
    state.dialog = { ...dialog };
  },
  setActive(state, active) {
    state.dialog.active = active;
  },
};

export const upgradePlanDialog = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
