import { capitalize } from "../filters";
import { LIGHT } from "../icons";
import i18n from "@/plugins/i18n";

// MODULE_ITEM_CONFIG is a Map that handles the configuration for retrieving and displaying data for different module items.
// Each entry in the Map consists of a key (dataKey) and a configuration object with methods to get the icon, text, and icon size for the item.
export const MODULE_ITEM_CONFIG = new Map([
  [
    "project",
    {
      getIcon: (project) => LIGHT.ROUTE.PROJECT,
      getText: (project) => project?.name,
      iconSize: 14,
    },
  ],
  [
    "projectNumber",
    {
      getIcon: (project) => LIGHT.ACTION.NUMBER,
      getText: (project) => project?.projectNumber,
      iconSize: 14,
    },
  ],
  [
    "form",
    {
      getIcon: (form) => LIGHT.ROUTE.FORM,
      getText: (form) => form?.name,
      iconSize: 12,
    },
  ],
  [
    "formItem",
    {
      getIcon: (formItem) => LIGHT.ACTION.FORM_ITEM,
      getText: (formItem) => formItem?.name,
      iconSize: 12,
    },
  ],
  [
    "task",
    {
      getIcon: (task) => LIGHT.ROUTE.TASK,
      getText: (task) => task?.name,
      iconSize: 12,
    },
  ],
  [
    "client",
    {
      getIcon: (client) => LIGHT.ACTION.CLIENT,
      getText: (client) => client?.name,
      iconSize: 12,
    },
  ],
  [
    "user",
    {
      getIcon: (user) => LIGHT.ROUTE.USER,
      getText: (user) =>
        user?.text ||
        user?.name ||
        user?.fullName ||
        `${user?.firstName || ""} ${user?.lastName || ""}` ||
        user?.email,
      iconSize: 12,
    },
  ],
  [
    "email",
    {
      getIcon: (user) => LIGHT.ACTION.EMAIL,
      getText: (user) => user?.email,
      iconSize: 12,
    },
  ],
  [
    "workTitleLabel",
    {
      getIcon: (workTitleLabel) => LIGHT.ACTION.ROLE,
      getText: (workTitleLabel) => workTitleLabel?.name,
      iconSize: 12,
    },
  ],
  [
    "label",
    {
      getIcon: (user) => LIGHT.ACTION.ROLE,
      getText: (user) => user?.label?.name,
      iconSize: 12,
    },
  ],
  [
    "organizationNumber",
    {
      getIcon: (item) => LIGHT.ACTION.NUMBER,
      getText: (item) => item?.organizationNumber,
      iconSize: 12,
    },
  ],
  [
    "clientType",
    {
      getIcon: (item) => LIGHT.ACTION.CLIENT,
      getText: (item) =>
        capitalize(i18n.t(`contact.client.type.${item.type.toLowerCase()}`)),
      iconSize: 12,
    },
  ],
  // storagePermissionLevel: {
  //   getIcon: (permissionLevel) => getStoragePermissionLevelIcon(permissionLevel),
  //   getText: (permissionLevel) =>
  //     this.$t(`v2.common.permissionLevel.${permissionLevel}`),
  //   iconSize: 12,
  // },
  // storageIsLocked: {
  //   getIcon: () => "lock-keyhole",
  //   getText: () => this.$t("v2.common.locked"),
  //   iconSize: 12,
  // },
  // storageIsLinked: {
  //   getIcon: () => "copy",
  //   getText: () => this.$t("v2.storage.common.linked"),
  //   iconSize: 12,
  // },
  // isInBoligmappa: {
  //   getIcon: () => BOLIGMAPPA_LOGO,
  //   getText: () => this.$t("v2.storage.common.inBoligmappa"),
  //   iconSize: 12,
  // },
  // Additional configurations can be added here as needed
]);

// MODULE_FIELD_CONFIG manages the configuration for how fields are displayed and queried for different modules.
// Each module (e.g., FORM, CLIENT) has a configuration object that specifies the title field, subtitle fields, and query parameters.
export const MODULE_FIELD_CONFIG = {
  FORM: {
    TITLE_FIELD: {
      dataKey: "form",
      pathToModule: "",
    },
    SUB_TITLE_FIELDS: [
      { dataKey: "project", pathToModule: "project" },
      { dataKey: "projectNumber", pathToModule: "project" },
      { dataKey: "task", pathToModule: "task" },
      { dataKey: "user", pathToModule: "member" },
    ],
    FIELDS_QUERY_PARAM: ["project(name,projectNumber)", "task(name)", "members(name)"],
  },
  CLIENT: {
    TITLE_FIELD: {
      dataKey: "client",
      pathToModule: "",
    },
    SUB_TITLE_FIELDS: [
      { dataKey: "organizationNumber", pathToModule: "" },
      { dataKey: "clientType", pathToModule: "" },
    ],
    FIELDS_QUERY_PARAM: ["meta(organizationNumber)", "type"],
  },
  USER_AND_CONTACT: {
    TITLE_FIELD: {
      dataKey: "user",
      pathToModule: "",
    },
    SUB_TITLE_FIELDS: [
      { dataKey: "email", pathToModule: "" },
      { dataKey: "workTitleLabel", pathToModule: "workTitleLabel" },
      { dataKey: "label", pathToModule: "" },
      { dataKey: "relation", pathToModule: "" },
      { dataKey: "client", pathToModule: "client" },
    ],
    USER_FIELDS_QUERY_PARAM: null, //everything is provided by default
    CONTACT_FIELDS_QUERY_PARAM: [
      //this is formatted differently because search/contact uses the old method
      "contactItems",
      "label",
      "firstName",
      "lastName",
      "fullName",
      "email",
    ],
  },
  TASK: {
    TITLE_FIELD: {
      dataKey: "task",
      pathToModule: "",
    },
    SUB_TITLE_FIELDS: [
      { dataKey: "project", pathToModule: "project" },
      { dataKey: "projectNumber", pathToModule: "project" },
      { dataKey: "formItem", pathToModule: "formItem" },
      { dataKey: "form", pathToModule: "formItem.form" },
      { dataKey: "user", pathToModule: "member" },
    ],
    FIELDS_QUERY_PARAM: [
      "members(name)",
      "formItem(form(name),name)",
      "project(name,projectNumber)",
    ],
  },
  PROJECT: {
    TITLE_FIELD: {
      dataKey: "project",
      pathToModule: "",
    },
    SUB_TITLE_FIELDS: [
      { dataKey: "projectNumber", pathToModule: "" },
      { dataKey: "client", pathToModule: "client" },
      { dataKey: "user", pathToModule: "owner" },
    ],
    FIELDS_QUERY_PARAM: ["meta(projectNumber)", "client(name)", "owner(name)"],
  },
  // Additional module configurations can be added here as needed
};

/**
 * How to Use:
 *
 * 1. Define the configuration for your module items in MODULE_ITEM_CONFIG.
 *    - Each entry should have a dataKey and a configuration object with getIcon, getText, and iconSize.
 *
 * 2. Define the configuration for your module fields in MODULE_FIELD_CONFIG.
 *    - Each module should have a TITLE_FIELD, SUB_TITLE_FIELDS, and FIELDS_QUERY_PARAM.
 *
 * 3. Use the getDisplayData function to retrieve the display data (icon, text, icon size) for a given item and configuration.
 *    Example:
 *    const displayData = getDisplayData(item, { dataKey: "project", pathToModule: "project" });
 *    console.log(displayData.icon, displayData.text, displayData.iconSize);
 *
 * 4. Use the getSearchStr function to generate a search string for a given item based on the module configuration.
 *    Example:
 *    const searchStr = getSearchStr(item, "FORM");
 *    console.log(searchStr);
 *
 * 5. Use the formatItemWithSearchStr function to format a list of items by adding a search string to each item.
 *    Example:
 *    const formattedItems = formatItemWithSearchStr(items, "FORM");
 *    console.log(formattedItems);
 */
