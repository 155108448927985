<template>
  <div style="height: calc(100% - 68px)" class="overflow-y-auto">
    <div class="d-flex flex-column pa-4">
      <div class="mb-4">
        <div class="mb-1 d-flex">
          <div class="font-weight-bold body-2">
            {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.sentOn") }}
          </div>
          <v-spacer />

          <AppColorChip
            style="max-height: 24px"
            :text="completedChipText()"
            color="success--text"
          />
        </div>
        <div>
          {{ createdDate }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.completedOn") }}
        </div>
        <div>
          {{ completedAt }}
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-1 font-weight-bold body-2">
          {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.sentBy") }}
        </div>
        <AppUserAvatar :user="sender" :truncate="30" show-name />
      </div>

      <div class="font-weight-bold body-2">
        {{ $t("fileManagement.preview.navMenu.e-signature.COMPLETED.signedBy") }}
      </div>

      <div>
        <v-divider class="my-2" />
        <div v-for="(signer, index) of signers" :key="index">
          <div class="d-flex flex-column">
            <div class="mb-1">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.name") }}</span>
                <v-spacer />
                <AppColorChip :text="recipientStatusText(signer)" color="success--text" />
              </div>
              <AppTextTruncateValue
                :disabled="signer.name.length <= truncateLength"
                :text="signer.name"
                :truncate="truncateLength"
              />
            </div>

            <div>
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.email") }}</span>
              </div>
              <a
                class="underline-on-hover primary-link--text"
                :href="`mailto:${signer.email}`"
                target="_blank"
              >
                <AppTextTruncateValue
                  :disabled="signer.email.length <= truncateLength"
                  :text="signer.email"
                  :truncate="truncateLength"
                />
              </a>
            </div>

            <div v-if="signer.comment">
              <div class="d-flex">
                <span class="text-caption">{{ $t("common.comment") | capitalize }}</span>
              </div>
              <span>{{ signer.comment }}</span>
            </div>
          </div>
          <v-divider class="my-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      truncateLength: 35,
    };
  },
  props: {
    signatureSession: Object,
  },
  computed: {
    sender() {
      return this.signatureSession?.user || null;
    },
    signers() {
      return this.signatureSession?.signers || [];
    },
    createdDate() {
      if (!this.signatureSession.createdAt) return "";
      return moment(this.signatureSession.createdAt).format("DD.MM.YYYY HH:mm");
    },
    completedAt() {
      if (!this.signatureSession.completedAt) return "";
      return moment(this.signatureSession.completedAt).format("DD.MM.YYYY HH:mm");
    },
  },
  methods: {
    completedChipText() {
      const count = `${this.signers.length}/${this.signers.length}`;
      const completedText = `${count} ${this.$t(
        "fileManagement.preview.navMenu.e-signature.status.COMPLETED",
      )}`;

      return completedText;
    },
    recipientStatusText(recipient) {
      const date = recipient.signedAt
        ? moment(recipient.signedAt).format("DD.MM.YYYY HH:mm")
        : "";
      return `${this.$t(
        "fileManagement.preview.navMenu.e-signature.status.COMPLETED",
      )} ${date}`;
    },
  },
};
</script>
