import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  documents: [],
  isLoading: false,
  filter: null,
});

const getters = {
  alreadyPickedDocuments: state => {
    return state.documents.filter(item => item.templateItem);
  },
};

const actions = {
  async getDocuments({ state, commit }, filter) {
    commit("loading", true);
    commit("setFilter", filter);

    return axios
      .get(`${apiUrl}template/document/imported`, {
        params: { ...state.filter },
      })
      .then(({ data }) => {
        const documents = data?.templates || [];
        commit("setDocuments", documents);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => commit("loading", false));
  },
};

const mutations = {
  setDocuments(state, documents) {
    state.documents = documents;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const importedDocuments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
