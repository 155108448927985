<template>
  <div>
    <AppDataTable
      v-model="localSelectedStorages"
      :loading="isLoading"
      :headers="_headers"
      :items="storages"
      :noDataText="$t('common.noStorage')"
      disable-pagination
      show-select
    >
      <template v-slot:item="{ item, isSelected, select }">
        <AppStorageTableItem
          :storage="item"
          :thumbnail="thumbnailById(item.id)"
          :isSelected="isSelected"
          @isSelected:change="select"
          @name:click="onNameClick"
          @menu:click="onMenuClick"
        />
      </template>
    </AppDataTable>
    <AppStorageContextMenu
      ref="contextMenu"
      :selectedStorages="selectedStorages"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      @action:click="onContextMenuActionClick"
    />
  </div>
</template>

<script>
import { getFileContent } from "@/services/storage/storageService";

export default {
  props: {
    storages: Array,
    isLoading: Boolean,
    selectedStorages: Array,
    showBoligmappa: Boolean,
    folderRouterName: String,
    rootId: String,
  },
  model: {
    prop: "selectedStorages",
    event: "selectedStorages:change",
  },
  data() {
    return {
      thumbnails: [],
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$t("fileManagement.list.name"),
          align: "start",
          value: "name",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.owner"),
          align: "start",
          value: "storageOwner.firstName",
          show: true,
        },
        {
          text: this.$t("fileManagement.list.lastModified"),
          align: "start",
          value: "updatedAt",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    localSelectedStorages: {
      get() {
        return this.selectedStorages;
      },
      set(value) {
        this.$emit("selectedStorages:change", value);
      },
    },
  },
  watch: {
    storages: {
      handler(storages) {
        this.getThumbnails(storages);
      },
    },
  },
  methods: {
    onMenuClick({ event, storage }) {
      if (!this.localSelectedStorages.some((s) => s.id === storage.id)) {
        this.localSelectedStorages = [storage];
      }
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onNameClick({ storage }) {
      this.$emit("storage:click", { storage });
    },
    thumbnailById(id) {
      return this.thumbnails.find((i) => i.id === id) || null;
    },
    onContextMenuActionClick(e) {
      this.$emit("menuAction:click", e);
    },
    getThumbnails(storages) {
      for (let storage of storages) {
        const isImage = !!storage?.mime?.match("image/*");
        if (!isImage) continue;
        const thumbnail = this.thumbnailById(storage.id);

        if (!thumbnail?.url && !thumbnail?.isLoading) {
          this.getThumbnail(storage.id);
        }
      }
    },
    getThumbnail(id) {
      let thumbnail = this.thumbnails.find((i) => i.id === id);
      if (!thumbnail) {
        thumbnail = { id, isLoading: true, url: null };
        this.thumbnails.push(thumbnail);
      } else {
        thumbnail.isLoading = true;
      }

      getFileContent(id, { height: 80 }).then((url) => {
        thumbnail.url = url;
        thumbnail.isLoading = false;
      });
    },
  },
};
</script>
