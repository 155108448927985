<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("cms.template.document.editCategory.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editTemplateCategoryForm"
          v-model="item.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("cms.template.document.editTemplate.categoryLabel") }}
            </div>
            <AppDefaultSelect
              v-model="item.category.value"
              :items="categories"
              :rules="$rules.REQUIRED"
              item-text="text"
              item-value="id"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("cmsTemplateCategory", {
      isLoading: (state) => state.isLoading,
      categories: (state) => state.items,
    }),
  },
  data() {
    return {
      item: {
        valid: false,
        category: {
          value: "",
        },
      },
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.$refs.editTemplateCategoryForm.resetValidation();
      this.closeDialog();
    },
    onSave() {
      const valid = this.$refs.editTemplateCategoryForm.validate();
      const category = this.item.category.value;
      if (valid) {
        const data = {
          categoryId: category !== "uncategorized" ? category : null,
        };
        this.$emit("submit:form", { data });

        this.closeDialog();
      }
    },
    getCategories() {
      this.$store.dispatch("cmsTemplateCategory/getItems");
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
