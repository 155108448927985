<template>
  <v-dialog v-model="localDialog" scrollable max-width="700px">
    <AppBoligmappaRoomDialog
      v-model="boligmappaRoomDialog.active"
      :boligmappaNumber="boligmappaNumber"
      @created="afterRoomCreation"
    />
    <AppDeleteConfirmationDialog
      v-model="comfirmDeleteFileDialog.active"
      :title="$t('integration.boligmappa.fileUpload.confirmDelete.title')"
      :subtitle="$t('integration.boligmappa.fileUpload.confirmDelete.subTitle')"
      skip-validation
      :deleteBtnText="$t('integration.boligmappa.fileUpload.confirmDelete.deleteBtnText')"
      @delete="deleteFile"
    />
    <AppFilePreviewDialog
      v-model="activePreviewFileId"
      :fileIds="
        boligmappaFiles.map((file) => {
          return { id: file.fileId };
        })
      "
      @dialog:close="onPreviewDialogClose"
    />
    <v-card :disabled="isUploading">
      <v-card-title>
        <div class="d-flex w-100 align-center">
          <span class="headline">
            {{ $t("integration.boligmappa.fileUpload.title") }}
          </span>
          <v-spacer />
          <AppStonlyGuideBtn guideId="roY8uHwW2v" />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="boligmappaFileUpload"
          v-model="isFormValid"
          v-on:submit.prevent
          @submit="onCreate"
        >
          <template v-if="boligmappaFiles[pageIndex]">
            <div class="p-relative">
              <div class="label--small required-asterisk">
                {{ $t("integration.boligmappa.fileUpload.fileName") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultTextField
                    v-if="showUseForAllField('fileName')"
                    v-model="useForAll.fileName.value"
                    :rules="formRules.fileName"
                  />
                  <AppDefaultTextField
                    v-else
                    v-model="boligmappaFiles[pageIndex].fileName"
                    :rules="formRules.fileName"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    hide-details
                    v-model="useForAll.fileName.isChecked"
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'fileName')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small">
                {{ $t("integration.boligmappa.fileUpload.orderNumber") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultTextField
                    v-if="showUseForAllField('orderNumber')"
                    v-model="useForAll.orderNumber.value"
                  />
                  <AppDefaultTextField
                    v-else
                    v-model="boligmappaFiles[pageIndex].orderNumber"
                    :rules="formRules.orderNumber"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    v-model="useForAll.orderNumber.isChecked"
                    @change="setUseForAllValue($event, 'orderNumber')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small">
                {{ $t("integration.boligmappa.fileUpload.profession") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultAutocomplete
                    v-if="showUseForAllField('professionType')"
                    v-model="useForAll.professionType.value"
                    :rules="formRules.professionType"
                    :items="professionTypes"
                    item-text="name"
                    item-value="id"
                    :loading="isLoading"
                  />
                  <AppDefaultAutocomplete
                    v-else
                    v-model="boligmappaFiles[pageIndex].professionType"
                    :rules="formRules.professionType"
                    :items="professionTypes"
                    item-text="name"
                    item-value="id"
                    :loading="isLoading"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    v-model="useForAll.professionType.isChecked"
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'professionType')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small required-asterisk">
                {{ $t("integration.boligmappa.fileUpload.type") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultAutocomplete
                    v-if="showUseForAllField('documentType')"
                    v-model="useForAll.documentType.value"
                    :rules="formRules.documentType"
                    :items="documentTypes"
                    item-text="name"
                    item-value="id"
                    :loading="isLoading"
                  />
                  <AppDefaultAutocomplete
                    v-else
                    v-model="boligmappaFiles[pageIndex].documentType"
                    :rules="formRules.documentType"
                    :items="documentTypes"
                    item-text="name"
                    item-value="id"
                    :loading="isLoading"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    v-model="useForAll.documentType.isChecked"
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'documentType')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small required-asterisk">
                {{ $t("integration.boligmappa.fileUpload.chapter") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultAutocomplete
                    v-if="showUseForAllField('chapterTags')"
                    v-model="useForAll.chapterTags.value"
                    :rules="formRules.chapterTags"
                    :items="chapterTags"
                    item-text="tagName"
                    item-value="id"
                    multiple
                    select-all
                    :loading="isLoading"
                  />
                  <AppDefaultAutocomplete
                    v-else
                    v-model="boligmappaFiles[pageIndex].chapterTags"
                    :rules="formRules.chapterTags"
                    :items="chapterTags"
                    item-text="tagName"
                    item-value="id"
                    multiple
                    select-all
                    :loading="isLoading"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    v-model="useForAll.chapterTags.isChecked"
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'chapterTags')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small">
                {{ $t("integration.boligmappa.fileUpload.room") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultAutocomplete
                    v-if="showUseForAllField('rooms')"
                    v-model="useForAll.rooms.value"
                    :rules="formRules.rooms"
                    :items="rooms"
                    item-text="title"
                    item-value="id"
                    select-all
                    multiple
                    :loading="isLoading"
                  >
                    <template v-slot:append-item>
                      <AppSelectAppendCreateBtn @click="onNewRoom">
                        {{ $t("integration.boligmappa.fileUpload.createNewRoom") }}
                      </AppSelectAppendCreateBtn>
                    </template>
                  </AppDefaultAutocomplete>
                  <AppDefaultAutocomplete
                    v-else
                    v-model="boligmappaFiles[pageIndex].rooms"
                    :rules="formRules.rooms"
                    :items="rooms"
                    item-text="title"
                    item-value="id"
                    select-all
                    multiple
                    :loading="isLoading"
                  >
                    <template v-slot:append-item>
                      <AppSelectAppendCreateBtn @click="onNewRoom">
                        {{ $t("integration.boligmappa.fileUpload.createNewRoom") }}
                      </AppSelectAppendCreateBtn>
                    </template>
                  </AppDefaultAutocomplete>
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    v-model="useForAll.rooms.isChecked"
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'rooms')"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="p-relative">
              <div class="label--small">
                {{ $t("integration.boligmappa.fileUpload.description") }}
              </div>
              <v-row>
                <v-col>
                  <AppDefaultTextarea
                    v-if="showUseForAllField('description')"
                    v-model="useForAll.description.value"
                    :rules="formRules.description"
                    rows="1"
                  />
                  <AppDefaultTextarea
                    v-else
                    v-model="boligmappaFiles[pageIndex].description"
                    :rules="formRules.description"
                    rows="1"
                  />
                </v-col>
                <v-col v-if="isMultiPage" cols="3">
                  <v-checkbox
                    v-model="useForAll.description.isChecked"
                    hide-details
                    class="mt-1"
                    :label="$t('integration.boligmappa.fileUpload.useForAll')"
                    @change="setUseForAllValue($event, 'description')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <v-checkbox
                  v-if="showUseForAllField('isVisibleInBoligmappa')"
                  v-model="useForAll.isVisibleInBoligmappa.value"
                  hide-details
                  class="mt-0 pt-0"
                  :label="$t('integration.boligmappa.fileUpload.showInBoligmappa')"
                />
                <v-checkbox
                  v-else
                  hide-details
                  class="mt-0 pt-0"
                  v-model="boligmappaFiles[pageIndex].isVisibleInBoligmappa"
                  :label="$t('integration.boligmappa.fileUpload.showInBoligmappa')"
                />
              </v-col>
              <v-col v-if="isMultiPage" cols="3">
                <v-checkbox
                  v-model="useForAll.isVisibleInBoligmappa.isChecked"
                  hide-details
                  class="mt-0 pt-0"
                  :label="$t('integration.boligmappa.fileUpload.useForAll')"
                  @change="setUseForAllValue($event, 'isVisibleInBoligmappa')"
                />
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="w-100">
        <div class="w-100">
          <AppErrorAlert class="mb-2" v-if="showFillObligatoryFieldsError">
            <span v-if="showWeHaveMovedYouToDifferentPageText">
              {{
                $t(
                  "integration.boligmappa.fileUpload.weHaveMovedYouToFirstPageWithMissingFields",
                )
              }}
            </span>
            {{ $t("integration.boligmappa.fileUpload.fillObligatoryFieldsError") }}
          </AppErrorAlert>
          <AppErrorAlert class="mb-2" v-if="hasError">
            {{ $t("integration.boligmappa.fileUpload.defaultError") }}
          </AppErrorAlert>
          <div class="d-flex w-100">
            <AppDialogActionBtnPair
              @confirm="onCreate"
              @cancel="onClose"
              :loading="isUploading"
              :confirmText="$t('common.upload')"
            />
            <v-spacer />
            <div class="d-flex">
              <AppDefaultTooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="onPreviewDialogOpen"
                    class="mr-2"
                  >
                    <v-icon>{{ $icon.LIGHT.COMMON.EYE }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("common.preview") }}</span>
              </AppDefaultTooltip>
              <AppDefaultTooltip top v-if="isMultiPage">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="error"
                    @click="comfirmDeleteFileDialog.active = true"
                    class="mr-5"
                  >
                    <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("integration.boligmappa.fileUpload.removeFile") }}</span>
              </AppDefaultTooltip>

              <div class="d-flex" v-if="isMultiPage">
                <v-btn text @click="previousPage" :disabled="isFirstPage">
                  {{ $t("integration.boligmappa.fileUpload.previous") }}
                </v-btn>
                <v-card
                  class="d-flex align-center justify-center font-weight-bold rounded"
                  flat
                >
                  <div class="px-3">{{ pageIndex + 1 }}/{{ boligmappaFiles.length }}</div>
                </v-card>
                <v-btn text @click="nextPage" :disabled="isLastPage">
                  {{ $t("integration.boligmappa.fileUpload.next") }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
    boligmappaNumber: String,
    projectId: String,
    files: Array,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      boligmappaRoomDialog: { active: false },
      comfirmDeleteFileDialog: { active: false },
      activePreviewFileId: null,
      pageIndex: 0,
      isFormValid: false,
      formRules: {
        fileName: [...this.$rules.REQUIRED, ...this.$rules.NOT_ONLY_WHITESPACES],
        orderNumber: [],
        professionType: [],
        documentType: [(v) => typeof v === "number" || this.$t("common.required")],
        chapterTags: [(v) => v.length > 0 || this.$t("common.required")],
        rooms: [],
        description: [],
      },
      boligmappaFiles: [],
      useForAll: {
        fileName: {
          isChecked: false,
          value: "",
        },
        orderNumber: {
          isChecked: false,
          value: "",
        },
        professionType: {
          isChecked: false,
          value: null,
        },
        documentType: {
          isChecked: false,
          value: null,
        },
        chapterTags: {
          isChecked: false,
          value: [],
        },
        rooms: {
          isChecked: false,
          value: [],
        },
        description: {
          isChecked: false,
          value: "",
        },
        isVisibleInBoligmappa: {
          isChecked: false,
          value: false,
        },
      },
      showFillObligatoryFieldsError: false,
      showWeHaveMovedYouToDifferentPageText: false,
    };
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getTypesAndTags(this.boligmappaNumber);
          this.setInitialData();
        }
      },
    },
  },
  computed: {
    isMultiPage() {
      return this.boligmappaFiles.length > 1;
    },
    isLastPage() {
      return this.pageIndex === this.boligmappaFiles.length - 1;
    },
    isFirstPage() {
      return this.pageIndex <= 0;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapState("boligmappaFileUpload", {
      documentTypes: (state) => state.documentTypes,
      chapterTags: (state) => state.chapterTags,
      professionTypes: (state) => state.professionTypes,
      rooms: (state) => state.rooms,
      isLoading: (state) => state.isLoading,
      isUploading: (state) => state.isUploading,
      hasError: (state) => state.hasError,
    }),
  },
  methods: {
    onPreviewDialogOpen() {
      this.activePreviewFileId = this.boligmappaFiles[this.pageIndex].fileId;
    },
    onPreviewDialogClose() {
      this.activePreviewFileId = null;
    },
    deleteFile() {
      this.boligmappaFiles.splice(this.pageIndex, 1);
      if (this.pageIndex > 0) {
        this.pageIndex--;
      }
    },
    showUseForAllField(fieldKey) {
      return this.isMultiPage && this.useForAll[fieldKey].isChecked;
    },
    validateField(rules, value) {
      for (var rule of rules) {
        const isValid = rule(value);
        if (isValid !== true) return false;
      }
      return true;
    },
    setUseForAllValue(isChecked, key) {
      if (isChecked) {
        this.useForAll[key].value = this.boligmappaFiles[this.pageIndex][key];
      }
    },
    getUseForAllValueOrNormalValue(key, item) {
      if (this.useForAll[key].isChecked) {
        return this.useForAll[key].value;
      }
      return item[key];
    },
    getTypesAndTags(boligmappaNumber) {
      this.$store.dispatch("boligmappaFileUpload/getTypesAndTags", boligmappaNumber);
    },
    setInitialData() {
      const files = [];
      for (var file of this.files) {
        const boligmappaFile = {
          orderNumber: "",
          professionType: null,
          documentType: null,
          chapterTags: [],
          rooms: [],
          fileName: file.name,
          description: "",
          isVisibleInBoligmappa: true,
          fileId: file.id,
        };
        files.push(boligmappaFile);
      }
      this.boligmappaFiles = files;
      if (this.$refs.boligmappaFileUpload) {
        this.softResetForm();
      }
    },
    onNewRoom() {
      this.boligmappaRoomDialog.active = true;
    },
    afterRoomCreation({ room }) {
      this.$store.commit("boligmappaFileUpload/addRoom", room);
    },
    createBody() {
      const files = this.boligmappaFiles.map((item) => {
        const fileName = this.getUseForAllValueOrNormalValue("fileName", item);
        const documentType = this.getUseForAllValueOrNormalValue("documentType", item);
        const chapterTags = this.getUseForAllValueOrNormalValue("chapterTags", item);
        const orderNumber = this.getUseForAllValueOrNormalValue("orderNumber", item);
        const description = this.getUseForAllValueOrNormalValue("description", item);
        const professionType = this.getUseForAllValueOrNormalValue(
          "professionType",
          item,
        );
        const isVisibleInBoligmappa = this.getUseForAllValueOrNormalValue(
          "isVisibleInBoligmappa",
          item,
        );
        const rooms = this.getUseForAllValueOrNormalValue("rooms", item);

        const boligmappaFile = {
          fileName,
          documentType,
          chapterTags,
          isVisibleInBoligmappa,
          rooms,
          ...(orderNumber && { orderNumber }),
          ...(description && { description }),
          ...(professionType && { professionType }),
        };
        const body = { id: item.fileId, boligmappaFile };
        return body;
      });
      const body = {
        files,
        projectId: this.projectId,
      };
      return body;
    },
    softResetForm() {
      this.$refs.boligmappaFileUpload.resetValidation();
      this.showFillObligatoryFieldsError = false;
      this.showWeHaveMovedYouToDifferentPageText = false;
    },
    nextPage() {
      this.softResetForm();
      this.pageIndex++;
    },
    previousPage() {
      this.softResetForm();
      this.pageIndex--;
    },
    validateAllForms() {
      for (const [index, file] of this.boligmappaFiles.entries()) {
        for (const key in file) {
          const rules = this.formRules[key];
          if (rules?.length > 0) {
            const value = this.getUseForAllValueOrNormalValue(key, file);
            const isValid = this.validateField(rules, value);
            if (!isValid) {
              if (this.pageIndex !== index) {
                this.pageIndex = index;
                this.showWeHaveMovedYouToDifferentPageText = true;
              }
              this.$refs.boligmappaFileUpload.validate();
              this.showFillObligatoryFieldsError = true;
              return false;
            }
          }
        }
      }
      return true;
    },
    onClose() {
      this.localDialog = false;
      this.pageIndex = 0;
      this.softResetForm();
      this.$refs.boligmappaFileUpload.reset();
    },
    async onCreate() {
      const isValid = this.validateAllForms();
      if (!isValid) return;
      this.pageIndex = 0;
      const body = this.createBody();
      try {
        await this.$store.dispatch("boligmappaFileUpload/uploadFile", {
          boligmappaNumber: this.boligmappaNumber,
          body,
        });
        this.$emit("created");
        this.localDialog = false;
        this.$refs.boligmappaFileUpload.reset();
        this.softResetForm();
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },
};
</script>
