<template>
  <div class="p-relative">
    <div class="label--small">
      {{ $t(labelKey) | capitalize }}
    </div>
    <AppDefaultTextField v-model="localValue" />
  </div>
</template>

<script>
export default {
  props: {
    labelKey: String,
    value: String,
  },
  data() {
    return {
      searchDebounce: null,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.debounceSearch(value);
      },
    },
  },
  methods: {
    debounceSearch(value) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.$emit("change", value);
      }, 600);
    },
  },
};
</script>
