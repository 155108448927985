import { ROUTE_NAMES } from "@/helpers/routeNames";
import { authGuard, contactUserRedirectGuard, userGuard } from "../../auth/authGuard";
import multiguard from "../multiguard";

const { ROOT, ALL_FILES } = ROUTE_NAMES.STORAGE;

export const storageRoutes = [
  {
    path: "/storage",
    name: ROOT,
    beforeEnter: multiguard([authGuard, userGuard(), contactUserRedirectGuard]),
    component: () =>
      import(
        /* webpackChunkName: "storage" */ "../../pages/FileManagement/CompanyStoragePage"
      ),
    children: [
      {
        path: "all-files/:rootId/:folderId",
        name: ALL_FILES,
        component: () =>
          import(
            /* webpackChunkName: "storage" */ "../../pages/FileManagement/CompanyStoragePage/AllFiles"
          ),
      },
    ],
  },
];
