import axios from "axios";
import { formatDataTableOptionsToQuery } from "@/helpers/util/dataTable";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getContactsCancel;

const state = () => ({
  contacts: [],
  count: null,
  isLoading: false,
});

const getters = {};

const actions = {
  async getContacts({ commit }, { filters, dataTableOptions, projectId } = {}) {
    if (getContactsCancel) getContactsCancel();
    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filters,
      ...optionsQuery,
      projectId,
    };

    return axios
      .get(`${apiUrl}contact`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getContactsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { contacts, count } = data;

        const currentPage = dataTableOptions?.page;
        const lastPageWithItems = Math.ceil(count / dataTableOptions?.itemsPerPage);

        if (!(currentPage > lastPageWithItems)) {
          commit("setContacts", contacts);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async createContact({ dispatch }, { body, filters, dataTableOptions }) {
    return axios
      .post(`${apiUrl}contact`, { ...body })
      .then(({ data }) => {
        const contact = data?.contact;
        dispatch("getContacts", { filters, dataTableOptions });
        return contact;
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async updateContact({ dispatch, commit, state }, { id, body, filters }) {
    const oldContact = state.contacts.find((x) => x.id === id);

    if (body?.labelId || body?.labelId === null) {
      commit("updateContact", { ...oldContact, isUpdatingLabel: true });
    }
    if (body?.relation?.labelId || body?.relation?.labelId === null) {
      commit("updateContact", { ...oldContact, isUpdatingRelationLabel: true });
    }

    return axios
      .put(`${apiUrl}contact/${id}`, { ...body })
      .then(({ data }) => {
        let contact = data?.contact;
        if (contact) {
          commit("updateContact", contact);
          return contact;
        } else {
          dispatch("getContacts", { filters });
          return null;
        }
      })
      .catch((error) => {
        commit("updateContact", oldContact);
        throw new Error(error);
      });
  },
  async deleteContact({ commit, state }, { ids }) {
    return axios
      .delete(`${apiUrl}contact`, { data: { ids } })
      .then(async (res) => {
        const contacts = res?.data?.contacts;
        if (contacts) {
          for (const contact of contacts) {
            commit("removeContact", contact);
            commit("setCount", state.count - 1);
          }
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async resetState({ commit }) {
    commit("setContacts", []);
    commit("setCount", null);
  },
};

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
  setCount(state, count) {
    state.count = count;
  },
  updateContact(state, contact) {
    if (!contact) throw new Error("No contact");
    const index = state.contacts.findIndex((x) => x.id === contact.id);
    if (index !== -1) {
      state.contacts.splice(index, 1, contact);
    }
  },
  removeContact(state, contact) {
    if (!contact) throw new Error("No contact");
    const index = state.contacts.findIndex((x) => x.id === contact.id);
    if (index !== -1) {
      state.contacts.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const contacts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
