import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  stats: [],
  isLoading: false,
});

const getters = {
  statCountByPublished: state => isPublished => {
    return state.stats.find(x => x.isPublished === isPublished)?.count || 0;
  },
  statTotalCount: state => {
    const countArr = state.stats.map(x => x.count);
    if (countArr.length > 0) {
      return countArr.reduce((prev, next) => prev + next);
    }

    return 0;
  },
};

const actions = {
  async getStats({ commit }, filter) {
    commit("loading", true);

    return axios
      .get(`${apiUrl}statistics/template`, {
        params: { ...filter },
      })
      .then(({ data }) => {
        const stats = data?.statistics || [];
        commit("setStats", stats);
        commit("loading", false);
      })
      .catch(error => {
        commit("loading", false);
        throw new Error(error);
      });
  },
};

const mutations = {
  setStats(state, stats) {
    state.stats = stats;
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export const formTemplateStats = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
