<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("users.userInvite.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="editUserForm"
          v-model="user.valid"
          v-on:submit.prevent
          @submit="onInvite"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.email") }}
            </div>
            <AppDefaultTextField
              ref="editUserFormEmail"
              v-model="user.email.value"
              :rules="$rules.EMAIL_RULES"
              :counter="emailMaxInput"
              autofocus
            />
          </div>
          <v-checkbox
            v-model="adminPrivileges"
            :label="$t('users.userInvite.grantAdminPrivileges')"
            color="primary"
            hide-details
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onInvite"
          @cancel="onClose"
          :confirmText="$t('users.userInvite.invite')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    emailMaxInput: {
      type: Number,
      default: 255,
    },
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        this.user.email.value = newVal ? newVal.name : "";
      },
    },
  },
  data() {
    return {
      user: {
        valid: false,
        email: {
          value: "",
        },
      },
      adminPrivileges: false,
    };
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.editUserForm.resetValidation();
    },
    onInvite() {
      const valid = this.$refs.editUserForm.validate();
      if (valid) {
        const data = {
          email: this.user.email.value,
          ...(this.adminPrivileges && { role: "ADMIN" }),
        };
        this.$emit("submit:form", data);
        this.$refs.editUserForm.reset();
        this.closeDialog();
        this.adminPrivileges = false;
      }
    },
  },
};
</script>
