import { normalizeSubscriptionUsages } from "@/helpers/normalization";
import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  isLoading: false,
  usages: [],
});

const getters = {};

const actions = {
  async getUsages({ commit }) {
    commit("loading", true);
    return axios
      .get(`${apiUrl}statistics/usages`)
      .then(({ data }) => {
        const usages = data?.usages;
        const normalizedUsages = normalizeSubscriptionUsages(usages);
        commit("setUsage", normalizedUsages);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("loading", false);
      });
  },
};

const mutations = {
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setUsage(state, usages) {
    state.usages = usages;
  },
};

export const usages = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
