<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    genAnswerElm() {
      const value = Array.isArray(this.item.value) ? this.item.value : [];
      if (value.length <= 0)
        return this.$createElement(
          "div",
          { class: "error--text" },
          this.$t("formManagement.preview.noAnswer"),
        );

      const answers = [];

      for (let answer of value) {
        answers.push(this.$createElement("li", answer));
      }

      return this.$createElement("ul", answers);
    },
  },
  render(h) {
    return this.genAnswerElm();
  },
};
</script>
