<script>
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import QuestionItem from "./QuestionItem.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
    items: Array,
  },
  data() {
    return {
      observer: null,
    };
  },
  methods: {
    genGroup(item) {
      return this.$createElement("div", [
        this.$createElement(
          "div",
          { class: "text-h6 font-weight-bold pb-3 pt-5" },
          `${item.number} ${item.name}`,
        ),
        this.$createElement("div", this.genHierarchy(item.children)),
      ]);
    },
    genHierarchy(items) {
      const hierarchy = [];
      for (let item of items) {
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item));
        } else {
          hierarchy.push(
            this.$createElement(QuestionItem, {
              props: { item, observer: this.observer },
              on: {
                "item:taskClick": (e) => this.$emit("item:taskClick", e),
                "item:imagePreview": (e) => this.$emit("item:imagePreview", e),
              },
            }),
          );
        }
      }
      return hierarchy;
    },
    onElementObserved(entries) {
      for (let { target, isIntersecting } of entries) {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        const id = target.getAttribute("data-id");
        this.$emit("item:getImages", { id });
      }
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 1.0,
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  render(h) {
    return h("div", [
      h(
        "div",
        this.genHierarchy(
          this.formTemplateBuilderMixins_getHierarchyByParentId(this.items, this.item.id),
        ),
      ),
    ]);
  },
};
</script>
