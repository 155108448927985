<template>
  <AppWidgetTemplate
    @delete:click="$emit('delete:click')"
    :title="$t('projectView.info.widgets.latestActivity')"
    :isLoading="isLoading"
    v-bind="templateProps"
  >
    <template v-if="!isLoading">
      <AppLogList
        style="height: calc(100% - 52px)"
        class="px-1"
        :items="items"
        :isLoadingMore="isLoadingMore"
        :canLoadMore="canLoadMore"
        @click:load-more="onLoadMore"
      />
    </template>
  </AppWidgetTemplate>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    itemId: String,
    models: Array,
    templateProps: Object,
  },
  data() {
    return {};
  },
  watch: {
    itemId: {
      handler(newItemId, oldItemId) {
        if (newItemId !== oldItemId) {
          this.getHistory(newItemId, 0);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("history", {
      items: (state) => state.items,
      isLoading: (state) => state.isLoading,
      isLoadingMore: (state) => state.isLoadingMore,
      canLoadMore: (state) => state.canLoadMore,
      offset: (state) => state.offset,
    }),
  },
  methods: {
    getHistory(id, offset) {
      this.$store.dispatch("history/getHistory", { id, offset, model: this.models });
    },
    onLoadMore() {
      this.getHistory(this.itemId, this.offset);
    },
  },
};
</script>
