import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

const state = () => ({
  status: null,
  isCompleting: false,
  isDeclining: false,
});

const getters = {};

const actions = {
  async getStatus({ commit }, { hash }) {
    return axios
      .get(`${apiUrl}form-signature-session/status`, { params: { hash } })
      .then(({ data }) => {
        commit("setStatus", data.status);
        return data.status;
      })
      .catch((error) => {
        throw error;
      });
  },
  async complete({ commit }, { hash, body }) {
    commit("setIsCompleting", true);
    return axios
      .put(`${apiUrl}form-signature-session/complete`, { ...body }, { params: { hash } })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("setIsCompleting", false);
      });
  },
  async decline({ commit }, { hash, comment }) {
    commit("setIsDeclining", true);
    return axios
      .put(`${apiUrl}form-signature-session/decline`, { comment }, { params: { hash } })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        commit("setIsDeclining", false);
      });
  },
};

const mutations = {
  setStatus(state, status) {
    state.status = status;
  },
  setIsCompleting(state, isCompleting) {
    state.isCompleting = isCompleting;
  },
  setIsDeclining(state, isDeclining) {
    state.isDeclining = isDeclining;
  },
};

export const formSignSessionStatus = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
