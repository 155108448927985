<template>
  <AppMenuItem
    :text="$t('common.overview')"
    :icon="$icon.REGULAR.ROUTE.OVERVIEW"
    @click="click"
  />
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
