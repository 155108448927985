<template>
  <div id="pdf-view"></div>
</template>

<script>
export default {
  props: {
    content: String,
    name: String,
    canEditFile: Boolean,
    isLinkedFile: Boolean,
  },
  data() {
    return {
      previewConfig: {
        showAnnotationTools: false,
        enableFormFilling: false,
        showBookmarks: false,
        showThumbnails: true,
      },
      LOCAL_CLIENT_KEY: "f3cfa5b786714197b3f6bf873b13b39f",
      TEST_CLIENT_KEY: "8672301c726a4ee981acd83eb393cc81",
      LIVE_CLIENT_KEY: "f3dbf424e4654f8eb12da1eec432292d",
      STAGING_CLIENT_KEY: "f888053cea0342f891238d5b5af0c716",
    };
  },
  watch: {
    content(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initPdfViewer();
      }
    },
  },
  methods: {
    initPdfViewer() {
      const adobeDCView = new AdobeDC.View({
        clientId: this.clientKey,
        divId: "pdf-view",
      });
      adobeDCView.previewFile(
        {
          content: { location: { url: this.content } },
          metaData: { fileName: this.name ?? " " },
        },
        this.previewConfig,
      );
    },
  },
  computed: {
    clientKey() {
      if (location.hostname === "gripr.io" || location.hostname === "www.gripr.io") {
        return this.LIVE_CLIENT_KEY;
      } else if (location.hostname === "devgov3fe.azurewebsites.net") {
        return this.TEST_CLIENT_KEY;
      } else if (location.hostname === "staging-gripr-fe.azurewebsites.net") {
        return this.STAGING_CLIENT_KEY;
      } else if (location.hostname === "localhost") {
        return this.LOCAL_CLIENT_KEY;
      }
      return "CLIENT KEY FOR ENVIORMENT NOT IMPLEMENTED";
    },
  },
  mounted() {
    this.initPdfViewer();
  },
};
</script>
